import { useDispatch } from 'react-redux';
import {
  resetViewDialog,
  setSelectedTransactionCompensationLogId,
  setViewDialog,
} from 'src/redux/slices/transaction-compensation-log.slice';
import { useSelector } from 'src/redux/store';

export default function useViewDialog() {
  const toggle = useSelector(
    (state) => state.transactionCompensationLog.default.view.dialog.toggle,
  );

  //hook
  const dispatch = useDispatch();

  const open = (id?: number) => {
    if (id) {
      dispatch(setSelectedTransactionCompensationLogId(id));
    }
    dispatch(setViewDialog({ toggle: true }));
  };

  const close = () => {
    dispatch(resetViewDialog());
  };

  return { open, close, toggle };
}
