const ROOT_STATIC = '/conversation-static';

export const CONVERSATION_API = Object.freeze({
  ROOT: '/conversation',
  ROOT_STATIC,
  OVERVIEW_TASK_HISTORY: '/conversation/overview-task-history',
  SEARCH_TASK_HISTORY: '/conversation/search-task-history',
  STATIC_COUNT_CONVERSATION: `${ROOT_STATIC}/static-count-conversation`,
  STATIC_COUNT_CONVERSATION_BY_USER: `${ROOT_STATIC}/static-count-conversation-by-user`,
  STATIC_DETAIL_TIME_COUNT_CONVERSATION: `${ROOT_STATIC}/static-detail-time-count-conversation`,
  STATIC_COUNT_RATING_CONVERSATION: `${ROOT_STATIC}/static-count-rating-conversation`,
  STATIC_COUNT_CALL_CONVERSATION: `${ROOT_STATIC}/static-count-call-conversation`,
});

export const CONTACT_CONVERSATION_API = Object.freeze({
  ROOT: '/contact-conversation',
  CONVERSATIONS_TODAY: '/conversations-today',
  RECORD: '/record',
});
