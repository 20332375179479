import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IQueryKeyState {
  [index: string]: any[];
}

export enum QueryKeyConstantType {
  SEARCH_RECRUITMENT = 'SEARCH_RECRUITMENT',
  GET_STATIC_STATUS_RECRUITMENT = 'GET_STATIC_STATUS_RECRUITMENT',

  SEARCH_USER_COMPLAINTS = 'SEARCH_USER_COMPLAINTS',

  SEARCH_RECRUITMENT_APPLICATION = 'SEARCH_RECRUITMENT_APPLICATION',
  GET_STATIC_STATUS_RECRUITMENT_APPLICATION = 'GET_STATIC_STATUS_RECRUITMENT_APPLICATION',

  SEARCH_CONTRACT = 'SEARCH_CONTRACT',
  GET_STATIC_STATUS_CONTRACT = 'GET_STATIC_STATUS_CONTRACT',

  SEARCH_CONTRACT_HANDOVER_BY_MANAGE = 'SEARCH_CONTRACT_HANDOVER_BY_MANAGE',

  SEARCH_USER_STAFF_BY_CONTRACT = 'SEARCH_USER_STAFF_BY_CONTRACT',
  GET_STATIC_STAFF_USER_CONTRACT_HANDOVER = 'GET_STATIC_STAFF_USER_CONTRACT_HANDOVER',

  DETAIL_RECRUITMENT_APPLICATION_CURRENT_USER = 'DETAIL_RECRUITMENT_APPLICATION_CURRENT_USER',

  GET_STATIC_ALL_KPI = 'GET_STATIC_ALL_KPI',

  SEARCH_EMPLOYEE_ADVANCE_FEE = 'SEARCH_EMPLOYEE_ADVANCE_FEE',
  GET_STATIC_EMPLOYEE_ADVANCE_FEE = 'GET_STATIC_EMPLOYEE_ADVANCE_FEE',

  SEARCH_SALARY_REQUEST = 'SEARCH_SALARY_REQUEST',
  GET_STATIC_SALARY_REQUEST = 'GET_STATIC_SALARY_REQUEST',

  SEARCH_PUNISH_BY_STAFF = 'SEARCH_PUNISH_BY_STAFF',

  GET_SUM_NUMBER_PUNISH_KEY_DISCIPLINARY = 'GET_SUM_NUMBER_PUNISH_KEY_DISCIPLINARY',

  FIND_ONE_USER_TO_SETTING = 'FIND_ONE_USER_TO_SETTING' ,

  SEARCH_STAFF_TO_SETTING = 'SEARCH_STAFF_TO_SETTING',

  SEARCH_STAFF = 'SEARCH_STAFF',
  GET_STATIC_TRAINED = 'GET_STATIC_TRAINED',

  SEARCH_USER_TRAINING_SECTION_BY_USER = 'SEARCH_USER_TRAINING_SECTION_BY_USER',
  SEARCH_USER_TRAINING_SECTION_BY_CURRENT_USER = 'SEARCH_USER_TRAINING_SECTION_BY_CURRENT_USER',
  GET_STATIC_USER_TRAINING_SECTION_BY_USER = 'GET_STATIC_USER_TRAINING_SECTION_BY_USER',
  GET_STATIC_USER_TRAINING_SECTION_BY_CURRENT_USER = 'GET_STATIC_USER_TRAINING_SECTION_BY_CURRENT_USER'
}

const initialState: IQueryKeyState = {};

const slice = createSlice({
  name: 'queryKey',
  initialState,
  reducers: {
    setQueryKey: (
      state,
      action: PayloadAction<{
        name: QueryKeyConstantType;
        queryKey: any[];
      }>,
    ) => {
      state[action.payload.name] = action.payload.queryKey;
    },

    removeQueryKey: (
      state,
      action: PayloadAction<{
        name: QueryKeyConstantType;
      }>,
    ) => {
      delete state[action.payload.name];
    },
  },
});

export default slice.reducer;

export const { removeQueryKey, setQueryKey } = slice.actions;
