import { ReactNode } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

type RootStyleProps = {
  rounded: boolean;
};

const RootStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'rounded',
})<RootStyleProps>(({ rounded }) => ({
  display: 'flex',
  listStyle: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  '& li': {
    width: 30,
    height: 6,
    opacity: 0.32,
    cursor: 'pointer',
  },
  '& li.slick-active': {
    opacity: 1,
    ...(rounded && {
      '& .minusActive': {
        width: 30,
        height: 6,
      },
    }),
  },
}));

const MinusWrapStyle = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MinusStyle = styled('span')(({ theme }) => ({
  width: 20,
  height: 4,
  borderRadius: 5,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
}));

// ----------------------------------------------------------------------

export interface Props extends BoxProps {
  color?: string;
  rounded?: boolean;
}

export default function CarouselMinus(props?: Props) {
  const color = props?.color;
  const rounded = props?.rounded || false;

  return {
    appendDots: (dots: ReactNode) => (
      <>
        <RootStyle rounded={rounded} as="ul" {...props}>
          {dots}
        </RootStyle>
      </>
    ),
    customPaging: () => (
      <MinusWrapStyle>
        <MinusStyle
          className="minusActive"
          sx={{
            bgcolor: color || 'primary.main',
          }}
        />
      </MinusWrapStyle>
    ),
  };
}
