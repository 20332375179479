import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CommonDashboardSideType } from 'src/@types/dashboard';
import {
  ICreateUndefinedTransactionFilter,
  IUndefinedTransactionState,
  UndefinedTransactionSearchType,
  UndefinedTransactionTypeType,
} from 'src/@types/undefined-transaction';
import { getMonth, getYear } from 'src/utils/generateDate.util';

const initialState: IUndefinedTransactionState = {
  selectedUndefinedTransactionId: null,
  undefinedTransactions: [],
  default: {
    dashboardSide: 'revenue',
    revenue: {
      create: {
        dialog: {
          toggle: false,
        },
        filter: {
          bankAccountId: undefined,
          ewalletAccountId: undefined,
          searchCharacter: '',
          month: getMonth(),
          year: getYear(),
          searchType: UndefinedTransactionSearchType.VERIFY,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
        },
      },
      reject: {
        mode: 'EDIT',
      },
      buttonTab: 0,
    },
    expense: {
      create: {
        dialog: {
          toggle: false,
        },
        filter: {
          bankAccountId: undefined,
          ewalletAccountId: undefined,
          searchCharacter: '',
          month: getMonth(),
          year: getYear(),
          searchType: UndefinedTransactionSearchType.VERIFY,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_REFUND,
        },
      },
      reject: {
        mode: 'EDIT',
      },
      buttonTab: 0,
    },
  },
};

const slice = createSlice({
  name: 'undefinedTransaction',
  initialState: initialState,
  reducers: {
    //Common
    setSelectedUndefinedTransactionId(
      state,
      action: PayloadAction<number | null>,
    ) {
      state.selectedUndefinedTransactionId = action.payload;
    },
    //---Default-----------------------
    //Set button tab
    setButtonTab(
      state,
      action: PayloadAction<{
        index: 0 | 1;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { dashboardSide, index } = action.payload;

      state.default[dashboardSide].buttonTab = index;
    },
    //Set dashboardSide
    setCursor(state, action: PayloadAction<CommonDashboardSideType>) {
      state.default.dashboardSide = action.payload;
    },

    setReject(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'EDIT';
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { dashboardSide, mode } = action.payload;

      state.default[dashboardSide].reject.mode = mode;
    },

    //Set create Filter
    setCreateFilter(
      state,
      action: PayloadAction<{
        filter: Partial<ICreateUndefinedTransactionFilter>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { filter, dashboardSide } = action.payload;

      state.default[dashboardSide].create.filter = {
        ...state.default[dashboardSide].create.filter,
        ...filter,
      };
    },

    //Create
    setCreateUndefinedTransactionDialogToggle(
      state,
      action: PayloadAction<{
        toggle: boolean;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { toggle, dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog.toggle = toggle;
    },
    //---Manager-----------------------

    //Verify mode
  },
});

//Reducer
export default slice.reducer;

export const {
  //Common
  setSelectedUndefinedTransactionId,

  //Default
  setButtonTab,
  setCursor,
  setCreateUndefinedTransactionDialogToggle,
  setReject,
  setCreateFilter,
  //Manager
} = slice.actions;
