import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonDashboardSideType } from 'src/@types/dashboard';
import { UndefinedTransactionTypeType } from 'src/@types/undefined-transaction';
import {
  IDefaultState,
  IManagerState,
  IUndefinedTransactionDeleteRequestState,
} from 'src/@types/undefined-transaction-delete-request';

const initialState: IUndefinedTransactionDeleteRequestState = {
  default: {
    expense: {
      create: {
        dialog: {
          toggle: false,
        },
      },
    },
    revenue: {
      create: {
        dialog: {
          toggle: false,
        },
      },
    },
  },
  manager: {
    expense: {
      delete: {
        dialog: {
          mode: 'VIEW',
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 10,
          statuses: [],
          undefinedTransactionType:
            UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
        },
      },
    },
    revenue: {
      delete: {
        dialog: {
          mode: 'VIEW',
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 10,
          statuses: [],
          undefinedTransactionType:
            UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
        },
      },
    },
  },
  selectedUndefinedTransactionDeleteRequestId: undefined,
};

const slice = createSlice({
  name: 'undefinedTransactionDeleteRequest',
  initialState,
  reducers: {
    setSelectedUndefinedTransactionDeleteRequestId: (
      state,
      action: { payload: number | undefined },
    ) => {
      state.selectedUndefinedTransactionDeleteRequestId = action.payload;
    },
    //--Default-----------------------
    setCreateDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<IDefaultState['create']['dialog']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog = {
        ...state.default[dashboardSide].create.dialog,
        ...dialog,
      };
    },
    //Reset
    resetCreateDialog: (
      state,
      action: PayloadAction<{
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog =
        initialState.default[dashboardSide].create.dialog;
    },

    //--Manager-----------------------
    setManagerDeleteDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<IManagerState['delete']['dialog']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.dialog = {
        ...state.manager[dashboardSide].delete.dialog,
        ...dialog,
      };
    },
    resetManagerDeleteDialog: (
      state,
      action: PayloadAction<{
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.dialog =
        initialState.manager[dashboardSide].delete.dialog;
    },
    setManagerDeleteFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<IManagerState['delete']['filter']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { filter, dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.filter = {
        ...state.manager[dashboardSide].delete.filter,
        ...filter,
      };
    },
    resetManagerDeleteFilter: (
      state,
      action: PayloadAction<{
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.filter =
        initialState.manager[dashboardSide].delete.filter;
    },
  },
});

export default slice.reducer;

export const {
  setSelectedUndefinedTransactionDeleteRequestId,
  setCreateDialog,
  resetCreateDialog,
  setManagerDeleteDialog,
  resetManagerDeleteDialog,
  setManagerDeleteFilter,
  resetManagerDeleteFilter,
} = slice.actions;
