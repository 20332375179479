import { BoxProps } from '@mui/material';
import { useContext, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import {
  ConversationType,
  ExistedRoomResponse,
  MessageResponse,
  MessageType,
} from 'src/@types/conversation';
import { GET_PRIVATE_IMAGE_API } from 'src/config';
import { AuthContext } from 'src/contexts/JWTContext';
import { SocketContext } from 'src/contexts/SocketContext';
import {
  addChat,
  addMsg,
  setIsCall,
  setIsNewMsg,
  setMsgs,
  updateConversation,
} from 'src/redux/slices/conversations.slice';
import { useDispatch, useSelector } from 'src/redux/store';
import ContactConversation from './ContactConversation';

export default function ContactContainer({ ...other }: BoxProps) {
  const { contSocket } = useContext(SocketContext);
  const authData = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  // redux
  const dispatch = useDispatch();
  const { chats, conversations } = useSelector((state) => state.conversations);

  // contSocket
  useEffect(() => {
    if (contSocket) {
      if (chats.length === 0) {
        contSocket.emit('check-exist-contact-conversation', null);
      }

      // listen
      contSocket.on('error', (data) => {});
      contSocket.on(
        'join-room-contact-again-succeeded',
        (data: { conversation: ConversationType }) => {
          dispatch(
            setMsgs({
              id: data.conversation.id,
              msgs: data.conversation.messages,
            }),
          );
        },
      );

      contSocket.on(
        'existed-contact-conversation',
        (data: ExistedRoomResponse) => {
          if (data.conversation) {
            const currentConversation: ConversationType = {
              ...data.conversation,
              messages: data.conversation.messages.map((item) => ({
                ...item,
                folder: item.folder
                  ? {
                      ...item.folder,
                      files: item.folder.files.map((file) => ({
                        ...file,
                        url: `${GET_PRIVATE_IMAGE_API(file.id)}`,
                      })),
                    }
                  : undefined,
              })),
            };
            dispatch(addChat(currentConversation));
          }
        },
      );

      contSocket.on(
        'send-to-all-contact-conversation',
        ({ message, roomId, sender }: MessageResponse) => {
          const currentMessage: MessageType = {
            ...message,
            folder: message.folder
              ? {
                  ...message.folder,
                  files: message.folder.files.map((file) => ({
                    ...file,
                    url: `${GET_PRIVATE_IMAGE_API(file.id)}`,
                  })),
                }
              : undefined,
          };
          dispatch(
            addMsg({
              id: Number(roomId?.split('contact-')[1]),
              msg: currentMessage,
            }),
          );

          dispatch(
            setIsNewMsg({
              id: Number(roomId?.split('contact-')[1]),
              isNewMsg: true,
            }),
          );
        },
      );

      contSocket.on('contact-call-ring', (data: { conversationId: number }) => {
        dispatch(
          setIsCall({
            conversationId: data.conversationId,
            call: {
              isCall: true,
              isReceiver: true,
              countDownTimeCall: Date.now() + 60000,
            },
          }),
        );
      });

      contSocket.on(
        'join-contact-call-successfully',
        (data: { conversationId: number }) => {
          dispatch(
            setIsCall({
              conversationId: data.conversationId,
              call: {
                isCall: true,
                isReceiver: false,
                countDownTimeCall: Date.now() + 60000,
              },
            }),
          );
        },
      );

      contSocket.on('join-contact-call-failed', () => {
        enqueueSnackbar(
          'Không thể thực hiện cuộc gọi này, vì bạn đang trong 1 cuộc gọi khác',
          {
            variant: 'warning',
          },
        );
      });
      contSocket.on(
        'leave-room-call-current-successfully',
        (data: { conversationId: number }) => {
          dispatch(
            setIsCall({
              conversationId: data.conversationId,
              call: {
                isCall: false,
                isStart: false,
                isReceiver: false,
                countDownTimeCall: undefined,
                roomUrl: '',
              },
            }),
          );
        },
      );

      contSocket.on(
        'other-leave-room-call',
        (data: { conversationId: number }) => {
          dispatch(
            setIsCall({
              conversationId: data.conversationId,
              call: {
                isCall: false,
                isStart: false,
                isReceiver: false,
                countDownTimeCall: undefined,
                roomUrl: '',
              },
            }),
          );
        },
      );

      contSocket.on(
        'start-call-contact-conversation-successfully',
        (data: { roomUrl: string; conversationId: number }) => {
          dispatch(
            setIsCall({
              call: {
                isStart: true,
                roomUrl: data.roomUrl,
              },
              conversationId: data.conversationId,
            }),
          );
          dispatch(
            updateConversation({
              id: data.conversationId,
              conversation: {
                isCall: true,
              },
            }),
          );
        },
      );

      contSocket.on(
        'other-start-call-contact-conversation-successfully',
        (data: { roomUrl: string; conversationId: number }) => {
          dispatch(
            setIsCall({
              call: {
                isStart: true,
                roomUrl: data.roomUrl,
              },
              conversationId: data.conversationId,
            }),
          );
        },
      );
      contSocket.on(
        'leave-duplicate-call-contact',
        (data: { roomUrl: string; conversationId: number }) => {
          dispatch(
            setIsCall({
              call: {
                isStart: false,
                roomUrl: '',
                countDownTimeCall: undefined,
                isCall: false,
                isReceiver: false,
              },
              conversationId: data.conversationId,
            }),
          );
        },
      );
    }

    return () => {
      if (contSocket) {
        contSocket.off('error');
        contSocket.off('existed-contact-conversation');
        contSocket.off('send-to-all-contact-conversation');
        contSocket.off('join-room-contact-again-succeeded');
        contSocket.off('contact-call-ring');
        contSocket.off('join-contact-call-successfully');
        contSocket.off('join-contact-call-failed');
        contSocket.off('leave-room-call-current-successfully');
        contSocket.off('other-leave-room-call');
        contSocket.off('start-call-contact-conversation-successfully');
        contSocket.off('other-start-call-contact-conversation-successfully');
        contSocket.off('leave-duplicate-call-contact');
      }
    };
  }, [contSocket, authData?.user]);

  useEffect(() => {
    if (conversations.length > 0 && contSocket) {
      contSocket.on('connect', () => {
        contSocket.emit('join-room-consultant', null);
        contSocket.emit('join-room-contact-again');
      });
    }
    return () => {
      contSocket?.off('connect');
    };
  }, [conversations, contSocket]);

  return (
    <>
      {chats.map((item, key) => (
        <ContactConversation
          {...other}
          right={key === 0 ? 20 : key * 450}
          zIndex={key === 0 ? 2 : 1}
          conversation={item.conversation}
          isNewMsg={item.isNewMsg}
          call={item.call}
          key={key}
          itemKey={key}
        />
      ))}
    </>
  );
}
