import {
  Box,
  Grid,
  Stack,
  StackProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { getMonth } from 'src/utils/generateDate.util';
import WorkMonthCalendarItem from './WorkMonthCalendarItem';

//----------------------------------------------------------------
type Props = StackProps & {
  value?: number;
  onMonthClick?: (value: number) => void;
};

//----------------------------------------------------------------
const HeaderStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 4, 1),
}));

const ContentStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4, 1),
}));

//----------------------------------------------------------------
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

//----------------------------------------------------------------
export default function WorkMonthCalendar({
  value,
  onMonthClick,
  ...other
}: Props) {
  const [month, setMonth] = useState<number>(value || getMonth());

  const theme = useTheme();

  const handleClick = (month: number) => {
    if (months.includes(month)) {
      setMonth(month);
      if (onMonthClick) onMonthClick(month);
    }
  };

  return (
    <Stack {...other}>
      <HeaderStyle>
        <Typography fontSize={16} fontWeight={theme.typography.fontWeightBold}>
          Tháng làm việc
        </Typography>
        <Typography color={grey[600]}>Chọn tháng để xem</Typography>
      </HeaderStyle>
      <ContentStyle overflow={'auto'}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {months.map((item) => (
            <Grid item key={item} xs={4}>
              <WorkMonthCalendarItem
                month={item}
                isActive={item === month}
                onClick={() => handleClick(item)}
              />
            </Grid>
          ))}
        </Grid>
      </ContentStyle>
    </Stack>
  );
}
