import { DeepPartial } from 'react-hook-form';
import { BankAccount } from './bank-account';
import { CommonDashboardSideType } from './dashboard';
import { EwalletAccount } from './e-wallet-account';
import { FileData } from './file';
import { MoneyHolderAccountTypeType } from './money-holder-account';
import { TransactionSupportRequest } from './transaction-support';
import { User } from './user';
import { UndefinedTransactionVerifyRequest } from './undefined-transaction-verify-request';
import { UndefinedTransactionDeleteRequest } from './undefined-transaction-delete-request';

//----------------------------------------------------------------
//State
export interface ICreateUndefinedTransactionFilter
  extends UndefinedTransactionSearchRequest {}

export interface IUndefinedTransactionSide {
  create: {
    dialog: {
      toggle: boolean;
    };
    filter: ICreateUndefinedTransactionFilter;
  };
  reject: {
    mode: 'VIEW' | 'EDIT';
  };
  buttonTab: number;
}
export interface IUndefinedTransactionState {
  selectedUndefinedTransactionId: number | null;
  undefinedTransactions: UndefinedTransaction[];
  default: {
    dashboardSide: CommonDashboardSideType;
    revenue: IUndefinedTransactionSide;
    expense: IUndefinedTransactionSide;
  };
}

//----------------------------------------------------------------
export enum UndefinedTransactionStatusType {
  PENDING = 'Chờ',
  PENDING_DELETE_CONFIRM = 'Chờ xác nhận xóa',
  PENDING_CONFIRM = 'Chờ xác nhận',
  COMPLETED = 'Hoàn thành',
  REJECTED = 'Từ chối',
  ACCEPTED = 'Chấp nhận',
  DELETED = 'Xóa',
}

export enum UndefinedTransactionTypeType {
  UNIDENTIFIED_RECEIPT = 'Nhận tiền chưa xác định',
  UNIDENTIFIED_REFUND = 'Hoàn tiền chưa xác định',
}

export enum HistoryUndefinedTransactionSearchTypeType {
  SUPPORT = 'Trợ giúp',
  OUT_OF_BAND = 'Ngoài luồng',
}

export enum UndefinedTransactionSearchType {
  VERIFY = 'verify',
  SUPPORT_HISTORY = 'support_history',
  OUT_OF_BAND_HISTORY = 'out_of_bands_history',
}

//----------------------------------------------------------------
export type MoneySummary = {
  month: number;
  year: number;
  total: number;
  pendingTotal: number;
  verifiedTotal: number;
  supportedTotal: number;
};

export type HistoryMoneySummary = {
  month: number;
  year: number;
  verifiedTotal: number;
  supportedTotal: number;
};

export type UndefinedTransaction = {
  id: number;
  amount: number;
  content: string;
  type: UndefinedTransactionTypeType;
  code: string;
  status: UndefinedTransactionStatusType;
  undefinedTransactionImageId: number;
  undefinedTransactionImage?: FileData;
  receivedBankAccountId: number;
  receivedBankAccount?: BankAccount;
  receivedEwalletAccountId: number;
  receivedEwalletAccount?: EwalletAccount;
  receivedAt: Date;
  createdByAccountantId: number;
  createdByAccountant?: User;
  undefinedTransactionDeleteRequests?: UndefinedTransactionDeleteRequest[];
  undefinedTransactionVerifyRequests?: UndefinedTransactionVerifyRequest[];
  transactionSupportRequest?: TransactionSupportRequest;
  createdAt: Date;
  updatedAt: Date;
};

//Form
export type CreateUndefinedTransactionFormProps = {
  receivedAt: Date;
  amount: number;
  content: string;
  undefinedTransactionImage: FileData;
  type: UndefinedTransactionTypeType;
  receivedBankAccountId?: number;
  receivedEwalletAccountId?: number;
};

/**
 * Request type
 *
 *
 */
export type OverviewTaskHistoryRequest = {
  month: number;
  year: number;
};

export type OverviewTaskHistoryResponse = {
  month: number;
  year: number;
  receiptUndefinedTransactionCount: number;
  refundUndefinedTransactionCount: number;
};

export type SearchTaskHistoryRequest = {
  searchCharacter?: string;
  month?: number;
  year?: number;
  type?: UndefinedTransactionTypeType;
};

export type SearchTaskHistoryResponse = {
  id: number;
  code: string;
  receivedAt: Date;
  status: UndefinedTransactionStatusType;
  type: UndefinedTransactionTypeType;
  description: string;
  requestCreatedAt: Date;
  requestId: number;
  KPI?: number;
};

export type CreateUndefinedTransactionRequest = {
  receivedAt: Date | string | null;
  amount: number;
  content: string;
  undefinedTransactionImage: DeepPartial<FileData> | null;
  receivedBankAccountId?: number | null;
  receivedEwalletAccountId?: null | number;
  type: UndefinedTransactionTypeType;
};

export type SearchUndefinedTransactionRequest = {
  searchType: UndefinedTransactionSearchType;
  searchCharacter?: string;
  bankId?: number;
  ewalletId?: number;
  month?: number;
  year?: number;
  page?: number;
  limit?: number;
  type?: UndefinedTransactionTypeType;
};

export type SummaryUndefinedTransactionMoneyRequest = {
  month: number;
  year: number;
  type: UndefinedTransactionTypeType;
  bankAccountId?: number;
  ewalletAccountId?: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type HistorySummaryUndefinedTransactionMoneyRequest = {
  month: number;
  year: number;
  type: UndefinedTransactionTypeType;
  bankAccountId?: number;
  ewalletAccountId?: number;
  historyType: HistoryUndefinedTransactionSearchTypeType;
};

export type FindUndefinedTransactionByCodeRequest = {
  code: string;
  status?: UndefinedTransactionStatusType;
  type?: UndefinedTransactionTypeType;
};

export type GetAllMoneyUndefinedTransactionRequest = {
  ewalletAccountId?: number | null;
  bankAccountId?: number | null;
};

export type GetAllMoneyUndefinedTransactionResponse = {
  total: number;
};
/**
 * Hook
 *
 *
 */
export type UndefinedTransactionSearchRequest = {
  searchCharacter?: string;
  bankAccountId?: number | undefined;
  ewalletAccountId?: number | undefined;
  month?: number | undefined;
  year?: number | undefined;
  searchType?: UndefinedTransactionSearchType;
  limit?: number;
  type?: UndefinedTransactionTypeType;
};
