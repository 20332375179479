import {
  doReadNumber,
  InvalidFormatError,
  InvalidNumberError,
  NotEnoughUnitError,
  ReadingConfig,
} from 'read-vietnamese-number';
import { getDateNow, momentVi } from './date-handle';

export function getAvatarName(name?: string): string {
  if (!name) return 'U';
  return name.split(' ').at(-1)?.charAt(0).toUpperCase() || 'U';
}

const config = new ReadingConfig();
config.unit = ['việt nam đồng'];

export function fMoneyToText(value?: string | number) {
  let text: string = '0';

  if (!value) return fCapitalizeFirstLetter(doReadNumber(config, text));

  if (typeof value === 'number') text = value.toString();
  else text = value;

  try {
    const result = doReadNumber(config, text);
    return fCapitalizeFirstLetter(result);
  } catch (err) {
    // Handle errors
    if (err instanceof InvalidFormatError) {
      return 'Định dạng input không hợp lệ';
    } else if (err instanceof InvalidNumberError) {
      return 'Số không hợp lệ';
    } else if (err instanceof NotEnoughUnitError) {
      return 'Không đủ đơn vị đọc số';
    }
  }
}

export function fCapitalizeFirstLetter(inputString?: string): string {
  if (!inputString) return '';
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function fUpperFirstCharacterCaseString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function fUpperCaseString(str: string) {
  return str.toUpperCase();
}

export function fTransformDotToUnderscore(str: string) {
  return str.replace(/\./g, '-');
}

export function fRemoveSpecificCharacter(str: string) {
  return str.replace(/[^\w]/g, '');
}

export function fNumberWithLeadingZeros(number: number, length: number) {
  return number.toString().padStart(length, '0');
}

export const fMoneyVND = (
  value?: number | null,
  options?: { suffixes?: string; sign?: '+' | '-' | '=' },
  isIgnoreSpace?: boolean,
) => {
  let valueConvert = 0;
  if (typeof value === 'number') {
    valueConvert = value;
  }

  if (options?.sign) {
    valueConvert = Math.abs(valueConvert);
  }

  const textCurrency = valueConvert.toLocaleString('it-IT', {
    style: 'currency',
    currency: 'VND',
  });

  if (typeof options?.suffixes != 'undefined') {
    if (!isIgnoreSpace) {
      const forMatTextCurrency = textCurrency.replace(
        /VND/g,
        options?.suffixes,
      );

      return forMatTextCurrency;
    }

    const forMatTextCurrency = textCurrency.replace(
      /\s*VND/g,
      options?.suffixes,
    );
    return forMatTextCurrency;
  }

  if (options?.sign) {
    if (options.sign === '-') return `-${textCurrency}`;
    if (options.sign === '+') return `+${textCurrency}`;
    if (options.sign === '=') return `=${textCurrency}`;
  }

  return textCurrency;
};

export const fBankAccountNumber = (value?: string | number) => {
  if (!value) return '';

  const fValue = value.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-');

  return fValue;
};

export function fPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) return '';

  if (phoneNumber.length > 0) {
    const parts = [];

    parts.push(phoneNumber.slice(0, 4));

    if (phoneNumber.length > 4) {
      parts.push(phoneNumber.slice(4, 7));

      if (phoneNumber.length > 7) {
        parts.push(phoneNumber.slice(7));
      }
    }

    return parts.join(' ');
  }

  return phoneNumber;
}

export function fAvatarName(name?: string) {
  if (!name) return 'U';
  return name.split(' ').at(-1)?.charAt(0).toUpperCase() || 'U';
}

export const getLabelOnline = ({
  isOnline,
  lastOnlineAt,
}: {
  isOnline: boolean;
  lastOnlineAt: Date;
}) => {
  const labelOnline = isOnline
    ? 'Hiện tại đang truy cập'
    : `Truy cập ${momentVi(lastOnlineAt).from(getDateNow())}`;

  return labelOnline;
};

export const shortenCurrencyWithUnit = (value: number) => {
  const lengthValueText = Math.floor(value).toString().length;

  if (lengthValueText >= 10)
    return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + ' tỷ';
  if (lengthValueText >= 7)
    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + ' triêu';
  if (lengthValueText >= 4)
    return (value / 1000).toFixed(1).replace(/\.0$/, '') + ' ngàn';
  return value.toFixed(1).replace(/\.0$/, '') + ' đồng';
};

export const hiddenPhone = (phone?: string) => {
  if (!phone) return '';

  const hiddenPhoneNumber = phone.replace(
    /(\d{3})(.*)(\d{3})/,
    (match, firstPart, middlePart, lastPart) => {
      const hiddenPart = '*'.repeat(middlePart.length);

      return firstPart + hiddenPart + lastPart;
    },
  );

  return hiddenPhoneNumber;
};

export const hiddenMail = (mail?: string) => {
  if (!mail) return '';

  const hiddenEmail = mail.replace(/(.{1})(.*)(?=@)/, (match, firstPart) => {
    const hiddenPart = '*'.repeat(6);

    return firstPart + hiddenPart;
  });

  return hiddenEmail;
};

export const fTextOrDash = (
  text?: string | number | null | undefined,
  dashCount: number = 2,
) => {
  if (!text && text !== 0) return '-'.repeat(dashCount);
  return text;
};
