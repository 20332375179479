import { useQueryClient } from '@tanstack/react-query';
import { DepositDashboardSideType } from 'src/@types/dashboard';
import { CreateMode } from 'src/@types/transaction';
import { QUERY_KEYS } from 'src/constants/queryKey';
import {
  setCreateCompanyDepositDialog,
  setSelectedTransactionId,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

type Props = {
  dashboardSide: DepositDashboardSideType;
};

export function useCreateDialog({ dashboardSide }: Props) {
  const toggle = useSelector(
    (state) =>
      state.transaction.default[dashboardSide].createCompanyDeposit.dialog
        .toggle,
  );

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const open = (mode?: CreateMode) => {
    dispatch(
      setCreateCompanyDepositDialog({
        dialog: {
          toggle: true,
          mode,
        },
        dashboardSide,
      }),
    );
  };

  const openWithId = (id: number, mode: CreateMode) => {
    dispatch(setSelectedTransactionId(id));
    dispatch(
      setCreateCompanyDepositDialog({
        dialog: {
          toggle: true,
          mode: mode,
        },
        dashboardSide,
      }),
    );
  };

  const close = () => {
    dispatch(setSelectedTransactionId(undefined));
    dispatch(
      setCreateCompanyDepositDialog({
        dialog: {
          toggle: false,
        },
        dashboardSide,
      }),
    );
    queryClient.resetQueries({
      queryKey: [
        QUERY_KEYS.VERIFY_PENDING_COMPANY_ACCOUNT_WITHDRAW_TRANSACTION,
      ],
    });
  };

  return { toggle, open, close, openWithId };
}
