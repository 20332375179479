import { CommonDashboardSideType } from 'src/@types/dashboard';
import { DefaultCreateDialogMode } from 'src/@types/undefined-transaction-verify-request';
import {
  resetCreateDialog,
  setCreateDialog,
} from 'src/redux/slices/undefined-transaction-verify-request.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyUndefinedTransactionDialog(
  dashboardSide: CommonDashboardSideType,
) {
  const toggle = useSelector(
    (state) =>
      state.undefinedTransactionVerifyRequest.default[dashboardSide].create
        .dialog.toggle,
  );
  const mode = useSelector(
    (state) =>
      state.undefinedTransactionVerifyRequest.default[dashboardSide].create
        .dialog.mode,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialog = (mode?: DefaultCreateDialogMode) => {
    dispatch(
      setCreateDialog({
        dialog: {
          mode,
          toggle: true,
        },
        dashboardSide: dashboardSide,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(resetCreateDialog({ dashboardSide: dashboardSide }));
  };

  return { toggle, openDialog, closeDialog, mode };
}
