/**
 * redux slice for company money holder account verification
 */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CreateCompanyMoneyHolderAccountVerification,
  CreateCompanyMoneyHolderAccountVerificationFormErrors,
  ICompanyMoneyHolderAccountVerificationState,
  VerifyCompanyMoneyHolderAccountVerification,
} from 'src/@types/company-money-holder-account-verification';
import { CommonDashboardSideType } from 'src/@types/dashboard';

const initialVerifyCompanyMoneyHolderAccountVerification: VerifyCompanyMoneyHolderAccountVerification =
  {
    selectedUserId: undefined,
    filter: {
      bankAccountId: undefined,
      ewalletAccountId: undefined,
      searchCharacter: undefined,
    },
    dialog: {
      toggle: false,
      groupId: undefined,
      bankAccountId: undefined,
      ewalletAccountId: undefined,
      date: undefined,
    },
    mode: 'VERIFY',
  };

const initialCreateCompanyMoneyHolderAccountVerification: CreateCompanyMoneyHolderAccountVerification =
  {
    mode: 'CREATE',
    dialog: {
      toggle: false,
      bankAccountId: undefined,
      ewalletAccountId: undefined,
      groupId: undefined,
      date: undefined,
    },
    form: {
      state: {
        actualBalance: undefined,
        verificationImage: undefined,
        bankAccountId: undefined,
        ewalletAccountId: undefined,
      },
      errors: undefined,
      isInitialized: false,
    },
  };

const initialState: ICompanyMoneyHolderAccountVerificationState = {
  default: {
    expense: {
      create: initialCreateCompanyMoneyHolderAccountVerification,
    },
    revenue: {
      create: initialCreateCompanyMoneyHolderAccountVerification,
    },
  },
  manager: {
    revenue: {
      verify: initialVerifyCompanyMoneyHolderAccountVerification,
    },
    expense: {
      verify: initialVerifyCompanyMoneyHolderAccountVerification,
    },
  },
};

const slice = createSlice({
  name: 'companyMoneyHolderAccountVerification',
  initialState,
  reducers: {
    //Default
    //Create
    setCreateFormState: (
      state,
      action: PayloadAction<{
        formState: Partial<
          CreateCompanyMoneyHolderAccountVerification['form']['state']
        >;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, formState } = action.payload;

      state.default[dashboardSide].create.form.state = {
        ...state.default[dashboardSide].create.form.state,
        ...formState,
      };
      state.default[dashboardSide].create.form.isInitialized = true;
    },
    setCreateFormErrors: (
      state,
      action: PayloadAction<{
        formErrors: Partial<CreateCompanyMoneyHolderAccountVerificationFormErrors>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, formErrors } = action.payload;

      state.default[dashboardSide].create.form.errors = formErrors as any;
    },
    setCreateDialog: (
      state,
      action: PayloadAction<{
        dialog?: Partial<CreateCompanyMoneyHolderAccountVerification['dialog']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog = {
        ...state.default[dashboardSide].create.dialog,
        ...dialog,
      };
    },
    resetCreateDialog: (
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) => {
      state.default[action.payload.dashboardSide].create.dialog = {
        ...initialCreateCompanyMoneyHolderAccountVerification.dialog,
      };
    },
    //Manager
    setManagerVerifyFilter: (
      state,
      action: PayloadAction<{
        filter: VerifyCompanyMoneyHolderAccountVerification['filter'];
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, filter } = action.payload;

      state.manager[dashboardSide].verify.filter = {
        ...state.manager[dashboardSide].verify.filter,
        ...filter,
      };
    },
    setManagerVerifyDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<VerifyCompanyMoneyHolderAccountVerification['dialog']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, dialog } = action.payload;

      state.manager[dashboardSide].verify.dialog = {
        ...state.manager[dashboardSide].verify.dialog,
        ...dialog,
      };
    },
    setManagerVerifyMode: (
      state,
      action: PayloadAction<{
        mode: VerifyCompanyMoneyHolderAccountVerification['mode'];
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, mode } = action.payload;

      state.manager[dashboardSide].verify.mode = mode;
    },
    setManagerVerifySelectedUserId: (
      state,
      action: PayloadAction<{
        selectedUserId?: number;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, selectedUserId } = action.payload;

      state.manager[dashboardSide].verify.selectedUserId = selectedUserId;
    },
    resetManagerVerifyDialog: (
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.dialog = {
        ...initialVerifyCompanyMoneyHolderAccountVerification.dialog,
      };
    },
  },
});

//Reducer
export default slice.reducer;

//actions
export const {
  setCreateFormState,
  setCreateFormErrors,
  setCreateDialog,
  setManagerVerifyFilter,
  setManagerVerifyDialog,
  setManagerVerifyMode,
  setManagerVerifySelectedUserId,
  resetCreateDialog,
  resetManagerVerifyDialog,
} = slice.actions;
