import { SidebarStaticAccountantManageRes, SidebarStaticAccountantRes } from 'src/@types/sidebar';
import { PrefixType } from 'src/constants/prefix';
import axiosInstance from 'src/utils/axios';
import { SIDEBAR_API } from '../constants/sidebar.api.constant';

export const getSidebarStaticAccountantManage =
  (): Promise<SidebarStaticAccountantManageRes> =>
    axiosInstance.get(
      PrefixType.ACCOUNTANT + '/' + SIDEBAR_API.GET_STATIC_MANAGE,
    );

export const getSidebarStaticAccountant = (): Promise<SidebarStaticAccountantRes> =>
  axiosInstance.get(PrefixType.ACCOUNTANT + '/' + SIDEBAR_API.GET_STATIC);
