import {
  SidebarStaticConsultantManageRes,
  SidebarStaticConsultantRes,
} from 'src/@types/sidebar';
import { PrefixType } from 'src/constants/prefix';
import axiosInstance from 'src/utils/axios';
import { SIDEBAR_API } from '../constants/sidebar.api.constant';

export const getSidebarStaticConsultantManage =
  (): Promise<SidebarStaticConsultantManageRes> =>
    axiosInstance.get(
      PrefixType.CONSULTANT + '/' + SIDEBAR_API.GET_STATIC_MANAGE,
    );

export const getSidebarStaticConsultant =
  (): Promise<SidebarStaticConsultantRes> =>
    axiosInstance.get(PrefixType.CONSULTANT + '/' + SIDEBAR_API.GET_STATIC);
