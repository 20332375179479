import { VerifyDepositDialogMode } from 'src/@types/transaction';
import {
  setSelectedTransactionId,
  setVerifyDepositTransactionDialog,
  setVerifyDepositTransactionForm,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export default function useDepositVerificationDialog() {
  const toggle = useSelector(
    (state) =>
      state.transaction.default.revenue.depositVerification.dialog.toggle,
  );

  //hook
  const dispatch = useDispatch();

  const openTransactionDialog = ({
    mode = 'VERIFY',
    transactionId,
  }: {
    transactionId: number;
    mode?: VerifyDepositDialogMode;
  }) => {
    dispatch(setSelectedTransactionId(transactionId));
    dispatch(setVerifyDepositTransactionDialog({ toggle: true, mode }));
  };

  const closeTransactionDialog = () => {
    dispatch(setSelectedTransactionId(undefined));
    dispatch(setVerifyDepositTransactionDialog({ toggle: false }));
    dispatch(
      setVerifyDepositTransactionForm({
        transactionVerificationImage: undefined,
      }),
    );
  };

  return { openTransactionDialog, closeTransactionDialog, toggle };
}
