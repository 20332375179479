// routes
import { PATH_CONSULTANT } from '../../../routes/paths';
// components
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { ConversationTypeType } from 'src/@types/conversation';
import { ConsultantRoleType } from 'src/@types/user';
import Iconify from 'src/components/Iconify';
import { SidebarContext } from 'src/contexts/sidebarContext';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => {
  return (
    <SvgIconStyle
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1 }}
    />
  );
};

const ICONS = {
  servicesPlumber: getIcon('services-plumber-icon'),
  archive: getIcon('archive-icon'),
  archiveLine: getIcon('archive-line-icon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  book: getIcon('book-icon'),
  globe: getIcon('globe-icon'),
  publisher: getIcon('publisher-icon'),
  description: getIcon('description-icon'),
  pen: getIcon('pen-icon'),
  admin: getIcon('admin-icon'),
  termPolicy: getIcon('term-policy-icon'),
  customGraduationHat: getIcon('custom-graduation-hat'),
  customFileTick: getIcon('custom-file-tick'),
  checkClass: getIcon('check_class_icon'),
  bookTool: getIcon('book-tool-icon'),
  penTool1: getIcon('pen-tool-icon-1'),
  tuitionReminder: getIcon('tuition-fee'),
  combine_classes: getIcon('combine_classes'),
  combine_teaching_classes: getIcon('combine_teaching_classes'),
  reMarketing: getIcon('remarketing'),
  studyReminder: getIcon('study-reminder'),
  classRecommendation: getIcon('class-recommendation'),
  teachReminder: getIcon('teach-reminder'),
};

// ----------------------------------------------------------------------
// component get count container
const CountUserAttendanceManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultantManage.countUserAttendance || 0}</>;
};

const CountConversationManageContainer = ({
  type,
}: {
  type: ConversationTypeType;
}) => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (type === ConversationTypeType.CONSULTANT_AND_STUDENT) {
    return (
      <>
        {dataCountStaticConsultantManage.countPendingConversationConsultantStudent ||
          0}
      </>
    );
  }
  if (type === ConversationTypeType.CONSULTANT_AND_TEACHER) {
    return (
      <>
        {dataCountStaticConsultantManage.countPendingConversationConsultantTeacher ||
          0}
      </>
    );
  }
  return <>0</>;
};

const CountCheckClassContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultant.countPendingCheckClass || 0}</>;
};

const CountCheckClassManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultantManage.countPendingCheckClass || 0}</>;
};

const CountReminderFeeStudyContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticConsultant.countPendingCallFeeLearningReminder || 0}</>
  );
};

const CountReminderFeeStudyManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticConsultantManage.countPendingCallFeeLearningReminder || 0}
    </>
  );
};

const CountPendingCallReminderReMarketingContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticConsultant.countPendingCallReminderReMarketing || 0}</>
  );
};

const CountPendingCallReminderReMarketingManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticConsultantManage.countPendingCallReminderReMarketing || 0}
    </>
  );
};

const CountPendingCallReminderCombineClassesContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticConsultant.countPendingCallReminderCombineClasses || 0}</>
  );
};

const CountPendingCallReminderCombineClassesManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticConsultantManage.countPendingCallReminderCombineClasses ||
        0}
    </>
  );
};

const CountPendingCallReminderStudyContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultant.countPendingCallReminderStudy || 0}</>;
};

const CountPendingCallReminderStudyManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticConsultantManage.countPendingCallReminderStudy || 0}</>
  );
};

const CountPendingCvContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultant.countPendingCvRequest || 0}</>;
};

const CountPendingCvManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultantManage.countPendingCvRequest || 0}</>;
};

const CountPendingCallFeeReminderTeacherContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticConsultant.countPendingCallFeeTeachingReminder || 0}</>
  );
};

const CountPendingCallFeeReminderTeacherManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticConsultantManage.countPendingCallFeeTeachingReminder || 0}
    </>
  );
};

const CountPendingCallReminderCombineTeachingClassesContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticConsultant.countPendingCallReminderCombineTeachingClasses ||
        0}
    </>
  );
};

const CountPendingCallReminderCombineTeachingClassesManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticConsultantManage.countPendingCallReminderCombineTeachingClasses ||
        0}
    </>
  );
};

const CountPendingCallReminderTeachContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return <>{dataCountStaticConsultant.countPendingCallReminderTeach || 0}</>;
};

const CountPendingCallReminderTeachManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticConsultantManage.countPendingCallReminderTeach || 0}</>
  );
};

const CountPendingCallReminderTeacherNeedProposalContainer = () => {
  const { dataCountStaticConsultant, isPendingCountStaticConsultant } =
    useContext(SidebarContext);

  if (isPendingCountStaticConsultant || !dataCountStaticConsultant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {(dataCountStaticConsultant.countPendingCallReminderTeacherNeedProposalOffline ||
        0) +
        (dataCountStaticConsultant.countPendingCallReminderTeacherNeedProposalOnline ||
          0)}
    </>
  );
};

const CountPendingCallReminderTeacherNeedProposalManageContainer = () => {
  const {
    dataCountStaticConsultantManage,
    isPendingCountStaticConsultantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticConsultantManage ||
    !dataCountStaticConsultantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {(dataCountStaticConsultantManage.countPendingCallReminderTeacherNeedProposalOffline ||
        0) +
        (dataCountStaticConsultantManage.countPendingCallReminderTeacherNeedProposalOnline ||
          0)}
    </>
  );
};

// STAFF
// ----------------------------------------------------------------------
const navConfig = [
  // STUDENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Người học',
    icon: ICONS.penTool1,
    items: [
      {
        title: 'Tư vấn học',
        path: PATH_CONSULTANT.manageStudentCounseling,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountConversationManageContainer
              type={ConversationTypeType.CONSULTANT_AND_STUDENT}
            />
          </Typography>
        ),
      },
      {
        title: 'Xét duyệt lớp',
        path: PATH_CONSULTANT.checkClass,
        icon: ICONS.checkClass,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountCheckClassContainer />
          </Typography>
        ),
      },
      {
        title: 'Xét duyệt lớp',
        path: PATH_CONSULTANT.manageCheckClass,
        icon: ICONS.checkClass,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountCheckClassManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc học phí',
        path: PATH_CONSULTANT.tuitionReminderOfStudent,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountReminderFeeStudyContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc học phí',
        path: PATH_CONSULTANT.manageTuitionReminderOfStudent,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountReminderFeeStudyManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Tiếp thị lại',
        path: PATH_CONSULTANT.reMarketing,
        icon: ICONS.reMarketing,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallReminderReMarketingContainer />
          </Typography>
        ),
      },
      {
        title: 'Tiếp thị lại',
        path: PATH_CONSULTANT.manageReMarketing,
        icon: ICONS.reMarketing,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallReminderReMarketingManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Ghép lớp học',
        path: PATH_CONSULTANT.combine_classes,
        icon: ICONS.combine_classes,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallReminderCombineClassesContainer />
          </Typography>
        ),
      },
      {
        title: 'Ghép lớp học',
        path: PATH_CONSULTANT.manage_combine_classes,
        icon: ICONS.combine_classes,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallReminderCombineClassesManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc nhở học',
        path: PATH_CONSULTANT.studyReminder,
        icon: ICONS.studyReminder,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallReminderStudyContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc nhở học',
        path: PATH_CONSULTANT.manageStudyReminder,
        icon: ICONS.studyReminder,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallReminderStudyManageContainer />
          </Typography>
        ),
      },
    ],
  },
  // TEACHER
  // ----------------------------------------------------------------------
  {
    subheader: 'Người dạy',
    icon: ICONS.bookTool,
    items: [
      {
        title: 'Tư vấn dạy',
        path: PATH_CONSULTANT.manageTeacherCounseling,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountConversationManageContainer
              type={ConversationTypeType.CONSULTANT_AND_TEACHER}
            />
          </Typography>
        ),
      },
      // Industry
      {
        title: 'Kiểm duyệt CV',
        path: PATH_CONSULTANT.checkCv,
        icon: ICONS.customFileTick,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCvContainer />
          </Typography>
        ),
      },
      {
        title: 'Kiểm duyệt CV',
        path: PATH_CONSULTANT.manageCheckCv,
        icon: ICONS.customFileTick,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCvManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc lệ phí',
        path: PATH_CONSULTANT.tuitionReminderOfTeacher,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallFeeReminderTeacherContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc lệ phí',
        path: PATH_CONSULTANT.manageTuitionReminderOfTeacher,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallFeeReminderTeacherManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Đề xuất lớp',
        path: PATH_CONSULTANT.classRecommendation,
        icon: ICONS.classRecommendation,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallReminderTeacherNeedProposalContainer />
          </Typography>
        ),
      },
      {
        title: 'Đề xuất lớp',
        path: PATH_CONSULTANT.manageClassRecommendation,
        icon: ICONS.classRecommendation,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallReminderTeacherNeedProposalManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Ghép lớp dạy',
        path: PATH_CONSULTANT.combine_teaching_classes,
        icon: ICONS.combine_teaching_classes,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallReminderCombineTeachingClassesContainer />
          </Typography>
        ),
      },
      {
        title: 'Ghép lớp dạy',
        path: PATH_CONSULTANT.manage_combine_teaching_classes,
        icon: ICONS.combine_teaching_classes,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallReminderCombineTeachingClassesManageContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc nhở dạy',
        path: PATH_CONSULTANT.teachReminder,
        icon: ICONS.teachReminder,
        roles: [ConsultantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountPendingCallReminderTeachContainer />
          </Typography>
        ),
      },
      {
        title: 'Nhắc nhở dạy',
        path: PATH_CONSULTANT.manageTeachReminder,
        icon: ICONS.teachReminder,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountPendingCallReminderTeachManageContainer />
          </Typography>
        ),
      },
    ],
  },
  // CONSULTANT
  // ----------------------------------------------------------------------
  {
    subheader: 'Nhân viên',
    icon: ICONS.bookTool,
    items: [
      // Industry
      {
        title: 'Năng suất làm',
        path: PATH_CONSULTANT.employeeProductivity,
        icon: ICONS.customFileTick,
        roles: [ConsultantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountUserAttendanceManageContainer />
          </Typography>
        ),
      },
    ],
  },
];

export default navConfig;
