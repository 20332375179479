import { CommonDashboardSideType } from 'src/@types/dashboard';
import {
  resetManagerDeleteDialog,
  setManagerDeleteDialog,
  setSelectedUndefinedTransactionDeleteRequestId,
} from 'src/redux/slices/undefined-transaction-delete-request.slice';

import { useDispatch, useSelector } from 'src/redux/store';

export function useDeleteUndefinedTransactionRequestManagerDialog(
  dashboardSide: CommonDashboardSideType,
) {
  //state
  const toggle = useSelector(
    (state) =>
      state.undefinedTransactionDeleteRequest.manager[dashboardSide].delete
        .dialog.toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialog = (id?: number) => {
    dispatch(setSelectedUndefinedTransactionDeleteRequestId(id));
    dispatch(
      setManagerDeleteDialog({
        dialog: {
          toggle: true,
        },
        dashboardSide: dashboardSide,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(
      resetManagerDeleteDialog({
        dashboardSide: dashboardSide,
      }),
    );
  };

  return { toggle, openDialog, closeDialog };
}
