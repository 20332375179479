import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardSideType } from 'src/@types/dashboard';
import { FileData } from 'src/@types/file';
import { MoneyHolderAccountTypeType } from 'src/@types/money-holder-account';
import {
  CheckBankAccountDialog,
  CreateCompanyOtherExpense,
  CreateDialog,
  DepositVerificationDialog,
  Filter,
  TransactionState,
  TransactionStatusType,
  TransactionTypeType,
  VerComTranDiag,
  VerifyCompanyOtherExpenseTransactionFilter,
  VerifyCompanyTransactionFilter,
  WithdrawVerificationDialog,
} from 'src/@types/transaction';
import { UserType } from 'src/@types/user';

const initialState: TransactionState = {
  default: {
    revenue: {
      depositVerification: {
        dialog: {
          toggle: false,
          mode: 'VERIFY',
        },
        filter: {
          searchCharacter: '',
          userType: UserType.TEACHER,
        },
        form: {},
      },
      createCompanyWithdraw: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
    },
    expense: {
      withdrawVerification: {
        checkBankAccountDialog: {
          isFulfilled: false,
          toggle: false,
        },
        dialog: {
          toggle: false,
          mode: 'VERIFY',
        },
        filter: {
          searchCharacter: '',
          userType: UserType.TEACHER,
        },
      },
      createCompanyDeposit: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
      createCompanyWithdraw: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
    },
    internal: {
      createCompanyDeposit: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
      createCompanyWithdraw: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
      createCompanyOtherExpense: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
    },
  },
  manager: {
    revenue: {
      companyWithdrawVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_REVENUE_ACCOUNT_WITHDRAW,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.REVENUE,
        },
        dialog: {
          toggle: false,
        },
      },
    },
    expense: {
      companyDepositVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_DEPOSIT,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.EXPENSE,
        },
        dialog: {
          toggle: false,
        },
      },
      companyWithdrawVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_WITHDRAW,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.EXPENSE,
        },
        dialog: {
          toggle: false,
        },
      },
    },
    internal: {
      companyDepositVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_INTERNAL_ACCOUNT_DEPOSIT,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.INTERNAL,
        },
        dialog: {
          toggle: false,
        },
      },
      companyWithdrawVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_INTERNAL_ACCOUNT_WITHDRAW,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.INTERNAL,
        },
        dialog: {
          toggle: false,
        },
      },
      companyOtherExpenseVerification: {
        dialog: {
          toggle: false,
        },
        filter: {
          type: TransactionTypeType.COMPANY_INTERNAL_ACCOUNT_OTHER_EXPENSE,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          searchCharacter: '',
        },
      },
    },
  },
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    //Common
    setSelectedTransactionId(state, action: PayloadAction<number | undefined>) {
      state.selectedTransactionId = action.payload;
    },

    //Manager
    //--Verify other expense
    //----Filter
    setCompanyOtherExpenseVerificationFilter: (
      state,
      action: PayloadAction<
        Partial<VerifyCompanyOtherExpenseTransactionFilter>
      >,
    ) => {
      state.manager.internal.companyOtherExpenseVerification.filter = {
        ...state.manager.internal.companyOtherExpenseVerification.filter,
        ...action.payload,
      };
    },

    //----Reset filter
    resetCompanyOtherExpenseVerificationFilter: (
      state,
      action: PayloadAction<
        Partial<VerifyCompanyOtherExpenseTransactionFilter>
      >,
    ) => {
      state.manager.internal.companyOtherExpenseVerification.filter = {
        ...initialState.manager.internal.companyOtherExpenseVerification.filter,
        ...action.payload,
      };
    },

    //----Dialog
    setVerifyOtherExpenseManagerDialog: (
      state,
      action: PayloadAction<Partial<VerComTranDiag>>,
    ) => {
      state.manager.internal.companyOtherExpenseVerification.dialog = {
        ...state.manager.internal.companyOtherExpenseVerification.dialog,
        ...action.payload,
      };
    },

    //----Reset Dialog
    resetVerifyOtherExpenseManagerDialog: (state) => {
      state.manager.internal.companyOtherExpenseVerification.dialog =
        initialState.manager.internal.companyOtherExpenseVerification.dialog;
    },

    //--Verify company deposit
    //----Filter
    setCompanyDepositVerificationFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<VerifyCompanyTransactionFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { filter, dashboardSide } = action.payload;

      if (dashboardSide === 'revenue') return;

      state.manager[dashboardSide].companyDepositVerification.filter = {
        ...state.manager[dashboardSide].companyDepositVerification.filter,
        ...filter,
      };
    },

    //----Reset filter
    resetCompanyDepositVerificationFilter: (
      state,
      action: PayloadAction<{
        filter?: Partial<VerifyCompanyTransactionFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { filter, dashboardSide } = action.payload;

      if (dashboardSide === 'revenue') return;

      state.manager[dashboardSide].companyDepositVerification.filter = {
        ...initialState.manager[dashboardSide].companyDepositVerification
          .filter,
        ...filter,
      };
    },

    //------Filter
    setCompanyWithdrawVerificationFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<VerifyCompanyTransactionFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { filter, dashboardSide } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.filter = {
        ...state.manager[dashboardSide].companyWithdrawVerification.filter,
        ...filter,
      };
    },

    //----Reset filter
    resetCompanyWithdrawVerificationFilter: (
      state,
      action: PayloadAction<{
        dashboardSide: DashboardSideType;
        filter?: Partial<VerifyCompanyTransactionFilter>;
      }>,
    ) => {
      const { dashboardSide, filter } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.filter = {
        ...initialState.manager[dashboardSide].companyWithdrawVerification
          .filter,
        ...filter,
      };
    },

    //----Dialog
    setVerifyDepositManagerDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<VerComTranDiag>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      if (dashboardSide === 'revenue') return;

      state.manager[dashboardSide].companyDepositVerification.dialog = {
        ...state.manager[dashboardSide].companyDepositVerification.dialog,
        ...dialog,
      };
    },

    //----Reset Dialog
    resetVerifyDepositManagerDialog: (
      state,
      action: PayloadAction<{
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dashboardSide } = action.payload;

      if (dashboardSide === 'revenue') return;

      state.manager[dashboardSide].companyDepositVerification.dialog =
        initialState.manager[dashboardSide].companyDepositVerification.dialog;
    },

    //----Dialog
    setVerifyWithdrawManagerDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<VerComTranDiag>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.dialog = {
        ...state.manager[dashboardSide].companyWithdrawVerification.dialog,
        ...dialog,
      };
    },

    //----Reset Dialog
    resetVerifyWithdrawManagerDialog: (
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.dialog =
        initialState.manager[dashboardSide].companyWithdrawVerification.dialog;
    },

    //Staff
    //--Create Company Transaction
    //----Dialog
    setCreateCompanyDepositDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<CreateDialog>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      if (dashboardSide === 'revenue') return;

      state.default[dashboardSide].createCompanyDeposit.dialog = {
        ...state.default[dashboardSide].createCompanyDeposit.dialog,
        ...dialog,
      };
    },

    //----Reset Dialog
    resetCreateCompanyDepositDialog: (
      state,
      action: PayloadAction<{
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dashboardSide } = action.payload;

      if (dashboardSide === 'revenue') return;

      state.default[dashboardSide].createCompanyDeposit.dialog =
        initialState.default[dashboardSide].createCompanyDeposit.dialog;
    },

    //----Dialog
    setCreateCompanyWithdrawDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<CreateDialog>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.default[dashboardSide].createCompanyWithdraw.dialog = {
        ...state.default[dashboardSide].createCompanyWithdraw.dialog,
        ...dialog,
      };
    },

    resetCreateCompanyWithdrawDialog: (
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.default[dashboardSide].createCompanyWithdraw.dialog =
        initialState.default[dashboardSide].createCompanyWithdraw.dialog;
    },

    //--Dialog
    setCreateCompanyOtherExpenseDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<CreateCompanyOtherExpense['dialog']>;
      }>,
    ) => {
      const { dialog } = action.payload;

      state.default.internal.createCompanyOtherExpense.dialog = {
        ...state.default.internal.createCompanyOtherExpense.dialog,
        ...dialog,
      };
    },

    resetCreateCompanyOtherExpenseDialog: (state) => {
      state.default.internal.createCompanyOtherExpense.dialog =
        initialState.default.internal.createCompanyOtherExpense.dialog;
    },

    //--Verify Client Deposit Transaction
    //----Set form
    setVerifyDepositTransactionForm: (
      state,
      action: PayloadAction<{
        transactionVerificationImage?: File | FileData;
      }>,
    ) => {
      state.default.revenue.depositVerification.form = {
        ...state.default.revenue.depositVerification.form,
        ...action.payload,
      };
    },

    //----Set Dialog
    setVerifyDepositTransactionDialog: (
      state,
      action: PayloadAction<Partial<DepositVerificationDialog>>,
    ) => {
      state.default.revenue.depositVerification.dialog = {
        ...state.default.revenue.depositVerification.dialog,
        ...action.payload,
      };
    },

    //----Set Filter
    setVerifyDepositTransactionFilter: (
      state,
      action: PayloadAction<Partial<Filter>>,
    ) => {
      state.default.revenue.depositVerification.filter = {
        ...state.default.revenue.depositVerification.filter,
        ...action.payload,
      };
    },

    //----Reset Filter
    resetVerifyDepositTransactionFilter: (state) => {
      state.default.revenue.depositVerification.filter =
        initialState.default.revenue.depositVerification.filter;
    },

    //--Verify Client Withdraw Transaction
    //----Set Dialog
    setVerifyWithdrawTransactionDialog: (
      state,
      action: PayloadAction<Partial<WithdrawVerificationDialog>>,
    ) => {
      state.default.expense.withdrawVerification.dialog = {
        ...state.default.expense.withdrawVerification.dialog,
        ...action.payload,
      };
    },

    //----Set Check Bank Account Dialog
    setVerifyWithdrawTransactionCheckBankAccountDialog: (
      state,
      action: PayloadAction<Partial<CheckBankAccountDialog>>,
    ) => {
      state.default.expense.withdrawVerification.checkBankAccountDialog = {
        ...state.default.expense.withdrawVerification.checkBankAccountDialog,
        ...action.payload,
      };
    },

    //----Set Filter
    setVerifyWithdrawTransactionFilter: (
      state,
      action: PayloadAction<Partial<Filter>>,
    ) => {
      state.default.expense.withdrawVerification.filter = {
        ...state.default.expense.withdrawVerification.filter,
        ...action.payload,
      };
    },

    //----Reset filter
    resetVerifyWithdrawTransactionFilter: (state) => {
      state.default.expense.withdrawVerification.filter =
        initialState.default.expense.withdrawVerification.filter;
    },
  },
});

export default slice.reducer;

export const {
  setVerifyDepositTransactionForm,
  resetVerifyDepositTransactionFilter,
  resetVerifyDepositManagerDialog,
  resetVerifyWithdrawTransactionFilter,
  resetVerifyWithdrawManagerDialog,
  setCompanyDepositVerificationFilter,
  setCompanyWithdrawVerificationFilter,
  setCreateCompanyDepositDialog,
  setCreateCompanyWithdrawDialog,
  setSelectedTransactionId,
  setVerifyDepositTransactionDialog,
  setVerifyDepositTransactionFilter,
  setVerifyWithdrawTransactionDialog,
  setVerifyWithdrawTransactionCheckBankAccountDialog,
  setVerifyWithdrawTransactionFilter,
  setVerifyDepositManagerDialog,
  setVerifyWithdrawManagerDialog,
  resetCompanyDepositVerificationFilter,
  resetCompanyWithdrawVerificationFilter,
  resetCreateCompanyDepositDialog,
  resetCreateCompanyWithdrawDialog,
  resetCreateCompanyOtherExpenseDialog,
  setCreateCompanyOtherExpenseDialog,
  resetCompanyOtherExpenseVerificationFilter,
  setCompanyOtherExpenseVerificationFilter,
  setVerifyOtherExpenseManagerDialog,
  resetVerifyOtherExpenseManagerDialog,
} = slice.actions;
