import { ReactNode, Suspense, createContext, lazy } from 'react';
import { Notification } from 'src/@types/notification';
import LoadingScreen from 'src/components/LoadingScreen';
import useToggle from 'src/hooks/useToggle';
import { setSelectedUndefinedTransactionDeleteRequestId } from 'src/redux/slices/undefined-transaction-delete-request.slice';
import { useDispatch } from 'src/redux/store';
import { useVerifyCompanyMoneyHolderAccountVerificationDialog } from 'src/sections/accountant/@dashboard/@manager/company-money-holder-account-verification/dialog/useVerifyCompanyMoneyHolderAccountVerificationDialog';
import useVerifyTransactionCompensationLogDialog from 'src/sections/accountant/@dashboard/@manager/transaction-compensation-log/dialog/useVerifyDialog';
import { useDeleteUndefinedTransactionRequestManagerDialog } from 'src/sections/accountant/@dashboard/@manager/undefined-transaction-delete-request/dialog/useDeleteUndefinedTransactionRequestManagerDialog';
import { useVerifyUndefinedTransactionRequestManagerDialog } from 'src/sections/accountant/@dashboard/@manager/undefined-transaction-verify-request/dialog/useVerifyUndefinedTransactionRequestManagerDialog';
import { useCreateDialog as useCreateCompanyDepositTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/common/deposit/dialog/useCreateDialog';
import { useCreateDialog as useCreateCompanyWithdrawTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/common/withdraw/dialog/useCreateDialog';
import { useCreateDialog as useCreateCompanyOtherExpenseTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/common/other-expense/dialog/useCreateDialog';
import { useCreateDialog as useCreateExpenseReportTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/expense/report/dialog/useCreateDialog';
import { useCreateDialog as useCreateIncomeReportTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/income/report/dialog/useCreateDialog';
import { useVerifyDialog as useVerifyCompanyDepositTransactionDialog } from 'src/sections/accountant/@dashboard/@manager/company-transaction/deposit/dialog/useVerifyDialog';
import { useVerifyDialog as useVerifyCompanyWithdrawTransactionDialog } from 'src/sections/accountant/@dashboard/@manager/company-transaction/withdraw/dialog/useVerifyDialog';
import { useVerifyDialog as useVerifyCompanyOtherExpenseTransactionDialog } from 'src/sections/accountant/@dashboard/@manager/company-transaction/other-expense/dialog/useVerifyDialog';
import useCreateTransactionCompensationLogDialog from 'src/sections/accountant/@dashboard/compensation-log/dialog/useCreateDialog';
import useViewTransactionCompensationLogDialog from 'src/sections/accountant/@dashboard/compensation-log/dialog/useViewDialog';
import useTransactionCompensationPaymentDialog from 'src/sections/accountant/@dashboard/compensation/dialog/useTransactionCompensationPaymentDialog';
import useDepositVerificationDialog from 'src/sections/accountant/@dashboard/deposit-request/dialog/useDepositVerificationDialog';
import { useDepositSupportRequestVerificationDialog } from 'src/sections/accountant/@dashboard/deposit-support-request/dialog/useDepositSupportRequestVerificationDialog';
import { useVerifyDialog as userVerifyDepositTransactionReportDialog } from 'src/sections/accountant/@dashboard/transaction-report/deposit/dialog/useVerifyDialog';
import { useVerifyDialog as userVerifyWithdrawTransactionReportDialog } from 'src/sections/accountant/@dashboard/transaction-report/withdraw/dialog/useVerifyDialog';
import { useVerifyUndefinedTransactionDialog } from 'src/sections/accountant/@dashboard/undefined-transaction-verify-request/dialog/useVerifyUndefinedTransactionRequestDialog';
import { useCreateCompanyMoneyHolderAccountVerificationDialog } from 'src/sections/accountant/@dashboard/verify-before-end/dialog/useCreateCompanyMoneyHolderAccountVerificationDialog';
import { useVerifyBeforeEndDialog } from 'src/sections/accountant/@dashboard/verify-before-end/dialog/useVerifyBeforeEndDialog';
import { useWithdrawVerificationDialog } from 'src/sections/accountant/@dashboard/withdraw-request/dialog/useWithdrawVerificationDialog';
import { useWithdrawSupportVerificationDialog } from 'src/sections/accountant/@dashboard/withdraw-support-request/dialog/useWithdrawSupportVerificationDialog';

const CreateTransactionCompensationLogDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/compensation-log/dialog/CreateDialog'
    ),
);
const CreateCompanyWithdrawTransactionDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/withdraw/dialog/WithdrawDialogContainer'
    ),
);
const ViewCompanyOtherExpenseTransactionDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/other-expense/dialog/ViewDialogContainer'
    ),
);
const CreateCompanyDepositTransactionDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/deposit/dialog/DepositDialogContainer'
    ),
);
const RejectComDepositTranDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/deposit/dialog/RejectDialog'
    ),
);
const RejectComWithdrawTranDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/withdraw/dialog/RejectDialog'
    ),
);
const CreateIncomeReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/income/report/dialog/CreateDialog'
    ),
);
const CreateExpenseReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/expense/report/dialog/CreateDialog'
    ),
);
const RevenueTranReptRejectedDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/income/report/dialog/RejectDialog'
    ),
);
const ExpenseTranReptRejectedDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/expense/report/dialog/RejectDialog'
    ),
);
const VerifyCompanyWithdrawTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/company-transaction/withdraw/dialog/VerifyDialog'
    ),
);
const VerifyCompanyDepositTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/company-transaction/deposit/dialog/VerifyDialog'
    ),
);
const VerifyCompanyOtherExpenseTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/company-transaction/other-expense/dialog/VerifyDialog'
    ),
);
const VerifyDepositTransactionReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/transaction-report/deposit/dialog/VerifyDialog'
    ),
);
const VerifyWithdrawTransactionReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/transaction-report/withdraw/dialog/VerifyDialog'
    ),
);
const DeleteUndefinedTransactionConfirmDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction-delete-request/dialog/DeleteUndefinedTransactionConfirmDialog'
    ),
);
const DeleteUndefinedTransactionRequestManagerDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/undefined-transaction-delete-request/dialog/DeleteUndefinedTransactionRequestManagerDialog'
    ),
);
const RejectUndefinedTransactionStaffDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/RejectUndefinedTransactionStaffDialog'
    ),
);
const RejectVerifyUndefinedTransactionRequestReasonDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction-verify-request/dialog/RejectReasonDialog'
    ),
);
const VerifyUndefinedTransactionRequestManagerDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/undefined-transaction-verify-request/dialog/VerifyUndefinedTransactionRequestManagerDialog'
    ),
);
//
const CreateUndefinedTransactionVerifyRequestDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction-verify-request/dialog/CreateDialogContainer'
    ),
);
const ViewUndefinedTransactionVerifyRequestDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction-verify-request/dialog/ViewDialogContainer'
    ),
);
const DepositVerificationDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-request/dialog/DepositVerificationDialog'
    ),
);
const VerifyDepSupReqVerDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-support-request/dialog/VerifyDepSupReqVerDialogContainer'
    ),
);
const ViewDepSupReqVerByUndDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-support-request/dialog/ViewDepSupReqVerByUndDialogContainer'
    ),
);
const ViewDepSupReqVerDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-support-request/dialog/ViewDepSupReqVerDialogContainer'
    ),
);
const WithdrawVerificationDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-request/dialog/WithdrawVerificationDialog'
    ),
);
const VerifyWithSupReqVerContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-support-request/dialog/VerifyWithSupReqVerContainer'
    ),
);
const ViewWithSupReqVerContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-support-request/dialog/ViewWithSupReqVerContainer'
    ),
);
const ViewWithSupReqVerByUndContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-support-request/dialog/ViewWithSupReqVerByUndContainer'
    ),
);
const VerifyCompanyMoneyHolderAccountVerificationDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/company-money-holder-account-verification/dialog/VerifyCompanyMoneyHolderAccountVerificationDialogContainer'
    ),
);
const CreateCompanyMoneyHolderAccountVerificationDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/verify-before-end/dialog/CreateCompanyMoneyHolderAccountVerificationDialogContainer'
    ),
);
const VerifyBeforeEndDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/verify-before-end/dialog/VerifyBeforeEndDialog'
    ),
);
const TransactionCompensationPaymentDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/compensation/dialog/TransactionCompensationPaymentDialog'
    ),
);
const VerifyTransactionCompensationLogDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/@manager/transaction-compensation-log/dialog/VerifyDialog'
    ),
);
const ViewTransactionCompensationLogDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/compensation-log/dialog/ViewDialog'
    ),
);

export type DialogContextType = {
  handleOpenRejDelUndTranStaffDia: (notification: Notification) => void;
  handleOpenConDelUndTranStaffDia: (notification: Notification) => void;
  handleOpenRejVerUndTranReasonsDia: (notification: Notification) => void;
  handleOpenRejVerDepositTranReptReasonsDia: (
    notification: Notification,
  ) => void;
  handleOpenRejVerWithdrawTranReptReasonsDia: (
    notification: Notification,
  ) => void;
  handleOpenRejComWithdrawTranVerReasonsDia: (
    notification: Notification,
  ) => void;
  handleOpenRejComDepositTranVerReasonsDia: (
    notification: Notification,
  ) => void;
};

const DialogContext = createContext<DialogContextType | null>(null);

type DialogProviderProps = {
  children: ReactNode;
};

function DialogProvider({ children }: DialogProviderProps) {
  //Hook
  const dispatch = useDispatch();

  //Toggle Hook
  const {
    onOpen: openRejDelUndTranStaffDia,
    onClose: closeRejDelUndTranStaffDia,
    toggle: rejDelUndTranStaffDiaToggle,
    value: rejDelUndTranStaffDiaValue,
  } = useToggle();
  const {
    onOpen: openConDelUndTranStaffDia,
    onClose: closeConDelUndTranStaffDia,
    toggle: conDelUndTranStaffDiaToggle,
  } = useToggle();
  const {
    onOpen: openRejVerUndTranReasonsDia,
    onClose: closeRejVerUndTranReasonsDia,
    toggle: rejVerUndTranReasonsDiaToggle,
    value: rejVerUndTranReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejVerDepositTranReptReasonsDia,
    onClose: closeRejVerDepositTranReptReasonsDia,
    toggle: rejVerDepositTranReptReasonsDiaToggle,
    value: rejVerDepositTranReptReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejVerWithdrawTranReptReasonsDia,
    onClose: closeRejVerWithdrawTranReptReasonsDia,
    toggle: rejVerWithdrawTranReptReasonsDiaToggle,
    value: rejVerWithdrawTranReptReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejComWithdrawTranVerReasonsDia,
    onClose: closeRejComWithdrawTranVerReasonsDia,
    toggle: rejComWithdrawTranVerReasonsDiaToggle,
    value: rejComWithdrawTranVerReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejComDepositTranVerReasonsDia,
    onClose: closeRejComDepositTranVerReasonsDia,
    toggle: rejComDepositTranVerReasonsDiaToggle,
    value: rejComDepositTranVerReasonsDiaValue,
  } = useToggle();

  //Specific hooks
  const {
    close: closeRevenueCompanyWithdrawTransaction,
    toggle: revenueCompanyCompanyWithdrawTransactionToggle,
  } = useCreateCompanyWithdrawTransactionDialog({
    dashboardSide: 'revenue',
  });
  const {
    close: closeExpenseCompanyWithdrawTransaction,
    toggle: expenseCompanyCompanyWithdrawTransactionToggle,
  } = useCreateCompanyWithdrawTransactionDialog({
    dashboardSide: 'expense',
  });
  const {
    close: closeInternalCompanyWithdrawTransaction,
    toggle: internalCompanyCompanyWithdrawTransactionToggle,
  } = useCreateCompanyWithdrawTransactionDialog({
    dashboardSide: 'internal',
  });
  const {
    close: closeCompanyOtherExpenseTransaction,
    toggle: companyOtherExpenseTransactionToggle,
    mode: companyOtherExpenseTransactionMode,
  } = useCreateCompanyOtherExpenseTransactionDialog();
  const {
    close: closeExpenseCompanyDepositTransaction,
    toggle: expenseCompanyDepositTransactionToggle,
  } = useCreateCompanyDepositTransactionDialog({
    dashboardSide: 'expense',
  });
  const {
    close: closeInternalCompanyDepositTransaction,
    toggle: internalCompanyDepositTransactionToggle,
  } = useCreateCompanyDepositTransactionDialog({
    dashboardSide: 'internal',
  });
  const {
    close: closeIncomeReportDialogClose,
    toggle: incomeReportDialogToggle,
  } = useCreateIncomeReportTransactionDialog();
  const {
    close: closeExpenseReportDialogClose,
    toggle: expenseReportDialogToggle,
  } = useCreateExpenseReportTransactionDialog();
  const {
    close: closeVerifyDepositTransactionReportDialog,
    toggle: verifyDepositTransactionReportDialogToggle,
  } = userVerifyDepositTransactionReportDialog();
  const {
    close: closeVerifyWithdrawTransactionReportDialog,
    toggle: verifyWithdrawTransactionReportDialogToggle,
  } = userVerifyWithdrawTransactionReportDialog();
  const {
    closeDialog: closeRevenueDelUndTransReqManDia,
    toggle: delRevenueUndTransReqManDiaToggle,
  } = useDeleteUndefinedTransactionRequestManagerDialog('revenue');
  const {
    closeDialog: closeExpenseDelUndTransReqManDia,
    toggle: delExpenseUndTransReqManDiaToggle,
  } = useDeleteUndefinedTransactionRequestManagerDialog('expense');
  const {
    closeDialog: closeRevenueVerUndTransReqManDia,
    toggle: verRevenueUndTransReqManDiaToggle,
  } = useVerifyUndefinedTransactionRequestManagerDialog('revenue');
  const {
    closeDialog: closeExpenseVerUndTransReqManDia,
    toggle: verExpenseUndTransReqManDiaToggle,
  } = useVerifyUndefinedTransactionRequestManagerDialog('expense');
  const {
    closeDialog: closeRevenueUndTranVerReqDialog,
    toggle: openRevenueUndTranVerReqToggle,
    mode: revenueUndTranVerReqMode,
  } = useVerifyUndefinedTransactionDialog('revenue');
  const {
    closeDialog: closeExpenseUndTranVerReqDialog,
    toggle: openExpenseUndTranVerReqToggle,
    mode: expenseUndTranVerReqMode,
  } = useVerifyUndefinedTransactionDialog('expense');
  const {
    closeDialog: closeVerifyCompanyWithdrawTransactionDialog,
    toggle: verifyCompanyWithdrawTransactionDialogToggle,
  } = useVerifyCompanyWithdrawTransactionDialog();
  const {
    closeDialog: closeVerifyCompanyOtherExpenseTransactionDialog,
    toggle: verifyCompanyOtherExpenseTransactionDialogToggle,
  } = useVerifyCompanyOtherExpenseTransactionDialog();
  const {
    closeDialog: closeExpenseVerifyCompanyDepositTransactionDialog,
    toggle: expenseVerifyCompanyDepositTransactionDialogToggle,
  } = useVerifyCompanyDepositTransactionDialog({
    dashboardSide: 'expense',
  });
  const {
    closeDialog: closeInternalVerifyCompanyDepositTransactionDialog,
    toggle: internalVerifyCompanyDepositTransactionDialogToggle,
  } = useVerifyCompanyDepositTransactionDialog({
    dashboardSide: 'internal',
  });
  const {
    closeTransactionDialog: closeVerifyDepositRequestDialog,
    toggle: verifyDepositRequestToggle,
  } = useDepositVerificationDialog();
  const {
    closeDialog: closeVerifyWithdrawRequestDialog,
    toggle: verifyWithdrawRequestToggle,
  } = useWithdrawVerificationDialog();
  const {
    closeTransactionDialog: closeDepositSupportRequestDialog,
    toggle: depositSupportRequestToggle,
    mode: depositSupportRequestMode,
    isShowByUndefinedTransaction:
      isDepositSupportRequestShowByUndefinedTransaction,
  } = useDepositSupportRequestVerificationDialog();
  const {
    toggle: withdrawSupportRequestToggle,
    mode: withdrawSupportRequestMode,
    closeDialog: closeWithdrawSupportRequestDialog,
    closeByUndefinedTransactionDialog:
      closeWithdrawSupportRequestDialogByUndefinedTransaction,
    isShowByUndefinedTransaction:
      isWithdrawSupportRequestShowByUndefinedTransaction,
  } = useWithdrawSupportVerificationDialog();
  const {
    toggle: verifyRevenueCompanyMoneyHolderAccountVerificationToggle,
    closeDialog: closeVerifyRevenueCompanyMoneyHolderAccountVerificationDialog,
  } = useVerifyCompanyMoneyHolderAccountVerificationDialog({
    dashboardSide: 'revenue',
  });
  const {
    toggle: verifyExpenseCompanyMoneyHolderAccountVerificationToggle,
    closeDialog: closeVerifyExpenseCompanyMoneyHolderAccountVerificationDialog,
  } = useVerifyCompanyMoneyHolderAccountVerificationDialog({
    dashboardSide: 'expense',
  });
  const {
    toggle: createRevenueCompanyMoneyHolderAccountVerificationDialogToggle,
    closeDialog:
      closeCreateRevenueCompanyMoneyHolderAccountVerificationDialogDialog,
  } = useCreateCompanyMoneyHolderAccountVerificationDialog({
    dashboardSide: 'revenue',
  });
  const {
    toggle: createExpenseCompanyMoneyHolderAccountVerificationDialogToggle,
    closeDialog:
      closeCreateExpenseCompanyMoneyHolderAccountVerificationDialogDialog,
  } = useCreateCompanyMoneyHolderAccountVerificationDialog({
    dashboardSide: 'expense',
  });
  const {
    toggle: verifyBeforeEndDialogToggle,
    close: closeVerifyBeforeEndDialog,
  } = useVerifyBeforeEndDialog();
  const {
    close: closeTransactionCompensationPaymentDialog,
    toggle: transactionCompensationPaymentDialogToggle,
  } = useTransactionCompensationPaymentDialog();
  const {
    close: closeCreateTransactionCompensationLogDialog,
    toggle: createTransactionCompensationLogDialogToggle,
  } = useCreateTransactionCompensationLogDialog();
  const {
    close: closeVerifyRevenueTransactionCompensationLogDialog,
    toggle: verifyRevenueTransactionCompensationLogToggle,
  } = useVerifyTransactionCompensationLogDialog('revenue');
  const {
    close: closeVerifyExpenseTransactionCompensationLogDialog,
    toggle: verifyExpenseTransactionCompensationLogToggle,
  } = useVerifyTransactionCompensationLogDialog('expense');
  const {
    close: closeViewTransactionCompensationLogDialog,
    toggle: viewTransactionCompensationLogDialogToggle,
  } = useViewTransactionCompensationLogDialog();

  //Function handle for toggle hooks
  const handleOpenRejDelUndTranStaffDia = (data: Notification) => {
    const value: { [key: string]: any } = {};
    data.templateParameter.forEach((item) => {
      value[item.name] = item.value;
    });
    console.log(value);
    openRejDelUndTranStaffDia({ ...value, id: data.topic.value });
  };

  const handleOpenConDelUndTranStaffDia = (data: Notification) => {
    if (data) {
      dispatch(
        setSelectedUndefinedTransactionDeleteRequestId(data.topic.value),
      );
      openConDelUndTranStaffDia();
    }
  };

  const handleCloseConDelUndTranStaffDia = () => {
    dispatch(setSelectedUndefinedTransactionDeleteRequestId(undefined));
    closeConDelUndTranStaffDia();
  };

  const handleOpenRejVerUndTranReasonsDia = (data: Notification) => {
    const value: { [key: string]: any } = {};
    data.templateParameter.forEach((item) => {
      value[item.name] = item.value;
    });

    openRejVerUndTranReasonsDia({
      id: +data.topic.value,
      ...value,
    });
  };

  const handleOpenRejVerDepositTranReptReasonsDia = (data: Notification) => {
    openRejVerDepositTranReptReasonsDia(data.topic.value);
  };

  const handleOpenRejVerWithdrawTranReptReasonsDia = (data: Notification) => {
    openRejVerWithdrawTranReptReasonsDia(data.topic.value);
  };

  const handleOpenRejComWithdrawTranVerReasonsDia = (data: Notification) => {
    openRejComWithdrawTranVerReasonsDia(data.topic.value);
  };

  const handleOpenRejComDepositTranVerReasonsDia = (data: Notification) => {
    openRejComDepositTranVerReasonsDia(data.topic.value);
  };

  const values = {
    handleOpenRejDelUndTranStaffDia,
    handleOpenConDelUndTranStaffDia,
    handleOpenRejVerUndTranReasonsDia,
    handleOpenRejVerDepositTranReptReasonsDia,
    handleOpenRejVerWithdrawTranReptReasonsDia,
    handleOpenRejComWithdrawTranVerReasonsDia,
    handleOpenRejComDepositTranVerReasonsDia,
  };

  return (
    <DialogContext.Provider value={values}>
      {children}
      <Suspense fallback={<LoadingScreen isBlurryBackground />}>
        {transactionCompensationPaymentDialogToggle && (
          <TransactionCompensationPaymentDialog
            open={transactionCompensationPaymentDialogToggle}
            onClose={closeTransactionCompensationPaymentDialog}
          />
        )}
        {verifyDepositRequestToggle && (
          <DepositVerificationDialog
            onClose={closeVerifyDepositRequestDialog}
            open={verifyDepositRequestToggle}
          />
        )}
        {verifyWithdrawRequestToggle && (
          <WithdrawVerificationDialog
            onClose={closeVerifyWithdrawRequestDialog}
            open={verifyWithdrawRequestToggle}
          />
        )}
        {depositSupportRequestToggle &&
          depositSupportRequestMode === 'EDIT' && (
            <VerifyDepSupReqVerDialogContainer
              open={depositSupportRequestToggle}
              onClose={closeDepositSupportRequestDialog}
            />
          )}
        {depositSupportRequestToggle &&
          depositSupportRequestMode === 'VIEW' &&
          !isDepositSupportRequestShowByUndefinedTransaction && (
            <ViewDepSupReqVerDialogContainer
              open={depositSupportRequestToggle}
              onClose={closeDepositSupportRequestDialog}
            />
          )}
        {depositSupportRequestToggle &&
          depositSupportRequestMode === 'VIEW' &&
          isDepositSupportRequestShowByUndefinedTransaction && (
            <ViewDepSupReqVerByUndDialogContainer
              open={depositSupportRequestToggle}
              onClose={closeDepositSupportRequestDialog}
            />
          )}
        {withdrawSupportRequestToggle &&
          withdrawSupportRequestMode === 'EDIT' && (
            <VerifyWithSupReqVerContainer
              open={withdrawSupportRequestToggle}
              onClose={closeWithdrawSupportRequestDialog}
            />
          )}
        {withdrawSupportRequestToggle &&
          withdrawSupportRequestMode === 'VIEW' &&
          !isWithdrawSupportRequestShowByUndefinedTransaction && (
            <ViewWithSupReqVerContainer
              open={withdrawSupportRequestToggle}
              onClose={closeWithdrawSupportRequestDialog}
            />
          )}
        {withdrawSupportRequestToggle &&
          withdrawSupportRequestMode === 'VIEW' &&
          isWithdrawSupportRequestShowByUndefinedTransaction && (
            <ViewWithSupReqVerByUndContainer
              open={withdrawSupportRequestToggle}
              onClose={closeWithdrawSupportRequestDialogByUndefinedTransaction}
            />
          )}
        {openRevenueUndTranVerReqToggle &&
          revenueUndTranVerReqMode === 'CREATE' && (
            <CreateUndefinedTransactionVerifyRequestDialogContainer
              open={openRevenueUndTranVerReqToggle}
              onClose={closeRevenueUndTranVerReqDialog}
              dashboardSide="revenue"
            />
          )}
        {openExpenseUndTranVerReqToggle &&
          expenseUndTranVerReqMode === 'CREATE' && (
            <CreateUndefinedTransactionVerifyRequestDialogContainer
              open={openExpenseUndTranVerReqToggle}
              onClose={closeExpenseUndTranVerReqDialog}
              dashboardSide="expense"
            />
          )}
        {openRevenueUndTranVerReqToggle &&
          revenueUndTranVerReqMode === 'VIEW' && (
            <ViewUndefinedTransactionVerifyRequestDialogContainer
              open={openRevenueUndTranVerReqToggle}
              onClose={closeRevenueUndTranVerReqDialog}
              dashboardSide="revenue"
            />
          )}
        {openExpenseUndTranVerReqToggle &&
          expenseUndTranVerReqMode === 'VIEW' && (
            <ViewUndefinedTransactionVerifyRequestDialogContainer
              open={openExpenseUndTranVerReqToggle}
              onClose={closeExpenseUndTranVerReqDialog}
              dashboardSide="expense"
            />
          )}
        {delRevenueUndTransReqManDiaToggle && (
          <DeleteUndefinedTransactionRequestManagerDialog
            open={delRevenueUndTransReqManDiaToggle}
            onClose={closeRevenueDelUndTransReqManDia}
            dashboardSide="revenue"
          />
        )}
        {delExpenseUndTransReqManDiaToggle && (
          <DeleteUndefinedTransactionRequestManagerDialog
            open={delExpenseUndTransReqManDiaToggle}
            onClose={closeExpenseDelUndTransReqManDia}
            dashboardSide="expense"
          />
        )}
        {verRevenueUndTransReqManDiaToggle && (
          <VerifyUndefinedTransactionRequestManagerDialog
            open={verRevenueUndTransReqManDiaToggle}
            onClose={closeRevenueVerUndTransReqManDia}
            dashboardSide="revenue"
          />
        )}
        {verExpenseUndTransReqManDiaToggle && (
          <VerifyUndefinedTransactionRequestManagerDialog
            open={verExpenseUndTransReqManDiaToggle}
            onClose={closeExpenseVerUndTransReqManDia}
            dashboardSide="expense"
          />
        )}
        {rejDelUndTranStaffDiaToggle && (
          <RejectUndefinedTransactionStaffDialog
            open={rejDelUndTranStaffDiaToggle}
            onClose={closeRejDelUndTranStaffDia}
            data={rejDelUndTranStaffDiaValue}
          />
        )}
        {conDelUndTranStaffDiaToggle && (
          <DeleteUndefinedTransactionConfirmDialog
            open={conDelUndTranStaffDiaToggle}
            onClose={handleCloseConDelUndTranStaffDia}
          />
        )}
        {rejVerUndTranReasonsDiaToggle && (
          <RejectVerifyUndefinedTransactionRequestReasonDialog
            open={rejVerUndTranReasonsDiaToggle}
            onClose={closeRejVerUndTranReasonsDia}
            data={rejVerUndTranReasonsDiaValue}
          />
        )}
        {incomeReportDialogToggle && (
          <CreateIncomeReportDialog
            open={incomeReportDialogToggle}
            onClose={closeIncomeReportDialogClose}
          />
        )}
        {expenseReportDialogToggle && (
          <CreateExpenseReportDialog
            open={expenseReportDialogToggle}
            onClose={closeExpenseReportDialogClose}
          />
        )}
        {revenueCompanyCompanyWithdrawTransactionToggle && (
          <CreateCompanyWithdrawTransactionDialogContainer
            open={revenueCompanyCompanyWithdrawTransactionToggle}
            onClose={closeRevenueCompanyWithdrawTransaction}
            dashboardSide="revenue"
          />
        )}
        {expenseCompanyCompanyWithdrawTransactionToggle && (
          <CreateCompanyWithdrawTransactionDialogContainer
            open={expenseCompanyCompanyWithdrawTransactionToggle}
            onClose={closeExpenseCompanyWithdrawTransaction}
            dashboardSide="expense"
          />
        )}
        {internalCompanyCompanyWithdrawTransactionToggle && (
          <CreateCompanyWithdrawTransactionDialogContainer
            open={internalCompanyCompanyWithdrawTransactionToggle}
            onClose={closeInternalCompanyWithdrawTransaction}
            dashboardSide="internal"
          />
        )}
        {expenseCompanyDepositTransactionToggle && (
          <CreateCompanyDepositTransactionDialogContainer
            open={expenseCompanyDepositTransactionToggle}
            onClose={closeExpenseCompanyDepositTransaction}
            dashboardSide="expense"
          />
        )}
        {internalCompanyDepositTransactionToggle && (
          <CreateCompanyDepositTransactionDialogContainer
            open={internalCompanyDepositTransactionToggle}
            onClose={closeInternalCompanyDepositTransaction}
            dashboardSide="internal"
          />
        )}
        {companyOtherExpenseTransactionToggle &&
          companyOtherExpenseTransactionMode === 'VIEW' && (
            <ViewCompanyOtherExpenseTransactionDialogContainer
              open={companyOtherExpenseTransactionToggle}
              onClose={closeCompanyOtherExpenseTransaction}
            />
          )}
        {verifyDepositTransactionReportDialogToggle && (
          <VerifyDepositTransactionReportDialog
            open={verifyDepositTransactionReportDialogToggle}
            onClose={closeVerifyDepositTransactionReportDialog}
          />
        )}
        {verifyWithdrawTransactionReportDialogToggle && (
          <VerifyWithdrawTransactionReportDialog
            open={verifyWithdrawTransactionReportDialogToggle}
            onClose={closeVerifyWithdrawTransactionReportDialog}
          />
        )}
        {rejVerDepositTranReptReasonsDiaToggle && (
          <RevenueTranReptRejectedDialog
            open={rejVerDepositTranReptReasonsDiaToggle}
            onClose={closeRejVerDepositTranReptReasonsDia}
            id={rejVerDepositTranReptReasonsDiaValue}
          />
        )}
        {rejVerWithdrawTranReptReasonsDiaToggle && (
          <ExpenseTranReptRejectedDialog
            open={rejVerWithdrawTranReptReasonsDiaToggle}
            onClose={closeRejVerWithdrawTranReptReasonsDia}
            id={rejVerWithdrawTranReptReasonsDiaValue}
          />
        )}
        {verifyCompanyWithdrawTransactionDialogToggle && (
          <VerifyCompanyWithdrawTransactionDialog
            open={verifyCompanyWithdrawTransactionDialogToggle}
            onClose={closeVerifyCompanyWithdrawTransactionDialog}
          />
        )}
        {verifyCompanyOtherExpenseTransactionDialogToggle && (
          <VerifyCompanyOtherExpenseTransactionDialog
            open={verifyCompanyOtherExpenseTransactionDialogToggle}
            onClose={closeVerifyCompanyOtherExpenseTransactionDialog}
          />
        )}
        {expenseVerifyCompanyDepositTransactionDialogToggle && (
          <VerifyCompanyDepositTransactionDialog
            open={expenseVerifyCompanyDepositTransactionDialogToggle}
            onClose={closeExpenseVerifyCompanyDepositTransactionDialog}
            dashboardSide="expense"
          />
        )}
        {internalVerifyCompanyDepositTransactionDialogToggle && (
          <VerifyCompanyDepositTransactionDialog
            open={internalVerifyCompanyDepositTransactionDialogToggle}
            onClose={closeInternalVerifyCompanyDepositTransactionDialog}
            dashboardSide="internal"
          />
        )}
        {rejComDepositTranVerReasonsDiaToggle && (
          <RejectComDepositTranDialog
            open={rejComDepositTranVerReasonsDiaToggle}
            onClose={closeRejComDepositTranVerReasonsDia}
            id={rejComDepositTranVerReasonsDiaValue}
          />
        )}
        {rejComWithdrawTranVerReasonsDiaToggle && (
          <RejectComWithdrawTranDialog
            open={rejComWithdrawTranVerReasonsDiaToggle}
            onClose={closeRejComWithdrawTranVerReasonsDia}
            id={rejComWithdrawTranVerReasonsDiaValue}
          />
        )}
        {verifyRevenueCompanyMoneyHolderAccountVerificationToggle && (
          <VerifyCompanyMoneyHolderAccountVerificationDialogContainer
            dashboardSide="revenue"
            open={verifyRevenueCompanyMoneyHolderAccountVerificationToggle}
            onClose={
              closeVerifyRevenueCompanyMoneyHolderAccountVerificationDialog
            }
          />
        )}
        {verifyExpenseCompanyMoneyHolderAccountVerificationToggle && (
          <VerifyCompanyMoneyHolderAccountVerificationDialogContainer
            dashboardSide="expense"
            open={verifyExpenseCompanyMoneyHolderAccountVerificationToggle}
            onClose={
              closeVerifyExpenseCompanyMoneyHolderAccountVerificationDialog
            }
          />
        )}
        {createRevenueCompanyMoneyHolderAccountVerificationDialogToggle && (
          <CreateCompanyMoneyHolderAccountVerificationDialogContainer
            dashboardSide="revenue"
            open={
              createRevenueCompanyMoneyHolderAccountVerificationDialogToggle
            }
            onClose={
              closeCreateRevenueCompanyMoneyHolderAccountVerificationDialogDialog
            }
          />
        )}
        {createExpenseCompanyMoneyHolderAccountVerificationDialogToggle && (
          <CreateCompanyMoneyHolderAccountVerificationDialogContainer
            dashboardSide="expense"
            open={
              createExpenseCompanyMoneyHolderAccountVerificationDialogToggle
            }
            onClose={
              closeCreateExpenseCompanyMoneyHolderAccountVerificationDialogDialog
            }
          />
        )}
        {verifyBeforeEndDialogToggle && (
          <VerifyBeforeEndDialog
            open={verifyBeforeEndDialogToggle}
            onClose={closeVerifyBeforeEndDialog}
          />
        )}
        {createTransactionCompensationLogDialogToggle && (
          <CreateTransactionCompensationLogDialog
            open={createTransactionCompensationLogDialogToggle}
            onClose={closeCreateTransactionCompensationLogDialog}
          />
        )}
        {verifyRevenueTransactionCompensationLogToggle && (
          <VerifyTransactionCompensationLogDialog
            open={verifyRevenueTransactionCompensationLogToggle}
            onClose={closeVerifyRevenueTransactionCompensationLogDialog}
            dashboardSide="revenue"
          />
        )}
        {verifyExpenseTransactionCompensationLogToggle && (
          <VerifyTransactionCompensationLogDialog
            open={verifyExpenseTransactionCompensationLogToggle}
            onClose={closeVerifyExpenseTransactionCompensationLogDialog}
            dashboardSide="expense"
          />
        )}
        {viewTransactionCompensationLogDialogToggle && (
          <ViewTransactionCompensationLogDialog
            open={viewTransactionCompensationLogDialogToggle}
            onClose={closeViewTransactionCompensationLogDialog}
          />
        )}
      </Suspense>
    </DialogContext.Provider>
  );
}

export { DialogContext, DialogProvider };
