import { CommonDashboardSideType } from 'src/@types/dashboard';
import {
  resetManagerVerifyDialog,
  setManagerVerifyDialog,
  setSelectedUndefinedTransactionVerifyRequestId,
} from 'src/redux/slices/undefined-transaction-verify-request.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyUndefinedTransactionRequestManagerDialog(
  dashboardSide: CommonDashboardSideType,
) {
  //state
  const toggle = useSelector(
    (state) =>
      state.undefinedTransactionVerifyRequest.manager[dashboardSide].verify
        .dialog.toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialog = (id?: number) => {
    dispatch(setSelectedUndefinedTransactionVerifyRequestId(id));
    dispatch(
      setManagerVerifyDialog({
        dialog: {
          toggle: true,
        },
        dashboardSide: dashboardSide,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(
      resetManagerVerifyDialog({
        dashboardSide: dashboardSide,
      }),
    );
  };

  return { toggle, openDialog, closeDialog };
}
