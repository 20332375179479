import { DeepPartial } from 'react-hook-form';
import { BankAccount } from './bank-account';
import { EwalletAccount } from './e-wallet-account';
import { FileData } from './file';
import { TransactionVerification } from './transaction-verification';
import { User, UserType } from './user';
import { MoneyHolderAccountTypeType } from './money-holder-account';
import { Month } from 'date-fns';

//----------------------------------------------------------------
//Global State
export type CreateMode = 'PENDING' | 'CREATE' | 'HISTORY' | 'NOTI';
export type OtherExpenseMode = 'CREATE' | 'VIEW' | 'PENDING';
export type VerifyDepositDialogMode = 'VERIFY' | 'VIEW';
export type VerifyWithdrawDialogMode = 'VERIFY' | 'VIEW';
export type CreateDialog = {
  mode: CreateMode;
  toggle: boolean;
};
export type VerComTranDiag = {
  toggle: boolean;
};
export type VerifyCompanyTransactionFilter = {
  type?: TransactionTypeType;
  moneyHolderAccountType?: MoneyHolderAccountTypeType;
  searchCharacter?: string;
  bankAccountId?: number;
  ewalletAccountId?: number;
  statuses?: TransactionStatusType[];
};

export type VerifyCompanyOtherExpenseTransactionFilter = {
  type: TransactionTypeType;
  searchCharacter?: string;
  bankAccountId?: number;
  ewalletAccountId?: number;
  statuses?: TransactionStatusType[];
};

export type Filter = {
  searchCharacter: string;
  userType: UserType;
};
export type CreateCompanyDeposit = {
  dialog: CreateDialog;
};
export type CreateCompanyWithdraw = {
  dialog: CreateDialog;
};
export type CreateCompanyOtherExpense = {
  dialog: {
    mode: OtherExpenseMode;
    toggle: boolean;
  };
};
export type DepositVerificationDialog = {
  toggle: boolean;
  mode: VerifyDepositDialogMode;
};
export type DepositVerification = {
  dialog: DepositVerificationDialog;
  filter: Filter;
  form: {
    transactionVerificationImage?: File | FileData;
  };
};
export type WithdrawVerificationDialog = {
  toggle: boolean;
  mode: VerifyWithdrawDialogMode;
};
export type CheckBankAccountDialog = {
  isFulfilled: boolean;
  toggle: boolean;
};
export type WithdrawVerification = {
  checkBankAccountDialog: CheckBankAccountDialog;
  dialog: WithdrawVerificationDialog;
  filter: Filter;
};
export type CompanyDepositVerification = {
  dialog: VerComTranDiag;
  filter: VerifyCompanyTransactionFilter;
};
export type CompanyWithdrawVerification = {
  dialog: VerComTranDiag;
  filter: VerifyCompanyTransactionFilter;
};
export type CompanyOtherExpenseVerification = {
  dialog: VerComTranDiag;
  filter: VerifyCompanyOtherExpenseTransactionFilter;
};

export type TransactionState = {
  selectedTransactionId?: number;
  default: {
    revenue: {
      depositVerification: DepositVerification;
      createCompanyWithdraw: CreateCompanyWithdraw;
    };
    expense: {
      withdrawVerification: WithdrawVerification;
      createCompanyDeposit: CreateCompanyDeposit;
      createCompanyWithdraw: CreateCompanyWithdraw;
    };
    internal: {
      createCompanyDeposit: CreateCompanyDeposit;
      createCompanyWithdraw: CreateCompanyWithdraw;
      createCompanyOtherExpense: CreateCompanyOtherExpense;
    };
  };
  manager: {
    revenue: {
      companyWithdrawVerification: CompanyWithdrawVerification;
    };
    expense: {
      companyDepositVerification: CompanyDepositVerification;
      companyWithdrawVerification: CompanyWithdrawVerification;
    };
    internal: {
      companyDepositVerification: CompanyDepositVerification;
      companyWithdrawVerification: CompanyWithdrawVerification;
      companyOtherExpenseVerification: CompanyOtherExpenseVerification;
    };
  };
};

//----------------------------------------------------------------
export enum WithdrawCompanyTransactionFailReasonType {
  BANK_WRONG = 'Chọn nhầm ngân hàng thu',
  DATE_WRONG = 'Ngày và giờ lấy tiền về chưa chính xác',
  MONEY_WRONG = 'Số tiền lấy về không chính xác',
  IMAGE_WRONG = 'Ảnh sao kê không chính xác',
  CONTENT_WRONG = 'Nội dung bạn nhập không chính xác',
}

export enum OtherExpenseCompanyTransactionFailReasonType {
  BANK_WRONG = 'Chọn nhầm ngân hàng thu',
  DATE_WRONG = 'Ngày và giờ lấy tiền về chưa chính xác',
  MONEY_WRONG = 'Số tiền lấy về không chính xác',
  IMAGE_WRONG = 'Ảnh sao kê không chính xác',
  CONTENT_WRONG = 'Nội dung bạn nhập không chính xác',
}

export enum TransactionStatusType {
  PENDING = 'Chờ xử lý',
  COMPLETED = 'Hoàn thành',
  CANCELLED = 'Hủy bỏ',
  ERROR = 'Lỗi',
  PAYMENT_PENDING = 'Chờ thanh toán',
  CONFIRMATION_PENDING = 'Chờ xác nhận',
  FAILED = 'Thất bại',
  VERIFY_PENDING = 'Chờ kiểm tra',
}

export enum TransactionTypeType {
  DEPOSIT = 'Nạp tiền',
  DEPOSIT_SUPPORT = 'Hỗ trợ nạp tiền',
  SALARY_PAYMENT = 'Thanh toán lương',
  REFUND = 'Hoàn trả',
  FEE_REFUND = 'Hoàn trả lệ phí',
  TUITION_REFUND = 'Hoàn trả học phí',
  WITHDRAW = 'Rút tiền',
  WITHDRAW_SUPPORT = 'Hỗ trợ rút tiền',
  PAYMENT = 'Thanh toán',
  TUITION_PAYMENT = 'Thanh toán học phí',
  FEE_PREPAYMENT = 'Tạm ứng lệ phí',

  //Support report
  RETRIEVE_OVER_DEPOSIT_AMOUNT = 'Thu hồi - Nạp thừa tiền',
  ADDITIONAL_UNDER_DEPOSIT_AMOUNT = 'Nạp thêm - Nạp thiếu tiền',
  REFUND_UNDER_WITHDRAW_AMOUNT = 'Hoàn trả - Chuyển thiếu tiền',
  CUSTOMER_TRANSFER_BACK_OVER_WITHDRAW_AMOUNT = 'KH chuyển lại - Chuyển thừa tiền',

  //Company
  COMPANY_EXPENSE_ACCOUNT_WITHDRAW = 'Rút tiền tài khoản chi công ty',
  COMPANY_EXPENSE_ACCOUNT_DEPOSIT = 'Nạp tiền tài khoản chi công ty',
  COMPANY_REVENUE_ACCOUNT_WITHDRAW = 'Rút tiền tài khoản thu công ty',
  COMPANY_REVENUE_ACCOUNT_DEPOSIT = 'Nạp tiền tài khoản thu công ty',
  COMPANY_INTERNAL_ACCOUNT_WITHDRAW = 'Rút tiền tài khoản nội bộ công ty',
  COMPANY_INTERNAL_ACCOUNT_DEPOSIT = 'Nạp tiền tài khoản nội bộ công ty',
  COMPANY_INTERNAL_ACCOUNT_OTHER_EXPENSE = 'Chi khác tài khoản nội bộ công ty',
}

export enum TransactionMethodType {
  BANK = 'bank',
  E_WALLET = 'e_wallet',
  IN_APP = 'in_app',
  OTHER = 'other',
}

export enum MoneyHolderType {
  BANK = 'Ngân hàng',
  E_WALLET = 'Ví điện tử',
}

export enum TransactionFailReasonType {
  BANK_ACCOUNT_NAME_NOT_SAME_NAME = 'Tên chủ tài khoản không trùng với tên đăng ký tại web',
  BANK_ACCOUNT_INCORRECT_OR_NOT_EXIST = 'Thông tin tài khoản ngân hàng không tồn tại',
}

//----------------------------------------------------------------

export interface Transaction {
  id: number;
  status: TransactionStatusType;
  sentBankAccountId?: number;
  sentBankAccount?: BankAccount;
  sentEwalletAccountId?: number;
  sentEwalletAccount?: EwalletAccount;
  receivedBankAccountId?: number;
  receivedBankAccount?: BankAccount;
  receivedEwalletAccountId?: number;
  receivedEwalletAccount?: EwalletAccount;
  content: string;
  receivedAt?: Date | string | number;
  imageId?: number;
  image?: FileData;
  ownerId: number;
  owner?: User;
  supportByAccountantId?: number;
  supportByAccountant?: User;
  amount?: number;
  type: TransactionTypeType;
  code: string;
  version: number;
  expiredAt: Date;
  method: TransactionMethodType;
  description?: string;
  isTransactionSupportRequestSuccess: boolean;
  transactionVerification?: TransactionVerification;
  createdAt: Date | string | number;
  updatedAt: Date | string | number;
  transactionDate?: Date;
  state?: {
    childIndex: number;
    typeIndex: number;
  };
}

//Form
export type CreateCompanyWithdrawTransactionForm = DeepPartial<
  CreateCompanyAccountantWithdrawRequest & {
    isConfirmed?: boolean;
  }
>;

export type CreateCompanyDepositTransactionForm = DeepPartial<
  CreateCompanyAccountantDepositRequest & {
    isConfirmed?: boolean;
  }
>;

export type UpdateDepositTransaction = {
  id: number;
  version: number;
  transactionVerificationImage: Partial<FileData> | null;
  amount: number;
};

export type VerifyDepositTransactionForm = {
  ownerId: number;
  userType: UserType;
  transaction: UpdateDepositTransaction;
  isConfirmed: boolean;
};

export type UpdateWithdrawTransaction = {
  id: number;
  version: number;
  transactionVerificationImage: Partial<FileData> | null;
};

export type RejectWithdrawTransactionForm = {
  ownerId: number;
  transaction: UpdateWithdrawTransaction;
  reason: string;
  type: TransactionTypeType;
};

export type VerifyWithdrawTransactionForm = {
  ownerId: number;
  userType: UserType;
  transaction: UpdateWithdrawTransactionService;
  sentBankAccountId: number;
};

export type CreateCompanyAccountantWithdrawRequestFormValueProps = {
  image: File | FileData;
  isConfirmed: boolean;
} & Omit<CreateCompanyAccountantWithdrawRequest, 'image'>;

export type CreateCompanyAccountantDepositRequestFormValueProps = {
  image: File | FileData;
  isConfirmed: boolean;
} & Omit<CreateCompanyAccountantDepositRequest, 'image'>;

export type CreateCompanyAccountantOtherExpenseRequestFormValueProps = {
  image: File | FileData;
  isConfirmed: boolean;
} & Omit<CreateCompanyAccountantOtherExpenseRequest, 'image'>;

export type RejectCompanyTransactionForm =
  DeepPartial<RejectCompanyTransactionRequest>;

//----------------------------------------------------------------
//Service
export type OverviewTaskHistoryRequest = {
  month?: number;
  year?: number;
  type: TransactionTypeType;
};

export type OverviewTaskHistoryResponse = {
  month: number;
  year: number;
  teacherCount: number;
  studentCount: number;
};

export type SearchTaskHistoryRequest = {
  searchCharacter?: string;
  month?: number;
  year?: number;
  userType?: UserType;
  type: TransactionTypeType;
  statuses?: TransactionStatusType[];
};

export type SearchTaskHistoryResponse = {
  id: number;
  updatedAt: Date;
  status: TransactionStatusType;
  description: string;
  ownerStudentCode?: string;
  ownerTeacherCode?: string;
  ownerConsultantCode?: string;
  ownerAccountantCode?: string;
  ownerName: string;
  state: {
    childIndex?: number;
    typeIndex?: number;
  };
  KPI?: number;
};

export type SearchInfinityDepositTransactionService = {
  moneyHolderType?: MoneyHolderType;
  searchCharacter?: string;
  limit?: number;
  page: number;
  userType: UserType;
};

export type SearchSupTranByAcctReqAccountantRequest = {
  searchCharacter?: string;
  bankAccountId?: number;
  ewalletAccountId?: number;
  year?: number;
  month?: number;
  limit?: number;
  page?: number;
  moneyHolderAccountType?: MoneyHolderAccountTypeType;
  userTypes?: string;
  types?: string;
};

export type SearchInfinityWithdrawTransactionService = {
  moneyHolderType?: MoneyHolderType;
  searchCharacter?: string;
  limit?: number;
  page: number;
  userType: UserType;
};

export type SearchInfinityCompanyTransactionByManagerRequest = {
  type?: TransactionTypeType;
  moneyHolderAccountType?: MoneyHolderAccountTypeType;
  searchCharacter?: string;
  bankAccountId?: number | null;
  ewalletAccountId?: number | null;
  statuses?: TransactionStatusType[];
  limit?: number;
  page?: number;
};

export type CountCompanyTransactionByManagerRequest = {
  type?: TransactionTypeType;
};

export type FindDepositTransactionService = {
  id: number;
};

export type CheckDepositTransactionService = {
  id: number;
};

export type FindWithdrawTransactionService = {
  id: number;
};

export type CheckWithdrawTransactionService = {
  id: number;
};

export type CheckSalaryRequestTransactionService = {
  id: number;
};

export type UpdateDepositTransactionService = {
  id: number;
  version: number;
  transactionVerificationImage: Partial<FileData>;
  amount: number;
};

export type UpdateWithdrawTransactionService = {
  id: number;
  version: number;
  transactionVerificationImage: Partial<FileData> | null;
};

export type VerifyDepositTransactionService = {
  ownerId: number;
  userType: UserType;
  transaction: UpdateDepositTransactionService;
};

export type VerifyWithdrawTransactionService = {
  ownerId: number;
  userType: UserType;
  transaction: UpdateWithdrawTransactionService;
  sentBankAccountId: number;
};

export type ConfirmCompanyWithdrawTransactionRequest = {
  id: number;
  version: number;
};

export type ConfirmCompanyOtherExpenseTransactionRequest = {
  id: number;
  version: number;
};

export type ConfirmCompanyDepositTransactionRequest = {
  id: number;
  version: number;
};

export type RejectCompanyTransactionRequest = {
  id: number;
  version: number;
  reasons: string[];
  type: TransactionTypeType;
};

export type RejectWithdrawTransactionService = {
  ownerId: number;
  transaction: UpdateWithdrawTransactionService;
  reason: string;
};

export type CreateCompanyAccountantDepositRequest = {
  amount: number;
  receivedEwalletAccountId?: number;
  receivedBankAccountId?: number;
  content: string;
  method: TransactionMethodType;
  image: Partial<FileData>;
  receivedAt: Date | number | string;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type CreateCompanyAccountantWithdrawRequest = {
  amount: number;
  sentEwalletAccountId?: number;
  sentBankAccountId?: number;
  content: string;
  method: TransactionMethodType;
  image: FileData;
  receivedAt: Date | number | string;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type CreateCompanyAccountantOtherExpenseRequest = {
  amount: number;
  sentEwalletAccountId?: number;
  sentBankAccountId?: number;
  content: string;
  image: FileData;
  receivedAt: Date | number | string;
};

export type FindPendingCompanyAccountOtherExpenseTransactionsRequest = {
  sentEwalletAccountId?: number;
  sentBankAccountId?: number;
  limit?: number;
  page?: number;
};

export type FindHistoryCompanyAccountWithdrawTransactionRequest = {
  sentEwalletAccountId?: number;
  sentBankAccountId?: number;
  method: TransactionMethodType;
  limit?: number;
  page?: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type FindHistoryCompanyAccountDepositTransactionRequest = {
  receivedEwalletAccountId?: number;
  receivedBankAccountId?: number;
  method: TransactionMethodType;
  limit?: number;
  page?: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type FindVerifyPendingCompanyAccountWithdrawTransactionRequest = {
  sentEwalletAccountId?: number;
  sentBankAccountId?: number;
  method: TransactionMethodType;
};

export type FindVerifyPendingCompanyAccountDepositTransactionRequest = {
  receivedEwalletAccountId?: number;
  receivedBankAccountId?: number;
  method: TransactionMethodType;
};

export type FindOneRequest = {
  id?: number;
  transactionReportId?: number;
  statuses?: TransactionStatusType[];
  type?: TransactionTypeType;
  ownerId?: number;
  isCurrentUser?: boolean;
  isGetImage?: boolean;
  isGetSentMoneyHolder?: boolean;
  isGetReceivedMoneyHolder?: boolean;
  isGetClientOwner?: boolean;
  isGetSupportByAccountant?: boolean;
  isGetTransactionVerification?: boolean;
};

export type FindChildrenTransactionRequest = {
  parentTransactionId: number;
  type: TransactionTypeType;
};

export type OverviewRequest = {
  type: TransactionTypeType;
};

export type DashboardFilterCountRequest = {
  userTypes?: string;
  bankAccountId?: number;
  ewalletAccountId?: number;
  month: number;
  year: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type DashboardFilterCountResponse = {
  teacherCount: number;
  studentCount: number;
  depositCount: number;
  withdrawCount: number;
  supportCount: number;
  reportCount: number;
};

export type GetStatisticsChartRequest = {
  bankAccountId?: number;
  ewalletAccountId?: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
  year: number;
};

export type GetStatisticsOverviewRequest = {
  bankAccountId?: number;
  ewalletAccountId?: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
  year: number;
  month: number;
};

export type StatisticChartItem = {
  month: Month;
  year: number;
  depositedAmount: number;
  supportedAmount: number;
  withdrawAmount: number;
};

export type GetStatisticsChartResponse = StatisticChartItem[];

export type GetStatisticTransactionOverviewItem = {
  month: number;
  year: number;
  revenueAmount: number;
  depositAmount: number;
  companyWithdrawAmount: number;
  companyDepositAmount: number;
};

export type GetStatisticTransactionOverviewResponse =
  GetStatisticTransactionOverviewItem[];

export type FindOneToReportRequest = {
  code: string;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type OverviewResponse = {
  teacherTransactionCount: number;
  studentTransactionCount: number;
};

export type FindOneOptions = {
  isGetReceivedMoneyHolder?: boolean;
  isGetSentMoneyHolder?: boolean;
  isGetImage?: boolean;
  isGetTransactionVerification?: boolean;
  isGetClientOwner?: boolean;
  isGetSupportByAccountant?: boolean;
};

//Hook
export type DepositTransactionSearchInfinityFilter = {
  searchCharacter: string;
  userType: UserType;
};
