// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';

// css swiper
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import { DialogProvider } from './contexts/DialogContext';
import { SidebarProvider } from './contexts/sidebarContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <DialogProvider>
            <SidebarProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
            </SidebarProvider>
          </DialogProvider>
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
