import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { getSidebarStaticAccountant } from 'src/services/accountant/sidebar.accountant.service';

export function useGetCountStaticAccountant(
  options?: Partial<UndefinedInitialDataOptions<any, any, any, any>>,
) {
  return {
    ...useQuery({
      queryKey: [QUERY_KEYS.SIDEBAR, QUERY_KEYS.SIDEBAR_GET_STATIC_ACCOUNTANT],
      queryFn: () => getSidebarStaticAccountant(),
      ...options,
    }),
  };
}
