import { useDispatch } from 'react-redux';
import { CommonDashboardSideType } from 'src/@types/dashboard';
import {
  resetManagerVerifyDialog,
  setManagerVerifyDialog,
  setSelectedTransactionCompensationLogId,
} from 'src/redux/slices/transaction-compensation-log.slice';
import { useSelector } from 'src/redux/store';

export default function useVerifyDialog(
  dashboardSide: CommonDashboardSideType,
) {
  const toggle = useSelector(
    (state) =>
      state.transactionCompensationLog.manager[dashboardSide].verify.dialog
        .toggle,
  );

  //hook
  const dispatch = useDispatch();

  const open = (id?: number) => {
    if (id) {
      dispatch(setSelectedTransactionCompensationLogId(id));
    }
    dispatch(
      setManagerVerifyDialog({ dialog: { toggle: true }, dashboardSide }),
    );
  };

  const close = () => {
    dispatch(resetManagerVerifyDialog({ dashboardSide }));
  };

  return { open, close, toggle };
}
