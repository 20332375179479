import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { getSidebarStaticAccountantManage } from 'src/services/accountant/sidebar.accountant.service';

export function useGetCountStaticAccountantManage(
  options?: Partial<UndefinedInitialDataOptions<any, any, any, any>>,
) {
  return {
    ...useQuery({
      queryKey: [
        QUERY_KEYS.SIDEBAR,
        QUERY_KEYS.SIDEBAR_GET_STATIC_ACCOUNTANT_MANAGE,
      ],
      queryFn: () => getSidebarStaticAccountantManage(),
      ...options,
    }),
  };
}
