import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { getSidebarStaticConsultantManage } from 'src/services/consultant/sidebar.consultant.service';

export function useGetCountStaticConsultantManage(
  options?: Partial<UndefinedInitialDataOptions<any, any, any, any>>,
) {
  return {
    ...useQuery({
      queryKey: [
        QUERY_KEYS.SIDEBAR,
        QUERY_KEYS.SIDEBAR_GET_STATIC_CONSULTANT_MANAGE,
      ],
      queryFn: () => getSidebarStaticConsultantManage(),
      ...options,
    }),
  };
}
