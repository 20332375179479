import { ElementType, Suspense, lazy } from 'react';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import AuthLayout from 'src/layouts/auth';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AccountantLayout from '../layouts/accountant';
import AdminLayout from '../layouts/admin';
import ConsultantLayout from '../layouts/consultant';
// guards
import AuthAdminGuard from '../guards/AuthAdminGuard';
import GuestGuard from '../guards/GuestGuard';
// config
import {
  PATH_AFTER_ACCOUNTANT_LOGIN,
  PATH_AFTER_ACCOUNTANT_MANAGER_LOGIN,
  PATH_AFTER_ADMIN_LOGIN,
  PATH_AFTER_CONSULTANT_LOGIN,
  PATH_AFTER_CONSULTANT_MANAGER_LOGIN,
} from '../config';
// components
import { ClassTypeType } from 'src/@types/class';
import { RecruitmentStaffType } from 'src/@types/recruitment';
import { TransactionTypeType } from 'src/@types/transaction';
import {
  AccountantRoleType,
  ConsultantRoleType,
  UserType,
} from 'src/@types/user';
import { FrontendPrefixType, PrefixType } from 'src/constants/prefix';
import AuthAccountantGuard from 'src/guards/AuthAccountantGuard';
import AuthConsultantGuard from 'src/guards/AuthConsultantGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import WorkAttendanceGuard from 'src/guards/WorkAttendanceGuard';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_ACCOUNTANT, PATH_ADMIN, PATH_CONSULTANT } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { userRole, userType } = useAuth();
  console.log('🚀 ~ file: index.tsx:59 ~ Router ~ userType:', userType);
  console.log('🚀 ~ file: index.tsx:59 ~ Router ~ userRole:', userRole);

  return useRoutes([
    //Admin Routes
    {
      path: FrontendPrefixType.ADMIN,
      children: [
        {
          element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />,
          index: true,
        },

        {
          path: 'auth',
          element: <AuthLayout />,
          children: [
            {
              element: (
                <Navigate
                  to={`/${FrontendPrefixType.ADMIN}/auth/login`}
                  replace
                />
              ),
              index: true,
            },
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <LoginAdmin />
                </GuestGuard>
              ),
            },
          ],
        },

        //Admin Dashboard
        {
          path: '',
          element: (
            <AuthAdminGuard>
              <AdminLayout />
            </AuthAdminGuard>
          ),
          children: [
            {
              path: 'nganh',
              element: <IndustryManageAdmin />,
              index: true,
            },
            {
              path: 'vi-tri-dia-ly',
              element: <LocationManageAdmin />,
            },
            {
              path: 'dieu-khoan-va-chinh-sach',
              children: [
                {
                  element: (
                    <Navigate
                      to={`/${FrontendPrefixType.ADMIN}/dieu-khoan-va-chinh-sach/danh-sach`}
                      replace
                    />
                  ),
                  index: true,
                },
                { path: 'danh-sach', element: <TermPolicyListAdmin /> },
                { path: 'tao-moi', element: <TermPolicyAddAdmin /> },
                { path: ':type/chinh-sua', element: <TermPolicyEditAdmin /> },
              ],
            },
            {
              path: 'tai-khoan-nguoi-hoc',
              children: [
                {
                  element: (
                    <Navigate
                      to={`/${FrontendPrefixType.ADMIN}/tai-khoan-nguoi-hoc/danh-sach`}
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  path: 'danh-sach',
                  element: <UserListAdmin type={UserType.STUDENT} />,
                },
                {
                  path: ':id',
                  element: <UserDetailAdmin type={UserType.STUDENT} />,
                },
              ],
            },
            {
              path: 'tai-khoan-nguoi-day',
              children: [
                {
                  element: (
                    <Navigate
                      to={`/${FrontendPrefixType.ADMIN}/tai-khoan-nguoi-day/danh-sach`}
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  path: 'danh-sach',
                  element: <UserListAdmin type={UserType.TEACHER} />,
                },
                {
                  path: ':id',
                  element: <UserDetailAdmin type={UserType.TEACHER} />,
                },
              ],
            },
            {
              path: 'tai-khoan-admin',
              children: [
                {
                  element: (
                    <Navigate
                      to={`/${FrontendPrefixType.ADMIN}/tai-khoan-admin/danh-sach`}
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  path: 'danh-sach',
                  element: <UserListAdmin type={UserType.ADMIN} />,
                },
                {
                  path: ':id',
                  element: <UserDetailAdmin type={UserType.ADMIN} />,
                },
              ],
            },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },

        //Support admin
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: 'coming-soon', element: <ComingSoon /> },
            { path: '500', element: <Page500 link={PATH_ADMIN.root} /> },
            { path: '404', element: <Page404 link={PATH_ADMIN.root} /> },
            { path: '403', element: <Page403 link={PATH_ADMIN.root} /> },
            {
              path: '*',
              element: <Navigate to={`${PATH_ADMIN.root}/404`} replace />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to={`${PATH_ADMIN.root}/404`} replace />,
        },
      ],
    },

    //Consultant Routes
    {
      path: FrontendPrefixType.CONSULTANT,
      children: [
        {
          element: (
            <>
              {userRole === ConsultantRoleType.MANAGER ? (
                <Navigate to={PATH_AFTER_CONSULTANT_LOGIN} replace />
              ) : (
                <Navigate to={PATH_AFTER_CONSULTANT_MANAGER_LOGIN} replace />
              )}
            </>
          ),
          index: true,
        },

        {
          path: 'auth',
          element: <AuthLayout />,
          children: [
            {
              element: (
                <Navigate
                  to={`/${FrontendPrefixType.CONSULTANT}/auth/login`}
                  replace
                />
              ),
              index: true,
            },
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <LoginConsultant />
                </GuestGuard>
              ),
            },
            { path: 'new-password', element: <NewPasswordConsultant /> },
          ],
        },

        //Consultant Dashboard
        {
          path: '',
          element: (
            <AuthConsultantGuard>
              <ConsultantLayout />
            </AuthConsultantGuard>
          ),
          children: [
            //Redirect
            {
              element: (
                <>
                  {userType === UserType.CONSULTANT &&
                    userRole === ConsultantRoleType.MANAGER && (
                      <Navigate
                        to={PATH_AFTER_CONSULTANT_MANAGER_LOGIN}
                        replace
                      />
                    )}
                  {userType === UserType.CONSULTANT &&
                    userRole === ConsultantRoleType.STAFF && (
                      <Navigate to={PATH_AFTER_CONSULTANT_LOGIN} replace />
                    )}
                </>
              ),
              index: true,
            },
            //For staff
            {
              path: '',
              element: (
                <RoleBasedGuard hasContent roles={[ConsultantRoleType.STAFF]}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: '',
                  element: (
                    <WorkAttendanceGuard hasContent>
                      <Outlet />
                    </WorkAttendanceGuard>
                  ),
                  children: [
                    { path: 'kiem-duyet-cv', element: <CVList /> },
                    { path: 'xet-duyet-lop', element: <ClassList /> },
                    {
                      path: 'nhac-nop-hoc-phi',
                      element: <TuitionReminderStudentList />,
                    },
                    {
                      path: 'nhac-nop-le-phi',
                      element: <TuitionReminderTeacherList />,
                    },
                    {
                      path: 'nhac-dong-hoc-phi',
                      element: <TuitionReminderStudentList />,
                    },
                    {
                      path: 'nhac-dong-le-phi',
                      element: <TuitionReminderTeacherList />,
                    },
                    { path: 'ghep-lop-hoc', element: <CombineClassesList /> },
                    {
                      path: 'ghep-lop-day',
                      element: <CombineTeachingClassesList />,
                    },
                    {
                      path: 'lich-su-cong-viec',
                      element: <ConsultantTaskHistory />,
                    },
                    {
                      path: 'tiep-thi-lai',
                      element: <ReMarketingList />,
                    },
                    {
                      path: 'nhac-nho-hoc',
                      element: <RemindToLearnList />,
                    },
                    {
                      path: 'de-xuat-lop',
                      children: [
                        {
                          element: <ClassProposalList />,
                          index: true,
                        },
                        {
                          path: 'lop-tai-nha/:id',
                          element: (
                            <ClassProposalChildrenList
                              type={ClassTypeType.OFFLINE}
                            />
                          ),
                        },
                        {
                          path: 'lop-truc-tuyen/:id',
                          element: (
                            <ClassProposalChildrenList
                              type={ClassTypeType.ONLINE}
                            />
                          ),
                        },
                      ],
                    },
                    {
                      path: 'nhac-nho-day',
                      element: <RemindToTeachList />,
                    },
                  ],
                },
                {
                  path: 'thong-ke-luong',
                  element: <ConsultantSalaryStatistic />,
                },
                {
                  path: 'muc-dao-tao',
                  element: (
                    <ManageTrainingSection prefixType={PrefixType.CONSULTANT} />
                  ),
                },
                {
                  path: 'lich-lam-viec',
                  element: <ConsultantWorkSchedule />,
                },
                {
                  path: 'lich-su-nhan-luong',
                  element: (
                    <ManageSalaryHistory prefixType={PrefixType.CONSULTANT} />
                  ),
                },
                {
                  path: 'quan-ly-tai-khoan',
                  element: (
                    <StaffAccountSetting userType={UserType.CONSULTANT} />
                  ),
                },
                {
                  path: 'ho-so-nhan-viec',
                  element: (
                    <DetailStaffApplication userType={UserType.CONSULTANT} />
                  ),
                },
              ],
            },
            //For manager
            {
              path: '',
              element: (
                <RoleBasedGuard hasContent roles={[ConsultantRoleType.MANAGER]}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                //Common
                {
                  path: 'quan-ly-nhan-vien',
                  children: [
                    {
                      path: 'dao-tao-nhan-vien',
                      element: (
                        <ManageTrainingStaff prefix={PrefixType.CONSULTANT} />
                      ),
                    },
                    {
                      path: 'dao-tao-nhan-vien/:id',
                      element: (
                        <DetailTrainingStaff prefix={PrefixType.CONSULTANT} />
                      ),
                    },
                    {
                      path: 'cai-dat-lich-lam',
                      element: (
                        <ManageStaffSchedule prefix={PrefixType.CONSULTANT} />
                      ),
                    },
                    {
                      path: 'dao-tao-nhan-vien',
                      element: (
                        <ManageTrainingStaff prefix={PrefixType.CONSULTANT} />
                      ),
                    },
                    {
                      path: 'dao-tao-nhan-vien/:id',
                      element: (
                        <DetailTrainingStaff prefix={PrefixType.CONSULTANT} />
                      ),
                    },

                    {
                      path: 'cai-dat-nhan-vien',
                      element: <ConsultantStaffsSetting />,
                    },
                    {
                      path: 'cai-dat-nhan-vien/:id',
                      element: <StaffSetting />,
                    },
                  ],
                },
                {
                  path: 'quan-ly-tuyen-dung',
                  children: [
                    {
                      path: 'tin-tuyen-dung',
                      element: (
                        <ManageRecruitment
                          recruitmentStaffType={RecruitmentStaffType.CONSULTANT}
                        />
                      ),
                      index: true,
                    },
                    {
                      path: 'tin-tuyen-dung/:recruitmentCode',
                      element: (
                        <ManageRecruitmentApplication
                          recruitmentStaffType={RecruitmentStaffType.CONSULTANT}
                        />
                      ),
                    },
                    {
                      path: 'hop-dong-lam-viec',
                      element: (
                        <ManageContract
                          recruitmentStaffType={RecruitmentStaffType.CONSULTANT}
                        />
                      ),
                    },
                    {
                      path: 'hop-dong-lam-viec/:contractCode',
                      element: (
                        <ManageConsultantContractHandover
                          recruitmentStaffType={RecruitmentStaffType.CONSULTANT}
                        />
                      ),
                    },
                    {
                      path: 'tam-ung',
                      element: (
                        <ManageEmployeeAdvanceFee
                          prefixType={PrefixType.CONSULTANT}
                        />
                      ),
                    },
                    {
                      path: 'quy-dinh-lam-viec',
                      element: (
                        <ManageWorkingRule prefixType={PrefixType.CONSULTANT} />
                      ),
                    },
                  ],
                },
                {
                  path: 'thong-ke-xet-duyet-lop',
                  element: <ManageCheckClass />,
                },
                {
                  path: 'quan-ly-khieu-nai-ngoai',
                  element: <ManageComplaint isOutside />,
                  index: true
                },
                {
                  path: 'quan-ly-khieu-nai',
                  children: [
                    {
                      element: <ManageComplaint />,
                      index: true,
                    },
                    {
                      path: 'xu-ly-khieu-nai/:complaintId',
                      element: <HandleComplaint/>,
                    },
                    {
                      path: 'lich-su',
                      element: <ManageComplaintHistory/>,
                    },
                    {
                      path: 'lich-su/:complaintId',
                      element: <HandleComplaint isHistory/>,
                    },
                  ],
                },
                {
                  path: 'thong-ke-nhac-nop-hoc-phi',
                  element: <ManageTuitionReminderStudentList />,
                },
                {
                  path: 'thong-ke-kiem-duyet-cv',
                  element: <ManageCheckCv />,
                },
                {
                  path: 'thong-ke-nhac-nop-le-phi',
                  element: <ManageTuitionReminderTeacherList />,
                },
                {
                  path: 'thong-ke-tiep-thi-lai',
                  element: <ManageReMarketing />,
                },
                {
                  path: 'thong-ke-ghep-lop-hoc',
                  element: <ManageCombineClasses />,
                },
                {
                  path: 'thong-ke-nhac-nho-hoc',
                  element: <ManageStudyReminder />,
                },
                {
                  path: 'thong-ke-de-xuat-lop',
                  element: <ManageClassRecommendation />,
                },
                {
                  path: 'thong-ke-ghep-lop-day',
                  element: <ManageCombineTeachingClasses />,
                },
                {
                  path: 'thong-ke-nhac-nho-day',
                  element: <ManageTeachReminder />,
                },
                {
                  path: 'thong-ke-nang-suat-lam-viec',
                  element: <ManageEmployeeProductivity />,
                },
                {
                  path: 'thong-ke-tu-van-nguoi-hoc',
                  element: <ManageCounseling userType={UserType.STUDENT} />,
                },
                {
                  path: 'thong-ke-tu-van-nguoi-day',
                  element: <ManageCounseling userType={UserType.TEACHER} />,
                },
              ],
            },
          ],
        },
        //Support consultant
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: 'coming-soon', element: <ComingSoon /> },
            { path: '500', element: <Page500 link={PATH_CONSULTANT.root} /> },
            { path: '404', element: <Page404 link={PATH_CONSULTANT.root} /> },
            { path: '403', element: <Page403 link={PATH_CONSULTANT.root} /> },
            {
              path: '*',
              element: <Navigate to={`${PATH_CONSULTANT.root}/404`} replace />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to={`${PATH_CONSULTANT.root}/404`} replace />,
        },
      ],
    },

    //Accountant Routes
    {
      path: FrontendPrefixType.ACCOUNTANT,
      children: [
        //Auth path
        {
          path: 'auth',
          element: <AuthLayout />,
          children: [
            {
              element: (
                <Navigate
                  to={`/${FrontendPrefixType.ACCOUNTANT}/auth/login`}
                  replace
                />
              ),
              index: true,
            },
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <LoginAccountant />
                </GuestGuard>
              ),
            },
            { path: 'new-password', element: <NewPasswordAccountant /> },
          ],
        },

        //Accountant Dashboard
        {
          path: '',
          element: (
            <AuthAccountantGuard>
              <AccountantLayout />
            </AuthAccountantGuard>
          ),
          children: [
            //Redirect
            {
              element: (
                <>
                  {userType === UserType.ACCOUNTANT &&
                    userRole === AccountantRoleType.MANAGER && (
                      <Navigate
                        to={PATH_AFTER_ACCOUNTANT_MANAGER_LOGIN}
                        replace
                      />
                    )}
                  {userType === UserType.ACCOUNTANT &&
                    userRole === AccountantRoleType.STAFF && (
                      <Navigate to={PATH_AFTER_ACCOUNTANT_LOGIN} replace />
                    )}
                </>
              ),
              index: true,
            },
            //For staff
            {
              path: '',
              element: (
                <RoleBasedGuard hasContent roles={[AccountantRoleType.STAFF]}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: '',
                  element: (
                    <WorkAttendanceGuard hasContent>
                      <Outlet />
                    </WorkAttendanceGuard>
                  ),
                  children: [
                    {
                      path: 'lich-su-cong-viec',
                      element: <AccountantTaskHistory />,
                    },
                    {
                      path: 'yeu-cau-nap-tien',
                      element: <DepositRequestList />,
                    },
                    {
                      path: 'tro-giup-nap-tien',
                      element: <DepositSupportRequestList />,
                    },
                    {
                      path: 'nhan-chua-xac-dinh',
                      children: [
                        {
                          element: <UndefinedReceiptTransactionList />,
                          index: true,
                        },
                        {
                          path: 'lich-su-xac-minh',
                          element: <UndefinedDepositTransactionHistoryList />,
                        },
                      ],
                    },
                    {
                      path: 'thong-ke-tong-thu',
                      children: [
                        {
                          element: <IncomeOverview />,
                          index: true,
                        },
                      ],
                    },
                    {
                      path: 'yeu-cau-rut-tien',
                      element: <WithdrawRequestList />,
                    },
                    {
                      path: 'tro-giup-rut-tien',
                      element: <WithdrawSupportRequestList />,
                    },
                    {
                      path: 'hoan-chua-xac-dinh',
                      children: [
                        {
                          element: <UndefinedWithdrawTransactionList />,
                          index: true,
                        },
                        {
                          path: 'lich-su-xac-minh',
                          element: <UndefinedWithdrawTransactionHistoryList />,
                        },
                      ],
                    },
                    {
                      path: 'thong-ke-tong-chi',
                      children: [
                        {
                          element: <ExpenseOverview />,
                          index: true,
                        },
                      ],
                    },
                    {
                      path: 'thanh-toan-luong',
                      element: <SalaryPayment />,
                    },
                    {
                      path: 'thong-ke-tong-noi-bo',
                      children: [
                        {
                          element: <InternalOverview />,
                          index: true,
                        },
                      ],
                    },
                  ],
                },
                { path: 'lich-lam-viec', element: <AccountantWorkSchedule /> },
                {
                  path: 'thong-ke-luong',
                  element: <AccountantSalaryStatistic />,
                },
                {
                  path: 'danh-sach-boi-thuong',
                  children: [
                    {
                      element: <Compensations />,
                      index: true,
                    },
                    {
                      element: <CompensationsHistory />,
                      path: 'lich-su',
                    },
                  ],
                },
                {
                  path: 'muc-dao-tao',
                  element: (
                    <ManageTrainingSection prefixType={PrefixType.ACCOUNTANT} />
                  ),
                },
                {
                  path: 'lich-su-nhan-luong',
                  element: (
                    <ManageSalaryHistory prefixType={PrefixType.ACCOUNTANT} />
                  ),
                },
                {
                  path: 'quan-ly-tai-khoan',
                  element: (
                    <StaffAccountSetting userType={UserType.ACCOUNTANT} />
                  ),
                },
                {
                  path: 'ho-so-nhan-viec',
                  element: (
                    <DetailStaffApplication userType={UserType.ACCOUNTANT} />
                  ),
                },
              ],
            },
            //For manager
            {
              path: '',
              element: (
                <RoleBasedGuard hasContent roles={[AccountantRoleType.MANAGER]}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                //Common
                {
                  path: 'quan-ly-tuyen-dung',
                  children: [
                    {
                      path: 'tin-tuyen-dung',
                      element: (
                        <ManageRecruitment
                          recruitmentStaffType={RecruitmentStaffType.ACCOUNTANT}
                        />
                      ),
                      index: true,
                    },
                    {
                      path: 'tin-tuyen-dung/:recruitmentCode',
                      element: (
                        <ManageRecruitmentApplication
                          recruitmentStaffType={RecruitmentStaffType.ACCOUNTANT}
                        />
                      ),
                    },
                    {
                      path: 'hop-dong-lam-viec',
                      element: (
                        <ManageContract
                          recruitmentStaffType={RecruitmentStaffType.ACCOUNTANT}
                        />
                      ),
                    },
                    {
                      path: 'hop-dong-lam-viec/:contractCode',
                      element: (
                        <ManageAccountantContractHandover
                          recruitmentStaffType={RecruitmentStaffType.ACCOUNTANT}
                        />
                      ),
                    },
                    {
                      path: 'tam-ung',
                      element: (
                        <ManageEmployeeAdvanceFee
                          prefixType={PrefixType.ACCOUNTANT}
                        />
                      ),
                    },
                    {
                      path: 'quy-dinh-lam-viec',
                      element: (
                        <ManageWorkingRule prefixType={PrefixType.ACCOUNTANT} />
                      ),
                    },
                  ],
                },
                {
                  path: 'quan-ly-ngan-hang',
                  children: [
                    {
                      path: 'the-ngan-hang',
                      element: <ManageBank />,
                      index: true,
                    },
                    {
                      path: 'ngan-hang-thu',
                      element: <ManageRevenueBank />,
                    },
                    {
                      path: 'ngan-hang-chi',
                      element: <ManageExpenseBank />,
                    },
                    {
                      path: 'ngan-hang-noi-bo',
                      element: <ManageInternalBank />,
                    },
                  ],
                },
                {
                  path: 'quan-ly-thong-ke',
                  children: [
                    {
                      path: 'thu',
                      element: <ManageRevenueOverview />,
                      index: true,
                    },
                    {
                      path: 'chi',
                      element: <ManageExpenseOverview />,
                    },
                    {
                      path: 'noi-bo',
                      element: <ManageInternalOverview />,
                    },
                  ],
                },
                {
                  path: 'quan-ly-nhan-vien',
                  children: [
                    {
                      path: 'dao-tao-nhan-vien',
                      element: (
                        <ManageTrainingStaff prefix={PrefixType.ACCOUNTANT} />
                      ),
                      index: true,
                    },
                    {
                      path: 'dao-tao-nhan-vien/:id',
                      element: (
                        <DetailTrainingStaff prefix={PrefixType.ACCOUNTANT} />
                      ),
                    },
                    {
                      path: 'cai-dat-lich-lam',
                      element: (
                        <ManageStaffSchedule prefix={PrefixType.ACCOUNTANT} />
                      ),
                    },
                    {
                      path: 'cai-dat-nhan-vien',
                      element: <AccountantStaffsSetting />,
                    },
                    {
                      path: 'cai-dat-nhan-vien/:id',
                      element: <StaffSetting />,
                    },
                  ],
                },
                //Deposit transaction report
                {
                  path: 'bao-cao-nap-tien',
                  children: [
                    {
                      element: (
                        <ReportTransactionList
                          transactionTypes={[
                            TransactionTypeType.DEPOSIT,
                            TransactionTypeType.DEPOSIT_SUPPORT,
                          ]}
                        />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <ReportTransactionHistoryList
                          transactionTypes={[
                            TransactionTypeType.DEPOSIT,
                            TransactionTypeType.DEPOSIT_SUPPORT,
                          ]}
                        />
                      ),
                    },
                  ],
                },
                //Undefined transaction verification
                {
                  path: 'xac-minh-nhan-chua-xac-dinh',
                  children: [
                    {
                      element: (
                        <UndefinedTransactionVerificationList dashboardSide="revenue" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <UndefinedTransactionVerificationHistoryList dashboardSide="revenue" />
                      ),
                    },
                  ],
                },
                //Undefined transaction deletion
                {
                  path: 'duyet-xoa-nhan-chua-xac-dinh',
                  children: [
                    {
                      element: (
                        <UndefinedTransactionDeletionList dashboardSide="revenue" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xoa-ma',
                      element: (
                        <UndefinedTransactionDeletionHistoryList dashboardSide="revenue" />
                      ),
                    },
                  ],
                },
                //Revenue company withdraw transaction verification
                {
                  path: 'thu-tien-duyet-tien-lay-ve',
                  children: [
                    {
                      element: (
                        <VerifyCompanyWithdrawTransactionList dashboardSide="revenue" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <VerifyCompanyWithdrawTransactionHistoryList dashboardSide="revenue" />
                      ),
                    },
                  ],
                },
                //Company money holder account verification
                {
                  path: 'duyet-thu-cuoi-ca',
                  children: [
                    {
                      element: (
                        <VerifyCompanyMoneyHolderAccountVerificationList dashboardSide="revenue" />
                      ),
                      index: true,
                    },
                    {
                      element: (
                        <VerifyCompanyMoneyHolderAccountVerificationHistoryList dashboardSide="revenue" />
                      ),
                      path: 'lich-su-xac-minh/:id',
                    },
                  ],
                },
                //Compensation Transaction List verification
                {
                  path: 'duyet-xu-ly-boi-thuong-thu',
                  children: [
                    {
                      element: (
                        <ManageCompensationTransactionList dashboardSide="revenue" />
                      ),
                      index: true,
                    },
                    {
                      element: (
                        <ManageCompensationTransactionHistoryList dashboardSide="revenue" />
                      ),
                      path: 'lich-su-xac-minh',
                    },
                  ],
                },
                //--For expense function
                //Withdraw transaction report
                {
                  path: 'bao-cao-rut-tien',
                  children: [
                    {
                      element: (
                        <ReportTransactionList
                          transactionTypes={[TransactionTypeType.WITHDRAW]}
                        />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <ReportTransactionHistoryList
                          transactionTypes={[TransactionTypeType.WITHDRAW]}
                        />
                      ),
                    },
                  ],
                },
                //Undefined transaction verification
                {
                  path: 'xac-minh-hoan-chua-xac-dinh',
                  children: [
                    {
                      element: (
                        <UndefinedTransactionVerificationList dashboardSide="expense" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <UndefinedTransactionVerificationHistoryList dashboardSide="expense" />
                      ),
                    },
                  ],
                },
                //Undefined transaction deletion
                {
                  path: 'duyet-xoa-hoan-chua-xac-dinh',
                  children: [
                    {
                      element: (
                        <UndefinedTransactionDeletionList dashboardSide="expense" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xoa-ma',
                      element: (
                        <UndefinedTransactionDeletionHistoryList dashboardSide="expense" />
                      ),
                    },
                  ],
                },
                //Expense company deposit transaction verification
                {
                  path: 'chi-tien-duyet-tien-them-vao',
                  children: [
                    {
                      element: (
                        <VerifyCompanyDepositTransactionList
                          dashboardSide={'expense'}
                        />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <VerifyCompanyDepositTransactionHistoryList
                          dashboardSide={'expense'}
                        />
                      ),
                    },
                  ],
                },
                //Expense company withdraw transaction verification
                {
                  path: 'chi-tien-duyet-tien-lay-ve',
                  children: [
                    {
                      element: (
                        <VerifyCompanyWithdrawTransactionList dashboardSide="expense" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <VerifyCompanyWithdrawTransactionHistoryList dashboardSide="expense" />
                      ),
                    },
                  ],
                },
                //Company money holder account verification
                {
                  path: 'duyet-chi-cuoi-ca',
                  children: [
                    {
                      element: (
                        <VerifyCompanyMoneyHolderAccountVerificationList dashboardSide="expense" />
                      ),
                      index: true,
                    },
                    {
                      element: (
                        <VerifyCompanyMoneyHolderAccountVerificationHistoryList dashboardSide="expense" />
                      ),
                      path: 'lich-su-xac-minh/:id',
                    },
                  ],
                },
                //Compensation Transaction List verification
                {
                  path: 'duyet-xu-ly-boi-thuong-chi',
                  children: [
                    {
                      element: (
                        <ManageCompensationTransactionList dashboardSide="expense" />
                      ),
                      index: true,
                    },
                    {
                      element: (
                        <ManageCompensationTransactionHistoryList dashboardSide="expense" />
                      ),
                      path: 'lich-su-xac-minh',
                    },
                  ],
                },
                //--For internal
                //internal company deposit transaction verification
                {
                  path: 'noi-bo-duyet-tien-them-vao',
                  children: [
                    {
                      element: (
                        <VerifyCompanyDepositTransactionList dashboardSide="internal" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <VerifyCompanyDepositTransactionHistoryList dashboardSide="internal" />
                      ),
                    },
                  ],
                },
                {
                  path: 'noi-bo-duyet-tien-chi-khac',
                  children: [
                    {
                      element: (
                        <VerifyCompanyOtherExpenseTransactionList dashboardSide="internal" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <VerifyCompanyOtherExpenseTransactionHistoryList dashboardSide="internal" />
                      ),
                    },
                  ],
                },
                //internal company withdraw transaction verification
                {
                  path: 'noi-bo-duyet-tien-lay-ve',
                  children: [
                    {
                      element: (
                        <VerifyCompanyWithdrawTransactionList dashboardSide="internal" />
                      ),
                      index: true,
                    },
                    {
                      path: 'lich-su-xac-minh',
                      element: (
                        <VerifyCompanyWithdrawTransactionHistoryList dashboardSide="internal" />
                      ),
                    },
                  ],
                },
              ],
            },
            { path: 'dashboard', element: <AccountantDashboard /> },
          ],
        },
        //Support accountant
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: 'coming-soon', element: <ComingSoon /> },
            { path: '500', element: <Page500 link={PATH_ACCOUNTANT.root} /> },
            { path: '404', element: <Page404 link={PATH_ACCOUNTANT.root} /> },
            { path: '403', element: <Page403 link={PATH_ACCOUNTANT.root} /> },
            {
              path: '*',
              element: <Navigate to={`${PATH_ACCOUNTANT.root}/404`} replace />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to={`${PATH_ACCOUNTANT.root}/404`} replace />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// --------------------------START ADMIN----------------------------------
// AUTHENTICATION
const LoginAdmin = Loadable(lazy(() => import('../pages/admin/auth/Login')));

// DASHBOARD
//----Industry Department Subject
const IndustryManageAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/Industry')),
);
//----Industry Location
const LocationManageAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/Location')),
);
//---- Term Policy
const TermPolicyListAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/term-policy/TermPolicyList')),
);
const TermPolicyAddAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/term-policy/TermPolicyAdd')),
);
const TermPolicyEditAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/term-policy/TermPolicyEdit')),
);
//----User
const UserListAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/user/list/List')),
);
const UserDetailAdmin = Loadable(
  lazy(() => import('../pages/admin/dashboard/user/UserDetail')),
);
// --------------------------END ADMIN----------------------------------

// --------------------------START COMMON STAFF----------------------------------

// --------------------------END COMMON STAFF----------------------------------

// --------------------------START CONSULTANT----------------------------------
//AUTHENTICATION
const LoginConsultant = Loadable(
  lazy(() => import('../pages/consultant/auth/Login')),
);
const NewPasswordConsultant = Loadable(
  lazy(() => import('../pages/consultant/auth/NewPassword')),
);
//DASHBOARD
//--Common
//----ConsultantTaskHistory
const ConsultantTaskHistory = Loadable(
  lazy(() => import('../pages/consultant/dashboard/TaskHistory')),
);
//----ConsultantSalaryStatistic
const ConsultantSalaryStatistic = Loadable(
  lazy(() => import('../pages/consultant/dashboard/SalaryStatistics')),
);
//----CV
const CVList = Loadable(
  lazy(() => import('../pages/consultant/dashboard/cv/CVList')),
);
//----Class
const ClassList = Loadable(
  lazy(() => import('../pages/consultant/dashboard/class/ClassList')),
);
//----Tuition reminder student
const TuitionReminderStudentList = Loadable(
  lazy(
    () =>
      import('../pages/consultant/dashboard/tuition/FeeReminderStudentList'),
  ),
);
//----Tuition rrminder teacher
const TuitionReminderTeacherList = Loadable(
  lazy(
    () =>
      import('../pages/consultant/dashboard/tuition/FeeReminderTeacherList'),
  ),
);
const ConsultantWorkSchedule = Loadable(
  lazy(() => import('../pages/consultant/dashboard/WorkSchedule')),
);
//---- combine class student
const CombineClassesList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/combine-classes/CombineClassesList'
      ),
  ),
);
//---- combine class teacher
const CombineTeachingClassesList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/combine-classes/CombineTeachingClassesList'
      ),
  ),
);
//---- re marketing
const ReMarketingList = Loadable(
  lazy(
    () => import('../pages/consultant/dashboard/re-marketing/ReMarketingList'),
  ),
);
//---- reminder to teach
const RemindToTeachList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/reminder-to-teach/ReminderToTeachList'
      ),
  ),
);
//---- remind to learn
const RemindToLearnList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/reminder-to-learn/ReminderToLearnList'
      ),
  ),
);

//---- combine class teacher
const ClassProposalList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/class-proposal/TeacherNeedProposalList'
      ),
  ),
);

//---- combine class teacher
const ClassProposalChildrenList = Loadable(
  lazy(
    () =>
      import('../pages/consultant/dashboard/class-proposal/ClassProposalList'),
  ),
);

//--Manager

// --------------------------END CONSULTANT----------------------------------

// --------------------------START ACCOUNTANT----------------------------------
//AUTHENTICATION
const LoginAccountant = Loadable(
  lazy(() => import('../pages/accountant/auth/Login')),
);
const NewPasswordAccountant = Loadable(
  lazy(() => import('../pages/accountant/auth/NewPassword')),
);
//DASHBOARD
const AccountantDashboard = Loadable(
  lazy(() => import('../pages/accountant/dashboard')),
);
//----AccountantSalaryStatistic
const AccountantSalaryStatistic = Loadable(
  lazy(() => import('../pages/accountant/dashboard/SalaryStatistics')),
);

/**
 * Income Money
 *
 *
 */

//---Deposit Request
const DepositRequestList = Loadable(
  lazy(() => import('../pages/accountant/dashboard/DepositRequestList')),
);

//---Deposit Support Request
const DepositSupportRequestList = Loadable(
  lazy(() => import('../pages/accountant/dashboard/DepositSupportRequestList')),
);

//---Undefined Receipt
const UndefinedReceiptTransactionList = Loadable(
  lazy(
    () =>
      import('../pages/accountant/dashboard/UndefinedDepositTransactionList'),
  ),
);

//---Undefined Receipt History
const UndefinedDepositTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/UndefinedDepositTransactionHistoryList'
      ),
  ),
);

//----Undefined Receipt Verification
const UndefinedTransactionVerificationList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/UndefinedTransactionVerificationList'
      ),
  ),
);

//----Undefined Receipt Verification History
const UndefinedTransactionVerificationHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/UndefinedTransactionVerificationHistoryList'
      ),
  ),
);

const IncomeOverview = Loadable(
  lazy(() => import('../pages/accountant/dashboard/RevenueOverview')),
);

/**
 * Internal Money
 *
 *
 */

const InternalOverview = Loadable(
  lazy(() => import('../pages/accountant/dashboard/InternalOverview')),
);

/**
 * Common money
 *
 *
 */
//---- Undefined  Deletion
const UndefinedTransactionDeletionList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/UndefinedTransactionDeletionList'
      ),
  ),
);

//---- Undefined  Deletion History
const UndefinedTransactionDeletionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/UndefinedTransactionDeletionHistoryList'
      ),
  ),
);

//----- Report Transaction
const ReportTransactionList = Loadable(
  lazy(
    () => import('../pages/accountant/dashboard/manager/ReportTransactionList'),
  ),
);

const ReportTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/ReportTransactionHistoryList'
      ),
  ),
);

const VerifyCompanyWithdrawTransactionList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyWithdrawTransactionList'
      ),
  ),
);

const VerifyCompanyWithdrawTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyWithdrawTransactionHistoryList'
      ),
  ),
);

const VerifyCompanyDepositTransactionList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyDepositTransactionList'
      ),
  ),
);

const VerifyCompanyDepositTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyDepositTransactionHistoryList'
      ),
  ),
);
/**
 * Expense Money
 *
 *
 */

const ExpenseOverview = Loadable(
  lazy(() => import('../pages/accountant/dashboard/ExpenseOverview')),
);

//--Withdraw Request
const WithdrawRequestList = Loadable(
  lazy(() => import('../pages/accountant/dashboard/WithdrawRequestList')),
);

//-- Withdraw Support Request
const WithdrawSupportRequestList = Loadable(
  lazy(
    () => import('../pages/accountant/dashboard/WithdrawSupportRequestList'),
  ),
);

//-- Undefined Withdraw Support Request
const UndefinedWithdrawTransactionList = Loadable(
  lazy(
    () =>
      import('../pages/accountant/dashboard/UndefinedWithdrawTransactionList'),
  ),
);

const UndefinedWithdrawTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/UndefinedWithdrawTransactionHistoryList'
      ),
  ),
);

// salary payment
const SalaryPayment = Loadable(
  lazy(() => import('../pages/accountant/dashboard/SalaryPayment')),
);

//Common
const AccountantWorkSchedule = Loadable(
  lazy(() => import('../pages/accountant/dashboard/WorkSchedule')),
);

const AccountantTaskHistory = Loadable(
  lazy(() => import('../pages/accountant/dashboard/TaskHistory')),
);

const ManageBank = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/ManageBank')),
);

const ManageRevenueBank = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/ManageRevenueBank')),
);

const ManageExpenseBank = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/ManageExpenseBank')),
);

const ManageInternalBank = Loadable(
  lazy(
    () => import('../pages/accountant/dashboard/manager/ManageInternalBank'),
  ),
);

const ManageRevenueOverview = Loadable(
  lazy(
    () => import('../pages/accountant/dashboard/manager/ManageRevenueOverview'),
  ),
);

const ManageExpenseOverview = Loadable(
  lazy(
    () => import('../pages/accountant/dashboard/manager/ManageExpenseOverview'),
  ),
);

const ManageInternalOverview = Loadable(
  lazy(
    () =>
      import('../pages/accountant/dashboard/manager/ManageInternalOverview'),
  ),
);

const VerifyCompanyMoneyHolderAccountVerificationList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyMoneyHolderAccountVerificationList'
      ),
  ),
);

const VerifyCompanyMoneyHolderAccountVerificationHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyMoneyHolderAccountVerificationHistoryList'
      ),
  ),
);

const VerifyCompanyOtherExpenseTransactionList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyOtherExpenseTransactionList'
      ),
  ),
);

const VerifyCompanyOtherExpenseTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/VerifyCompanyOtherExpenseTransactionHistoryList'
      ),
  ),
);

const ManageCheckCv = Loadable(
  lazy(() => import('../pages/consultant/dashboard/manager/ManageCheckCv')),
);

const ManageCheckClass = Loadable(
  lazy(() => import('../pages/consultant/dashboard/manager/ManageCheckClass')),
);
const ManageTuitionReminderStudentList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/manager/ManageTuitionReminderStudentList'
      ),
  ),
);
const ManageTuitionReminderTeacherList = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/manager/ManageTuitionReminderTeacherList'
      ),
  ),
);

const ManageClassRecommendation = Loadable(
  lazy(
    () =>
      import('../pages/consultant/dashboard/manager/ManageClassRecommendation'),
  ),
);

const ManageCombineClasses = Loadable(
  lazy(
    () => import('../pages/consultant/dashboard/manager/ManageCombineClasses'),
  ),
);

const ManageCombineTeachingClasses = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/manager/ManageCombineTeachingClasses'
      ),
  ),
);

const ManageReMarketing = Loadable(
  lazy(() => import('../pages/consultant/dashboard/manager/ManageReMarketing')),
);

const ManageStudyReminder = Loadable(
  lazy(
    () => import('../pages/consultant/dashboard/manager/ManageStudyReminder'),
  ),
);

const ManageTeachReminder = Loadable(
  lazy(
    () => import('../pages/consultant/dashboard/manager/ManageTeachReminder'),
  ),
);

const ManageEmployeeProductivity = Loadable(
  lazy(
    () =>
      import(
        '../pages/consultant/dashboard/manager/ManageEmployeeProductivity'
      ),
  ),
);

const ManageCounseling = Loadable(
  lazy(() => import('../pages/consultant/dashboard/manager/ManageCounseling')),
);

// --------------------------END ACCOUNTANT----------------------------------
// --------------------------START COMMON----------------------------------
const ManageStaffSchedule = Loadable(
  lazy(() => import('../pages/common/manager/ManageStaffSchedule')),
);

const ManageTrainingStaff = Loadable(
  lazy(() => import('../pages/common/manager/ManageTrainingStaff')),
);

const DetailTrainingStaff = Loadable(
  lazy(() => import('../pages/common/manager/DetailTrainingStaff')),
);

const AccountantStaffsSetting = Loadable(
  lazy(
    () =>
      import('../pages/accountant/dashboard/manager/AccountantStaffsSetting'),
  ),
);

const ConsultantStaffsSetting = Loadable(
  lazy(
    () =>
      import('../pages/consultant/dashboard/manager/ConsultantStaffsSetting'),
  ),
);

const StaffSetting = Loadable(
  lazy(() => import('../pages/common/manager/StaffSetting')),
);

const ManageRecruitment = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/ManageRecruitment')),
);

const ManageComplaint = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/manage-user-complaint/ManageComplaint')),
);

const ManageComplaintHistory = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/manage-user-complaint/ManageComplaintHistory')),
);

const HandleComplaint = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/manage-user-complaint/HandleUserComplaint')),
);

const ManageRecruitmentApplication = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/ManageRecruitmentApplication'
      ),
  ),
);

const ManageContract = Loadable(
  lazy(() => import('../pages/accountant/dashboard/manager/ManageContract')),
);

const ManageAccountantContractHandover = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/ManageAccountantContractHandover'
      ),
  ),
);

const ManageConsultantContractHandover = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/ManageConsultantContractHandover'
      ),
  ),
);

const DetailStaffApplication = Loadable(
  lazy(() => import('../pages/common/DetailStaffApplication')),
);

const StaffAccountSetting = Loadable(
  lazy(() => import('../pages/common/StaffAccountSetting')),
);

const ManageEmployeeAdvanceFee = Loadable(
  lazy(() => import('../pages/common/manager/ManageEmployeeAdvanceFee')),
);

const ManageWorkingRule = Loadable(
  lazy(() => import('../pages/common/manager/ManageWorkingRule')),
);

// history salary
const ManageSalaryHistory = Loadable(
  lazy(() => import('../pages/common/manager/ManageSalaryHistory/index')),
);

// training section
const ManageTrainingSection = Loadable(
  lazy(() => import('../pages/common/manager/ManageTrainingSection')),
);

//compensations
const Compensations = Loadable(
  lazy(() => import('../pages/accountant/dashboard/Compensations')),
);

const CompensationsHistory = Loadable(
  lazy(() => import('../pages/accountant/dashboard/CompensationsHistory')),
);

const ManageCompensationTransactionList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/CompensationTransactionList'
      ),
  ),
);

const ManageCompensationTransactionHistoryList = Loadable(
  lazy(
    () =>
      import(
        '../pages/accountant/dashboard/manager/CompensationTransactionListHistory'
      ),
  ),
);

// --------------------------END COMMON----------------------------------

// OTHER
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
