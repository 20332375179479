import { CommonDashboardSideType } from 'src/@types/dashboard';
import {
  resetCreateDialog,
  setCreateDialog,
} from 'src/redux/slices/company-money-holder-account-verification.slice';
import { useDispatch, useSelector } from 'src/redux/store';

type Props = {
  dashboardSide: CommonDashboardSideType;
};

export function useCreateCompanyMoneyHolderAccountVerificationDialog({
  dashboardSide,
}: Props) {
  //State
  const toggle = useSelector(
    (state) =>
      state.companyMoneyHolderAccountVerification.default[dashboardSide].create
        .dialog.toggle,
  );

  //hook
  const dispatch = useDispatch();

  //open
  const openDialog = (groupId?: number, date?: string) => {
    dispatch(
      setCreateDialog({
        dashboardSide,
        dialog: {
          toggle: true,
          groupId,
          date,
        },
      }),
    );
  };

  //close
  const closeDialog = () => {
    dispatch(
      resetCreateDialog({
        dashboardSide,
      }),
    );
  };

  return {
    toggle,
    openDialog,
    closeDialog,
  };
}
