import { OtherExpenseMode } from 'src/@types/transaction';
import { resetFile } from 'src/redux/slices/file.slice';
import {
  resetCreateCompanyOtherExpenseDialog,
  setCreateCompanyOtherExpenseDialog,
  setSelectedTransactionId,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useCreateDialog() {
  const { toggle, mode } = useSelector(
    (state) =>
      state.transaction.default.internal.createCompanyOtherExpense.dialog,
  );

  const dispatch = useDispatch();

  const open = (mode: OtherExpenseMode) => {
    dispatch(resetFile());
    dispatch(
      setCreateCompanyOtherExpenseDialog({
        dialog: {
          mode,
          toggle: true,
        },
      }),
    );
  };

  const openWithId = (id: number, mode: OtherExpenseMode) => {
    dispatch(
      setCreateCompanyOtherExpenseDialog({
        dialog: {
          toggle: true,
          mode,
        },
      }),
    );
    dispatch(setSelectedTransactionId(id));
  };

  const close = () => {
    dispatch(setSelectedTransactionId(undefined));
    dispatch(resetCreateCompanyOtherExpenseDialog());
  };

  return { toggle, open, close, openWithId, mode };
}
