import { PaginationRes } from 'src/@types/app-response';
import { GetStaticByUserRequest } from 'src/@types/common';
import {
  ConversationType,
  GetStaticConversationRequest,
  GetStaticCountRatingConversationRequest,
  GetStaticDetailTimeConversationRequest,
  OverviewTaskHistoryRequest,
  OverviewTaskHistoryResponse,
  SearchTaskHistoryRequest,
  SearchTaskHistoryResponse,
  StaticConversationRes,
  StaticCountCallConversationRes,
  staticCountConversationByUserRes,
  StaticCountRatingConversationRes,
  StaticDetailTimeCountConversationRes,
} from 'src/@types/conversation';
import { Pagination } from 'src/@types/pagination';
import { PrefixType } from 'src/constants/prefix';
import axiosInstance from 'src/utils/axios';
import {
  CONTACT_CONVERSATION_API,
  CONVERSATION_API,
} from '../constants/conversation.api.constant';

export enum IndustryServerType {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export const overviewTaskHistory = (
  request: OverviewTaskHistoryRequest,
): Promise<OverviewTaskHistoryResponse> =>
  axiosInstance.get(
    `${PrefixType.CONSULTANT}${CONVERSATION_API.OVERVIEW_TASK_HISTORY}`,
    {
      params: request,
    },
  );

export const searchTaskHistory = (
  request: SearchTaskHistoryRequest,
): Promise<PaginationRes<SearchTaskHistoryResponse>> =>
  axiosInstance.get(
    `${PrefixType.CONSULTANT}${CONVERSATION_API.SEARCH_TASK_HISTORY}`,
    {
      params: {
        ...request,
      },
    },
  );

export const getConversationsToday = ({
  limit,
  page,
  startTime,
}: Pagination & {
  startTime?: string | Date;
}): Promise<PaginationRes<ConversationType>> =>
  axiosInstance.get(
    `${CONTACT_CONVERSATION_API.ROOT}${CONTACT_CONVERSATION_API.CONVERSATIONS_TODAY}`,
    {
      params: {
        limit,
        page,
        startTime,
      },
    },
  );

export const createRecordConversation = (data: {
  nameFile: string;
  bucketName: string;
  conversationId: number;
}): Promise<any> =>
  axiosInstance.post(
    `${CONTACT_CONVERSATION_API.ROOT}${CONTACT_CONVERSATION_API.RECORD}`,
    {
      ...data,
    },
  );

export const getStaticCountConversation = (
  params: GetStaticConversationRequest,
): Promise<StaticConversationRes> =>
  axiosInstance.get(
    `/${PrefixType.CONSULTANT}${CONVERSATION_API.STATIC_COUNT_CONVERSATION}`,
    {
      params,
    },
  );

export const getStaticDetailTimeCountConversation = (
  params: GetStaticDetailTimeConversationRequest,
): Promise<StaticDetailTimeCountConversationRes> =>
  axiosInstance.get(
    `/${PrefixType.CONSULTANT}${CONVERSATION_API.STATIC_DETAIL_TIME_COUNT_CONVERSATION}`,
    {
      params,
    },
  );

export const getStaticCountRatingConversation = (
  params: GetStaticCountRatingConversationRequest,
): Promise<StaticCountRatingConversationRes> =>
  axiosInstance.get(
    `/${PrefixType.CONSULTANT}${CONVERSATION_API.STATIC_COUNT_RATING_CONVERSATION}`,
    {
      params,
    },
  );

  export const getStaticCountCallConversation = (
    params: GetStaticCountRatingConversationRequest,
  ): Promise<StaticCountCallConversationRes> =>
    axiosInstance.get(
      `/${PrefixType.CONSULTANT}${CONVERSATION_API.STATIC_COUNT_CALL_CONVERSATION}`,
      {
        params,
      },
    );

export const getStaticCountConversationByUser = (
  params: GetStaticByUserRequest,
): Promise<staticCountConversationByUserRes> =>
  axiosInstance.get(
    `/${PrefixType.CONSULTANT}${CONVERSATION_API.STATIC_COUNT_CONVERSATION_BY_USER}`,
    {
      params,
    },
  );
