export const QUERY_KEYS = Object.freeze({
  //Auth
  CHECK_ROLE: 'check_role',

  //For Roll
  ACCOUNTANT: 'accountant',
  CONSULTANT: 'consultant',

  //
  SEARCH_TASK_HISTORY: 'search_task_history',
  //Salary statistics
  SALARY_STATISTICS: 'salary_statistics',
  //Industry
  SUBJECTS: 'subjects',
  INDUSTRIES: 'industries',
  DEPARTMENTS: 'departments',
  EXPERIENCES: 'experiences',
  CONNECT_DEPARTMENT_SUBJECT: 'connect_department_subject',
  //Location
  PROVINCES: 'provinces',
  DISTRICTS: 'districts',
  WARDS: 'wards',
  //Term and policy
  TERM_AND_POLICIES: 'term_and_policies',
  TERM_AND_POLICY: 'term_and-policy',
  //User
  USERS: 'users',
  USER: 'user',
  SEARCH_STAFF: 'search_Staff',
  SEARCH_TEACHER_TO_COMBINE_CLASS: 'search_teacher_to_combine_class',
  SEARCH_TEACHER_NEED_PROPOSAL: 'search_teacher_need_proposal',
  SEARCH_STUDENT_NEED_RE_MARKETING: 'search_student_need_re_marketing',
  //Cv
  CVS: 'cvs',
  CV: 'cv',
  CV_REQUESTS: 'cv_requests',
  CV_REQUEST: 'cv_request',
  STATIC_COUNT_CV_REQUEST: 'static_count_cv_request',
  STATIC_COUNT_CV_REQUEST_BY_USER: 'static_count_cv_request_by_user',
  STATIC_COUNT_CV_KPI: 'static_count_cv_kpi',
  STATIC_COUNT_PENDING_CV_REQUEST: 'static_count_pending_cv_request',
  STATIC_COUNT_TYPE_CV_REQUEST: 'static_count_type_cv_request',
  STATIC_COUNT_TYPE_CV: 'static_count_type_cv',
  STATIC_DETAIL_TIME_COUNT_CV_KPI_CLASS:
    'static_detail_time_count_cv_kpi_class',

  //Class
  CLASSES: 'classes',
  CLASS: 'class',
  CLASS_SUM_FOR_TEACHER: 'class_sum_for_teacher',
  CLASS_APPLICATION_INFORMATIONS: 'class_application_informations',
  CLASS_APPLICATION_INFORMATION: 'class_application_information',
  CLASS_FAIL_REASON: 'class_fail_reason',
  STATIC_COUNT_CLASS_CREATE: 'static_count_class_create',
  STATIC_COUNT_CHECKED_CLASS: 'static_count_checked_class',
  STATIC_COUNT_PENDING_CHECK_CLASS: 'static_count_pending_check_class',
  STATIC_DETAIL_TIME_COUNT_CREATED_CHECKED_CLASS_ONLINE:
    'static_detail_time_count_created_checked_class_online',
  STATIC_DETAIL_TIME_COUNT_CREATED_CHECKED_CLASS_OFFLINE:
    'static_detail_time_count_created_checked_class_offline',
  STATIC_COUNT_TYPE_CLASS_KPI_ONLINE: 'static_count_type_class_kpi_online',
  STATIC_COUNT_TYPE_CLASS_KPI_OFFLINE: 'static_count_type_class_kpi_offline',
  STATIC_COUNT_CHECKED_CLASS_REQUEST_BY_USER:
    'static_count_checked_class_request_by_user',

  // call kpi
  CALL_KPI: 'call_kpi',
  STATIC_COUNT_PENDING_CALL_FEE_REMINDER_STUDENT:
    'static_count_pending_call_Fee_reminder_student',
  STATIC_COUNT_PENDING_CALL_FEE_REMINDER_TEACHER:
    'static_count_pending_call_Fee_reminder_teacher',
  STATIC_COUNT_PENDING_CALL_REMINDER_RE_MARKETING:
    'static_count_pending_call_reminder_re_marketing',
  STATIC_COUNT_PENDING_CALL_REMINDER_TEACH:
    'static_count_pending_call_reminder_teach',
  STATIC_COUNT_PENDING_CALL_REMINDER_TEACHER_NEED_PROPOSAL:
    'static_count_pending_call_reminder_teacher_need_proposal',
  STATIC_COUNT_PENDING_CALL_REMINDER_STUDY:
    'static_count_pending_call_reminder_study',
  STATIC_COUNT_PENDING_CALL_REMINDER_COMBINE_CLASSES:
    'static_count_pending_call_reminder_combine_classes',
  STATIC_COUNT_PENDING_CALL_REMINDER_COMBINE_TEACHING_CLASSES:
    'static_count_pending_call_reminder_combine_teaching_classes',
  STATIC_COUNT_CALL_REMINDER_SUCCESS: 'static_count_call_reminder_success',
  STATIC_COUNT_CALL_REMINDER_BY_USER: 'static_count_call_reminder_by_user',
  STATIC_COUNT_CALL_REMINDER_REJECT: 'static_count_call_fee_reminder_reject',
  STATIC_DETAIL_TIME_COUNT_CALL_REMINDER_SUCCESS_REJECT:
    'static_detail_time_count_call_reminder_success_reject',
  STATIC_COUNT_STATUS_CALL_KPI: 'static_count_status_call_kpi',
  STATIC_COUNT_TIMES_NO_ANSWER_CALL_KPI:
    'static_count_times_no_answer_call_kpi',

  CLASSES_COMBINE: 'classes_combine',
  CLASSES_NEED_REMINDER_TO_TEACH: 'classes_need_reminder_to_teach',
  CLASSES_NEED_REMINDER_TO_LEARN: 'classes_need_reminder_to_learn',
  CLASSES_NEED_PROPOSAL: 'list_class_need_proposal',
  //Conversations
  CONVERSATIONS: 'conversations',
  CONVERSATION: 'conversation',
  STATIC_COUNT_CONVERSATION: 'static_count_conversation',
  STATIC_COUNT_CONVERSATION_BY_USER: 'static_count_conversation_by_user',
  STATIC_DETAIL_TIME_COUNT_CONVERSATION:
    'static_detail_time_count_conversation',
  STATIC_DETAIL_TIME_COUNT_RATING_CONVERSATION:
    'static_detail_time_count_rating_conversation',
  STATIC_DETAIL_TIME_COUNT_CALL_CONVERSATION:
    'static_detail_time_count_call_conversation',

  //Messages
  MESSAGES: 'messages',
  //Task history
  OVERVIEW_TASK_HISTORY: 'overview_task_history',
  //Transactions
  DEPOSIT_TRANSACTIONS: 'deposit_transactions',
  WITHDRAW_TRANSACTIONS: 'withdraw_transactions',
  TRANSACTION: 'transaction',
  CHECK_SALARY_REQUEST_TRANSACTION: 'check_salary_request_transaction',
  CHILD_TRANSACTION: 'child_transaction',
  TRANSACTION_VERIFICATION_FAIL_REASON: 'transaction_verification_fail_reason',
  TRANSACTIONS: 'transactions',
  SUP_TRANSACTIONS_BY_ACCT: 'sup_transactions_by_acct',
  VERIFY_PENDING_COMPANY_ACCOUNT_WITHDRAW_TRANSACTION:
    'verify_pending_company_account_withdraw_transaction',
  VERIFY_PENDING_COMPANY_ACCOUNT_DEPOSIT_TRANSACTION:
    'verify_pending_company_account_deposit_transaction',
  VERIFY_PENDING_COMPANY_ACCOUNT_OTHER_EXPENSE_TRANSACTIONS:
    'verify_pending_company_account_other_expense_transactions',
  COMPANY_ACCOUNT_WITHDRAW_TRANSACTIONS:
    'company_account_withdraw_transactions',
  COMPANY_ACCOUNT_DEPOSIT_TRANSACTIONS: 'company_account_deposit_transactions',
  TRANSACTION_CHART: 'transaction_chart',
  TRANSACTION_STATISTIC_OVERVIEW: 'transaction_statistic_overview',
  TRANSACTION_OVERVIEW: 'transaction_overview',
  GET_PREVIEW_INDEX_PAYMENT_SALARY_TRANSACTION:
    'get_preview_index_payment_salary_transaction',

  //Transaction verification
  TRANSACTION_VERIFICATIONS: 'transaction_verifications',
  TRANSACTION_VERIFICATION: 'transaction_verification',

  //Transaction support
  TRANSACTION_SUPPORTS: 'transaction_supports',
  TRANSACTION_SUPPORT: 'transaction_support',

  //Transaction support request
  TRANSACTION_SUPPORT_REQUESTS: 'transaction_support_requests',
  TRANSACTION_SUPPORT_REQUEST: 'transaction_support_request',
  TRANSACTION_SUPPORT_REQUEST_BY_UNDEFINED_TRANSACTION_ID:
    'transaction_support_request_by_undefined_transaction_id',
  TRANSACTION_SUPPORT_REQUEST_OVERVIEW: 'transaction_support_request_overview',

  //Transaction report
  TRANSACTION_REPORTS: 'transaction_reports',
  TRANSACTION_REPORT: 'transaction_report',
  TRANSACTION_REPORT_FAIL_REASON: 'transaction_report_fail_reason',
  OVER_AND_UNDER_COUNT: 'over_and_under_count',
  //Company transaction
  COMPANY_TRANSACTIONS: 'company_transactions',
  //Undefined transaction
  UNDEFINED_TRANSACTION_GET_ALL_MONEY: 'undefined_transaction_get_all_money',
  UNDEFINED_TRANSACTION: 'undefined_transaction',
  COUNT_UNDEFINED_TRANSACTION: 'count_undefined_transaction',
  UNDEFINED_TRANSACTIONS: 'undefined_transactions',
  UNDEFINED_TRANSACTION_VERIFY_REQUEST: 'undefined_transaction_verify_request',
  COUNT_UNDEFINED_TRANSACTION_VERIFY_REQUEST:
    'count_undefined_transaction_verify_request',
  UNDEFINED_TRANSACTION_VERIFY_REQUESTS:
    'undefined_transaction_verify_requests',
  UNDEFINED_TRANSACTION_DELETE_REQUEST: 'undefined_transaction_delete_request',
  COUNT_UNDEFINED_TRANSACTION_DELETE_REQUEST:
    'count_undefined_transaction_delete_request',
  UNDEFINED_TRANSACTION_DELETE_REQUESTS:
    'undefined_transaction_delete_requests',
  SUMMARY_UNDEFINED_TRANSACTION_MONEY: 'summary_transaction_transaction_money',
  HISTORY_SUMMARY_UNDEFINED_TRANSACTION_MONEY:
    'history_summary_transaction_transaction_money',
  //Dashboard filter
  TRANSACTION_DASHBOARD_FILTER_COUNT: 'transaction_dashboard_filter_count',

  //Transaction compensation
  TRANSACTION_COMPENSATION: 'transaction_compensation',
  TRANSACTION_COMPENSATIONS: 'transaction_compensations',
  TRANSACTION_COMPENSATION_LOG: 'transaction_compensation_log',
  COUNT_TRANSACTION_COMPENSATION_LOG: 'count_transaction_compensation_log',
  TRANSACTION_COMPENSATION_LOGS: 'transaction_compensation_logs',
  TRANSACTION_COMPENSATION_LOG_FAIL_REASON:
    'transaction_compensation_log_fail_reason',

  //Continue heere
  //Bank account
  BANK_ACCOUNT: 'bank_account',
  BANK_ACCOUNTS: 'bank_accounts',
  IDLE_BANK_ACCOUNTS: 'idle_bank_accounts',
  BANK_ACCOUNT_CURRENT_USER: 'bank_account_current_user',
  BANK_ACCOUNT_BY_USER: 'bank_account_by_user',

  //BANK
  BANK: 'bank',
  BANKS: 'banks',
  //EWallet account
  E_WALLET_ACCOUNT: 'e_wallet_account',
  E_WALLET_ACCOUNTS: 'e_wallet_accounts',
  IDLE_E_WALLET_ACCOUNTS: 'idle_e_wallet_accounts',
  //BANK
  E_WALLET: 'e_wallet',
  E_WALLETS: 'e_wallets',
  //WALLET
  WALLET: 'wallet',
  WALLETS: 'wallets',
  //Identification
  IDENTIFICATION: 'identification',
  CHECK_IDENTIFICATION: 'check_identification',

  //Specific company credit balance
  SPECIFIC_COMPANY_CREDIT_BALANCE: 'specific_company_credit_balance',

  //Company Account Monthly Audit
  COMPANY_ACCOUNT_MONTHLY_AUDIT: 'company_account_monthly_audit',
  COMPANY_ACCOUNT_MONTHLY_AUDIT_STATISTIC:
    'company_account_monthly_audit_statistic',
  COMPANY_ACCOUNT_MONTHLY_AUDIT_CHART: 'company_account_monthly_audit_chart',

  //Company Money Holder Account Verification
  COMPANY_MONEY_HOLDER_ACCOUNT_VERIFICATION:
    'company_money_holder_account_verification',
  COMPANY_MONEY_HOLDER_ACCOUNT_VERIFICATIONS:
    'company_money_holder_account_verifications',
  COMPANY_MONEY_HOLDER_ACCOUNT_VERIFICATION_HISTORY_OVERVIEW:
    'company_money_holder_account_verification_history_overview',

  //Small overview in list
  COMPANY_MONEY_HOLDER_ACCOUNT_VERIFICATION_SMALL_OVERVIEW_IN_LIST:
    'company_money_holder_account_verification_small_overview_in_list',

  //Company bank account
  COMPANY_BANK_ACCOUNT: 'company_bank_account',

  //Company Bank Account Assignment
  COMPANY_BANK_ACCOUNT_ASSIGNMENT: 'company_bank_account_assignment',

  //Company Ewallet Account
  COMPANY_EWALLET_ACCOUNT: 'company_ewallet_account',

  //Company Ewallet Account Assignment
  COMPANY_EWALLET_ACCOUNT_ASSIGNMENT: 'company_ewallet_account_assignment',

  //--AVAILABLE MONTH
  GET_AVAILABLE_MONTH: 'get_available_month',

  //--RATING SUMMARY
  RATING_SUMMARY: 'rating_summary',

  //WORK SHIFT
  SHIFT_TEMPLATE: 'shift_template',
  SHIFT_TEMPLATES: 'shift_templates',

  DATE_INFO_BY_WEEK: 'date_info_by_week',
  DATE_INFO: 'date_info',

  FULL_TIME_WORK_SHIFT_SCHEDULE: 'full_time_work_shift_schedule',
  FULL_TIME_WORK_SHIFT_SCHEDULES: 'full_time_work_shift_schedules',
  FULL_TIME_WORK_SHIFT_SCHEDULE_REQUEST:
    'full_time_work_shift_schedule_request',
  FULL_TIME_WORK_SHIFT_SCHEDULE_REQUESTS:
    'full_time_work_shift_schedule_requests',
  FULL_TIME_WORK_SHIFT_SCHEDULE_DAY: 'full_time_work_shift_schedule_day',

  FULL_TIME_WORK_SHIFT_SCHEDULE_DAY_SUMMARY:
    'full_time_work_shift_schedule_day_summary',
  FULL_TIME_WORK_SHIFT_SCHEDULE_DAYS: 'full_time_work_shift_schedule_days',

  WORK_SHIFT: 'work_shift',
  WORK_SHIFTS: 'work_shifts',
  GET_BASE_STATIC_WORK_SHIFT: 'get_base_static_work_shift',
  GET_STATIC_SALARY_WORK_SHIFT: 'get_static_salary_work_shift',
  GET_STATIC_ACTUALLY_SALARY_WORK_SHIFT:
    'get_static_actually_salary_work_shift',
  GET_STATIC_SALARY_WORK_SHIFT_BY_USER_ID:
    'get_static_salary_work_shift_by_user',
  GET_DETAIL_STATIC_WORK_SHIFT: 'get_detail_static_work_shift',

  STAFF_WORK_SHIFT: 'staff_work_shift',

  OFF_COUNT: 'off_count',

  //WORK_ATTENDANCE
  WORK_ATTENDANCE: 'work_attendance',
  WORKING_NOW: 'working_now',
  CHECK_IN_TIME_WORK: 'check_in_time_work',

  //RECRUITMENT
  RECRUITMENT: 'recruitment',
  SEARCH_RECRUITMENT: 'search_recruitment',
  STATIC_RECRUITMENT: 'static_recruitment',

  // user complaint
  USER_COMPLAINT: 'user_complaint',
  SEARCH_USER_COMPLAINT: 'search_user_complaint',
  GET_COUNT_STATUSES_USER_COMPLAINT: 'get_count_statuses_user_complaint',

  //RECRUITMENT_APPLICATION
  RECRUITMENT_APPLICATION: 'recruitment_application',
  REJECT_RECRUITMENT_APPLICATION: 'reject_recruitment_application',
  SEARCH_RECRUITMENT_APPLICATION: 'search_recruitment_application',
  DETAIL_RECRUITMENT_APPLICATION_CURRENT_USER:
    'recruitment_application_current_user',
  GET_STATIC_STATUS_RECRUITMENT_APPLICATION:
    'get_static_status_recruitment_application',
  GET_RECRUITMENT_APPLICATION_BY_MANAGE:
    'get_recruitment_application_by_manage',

  //CONTRACT
  CONTRACT: 'contract',
  CONTRACT_CODE: 'contract_code',
  SEARCH_CONTRACT: 'search_contract',
  STATIC_CONTRACT: 'static_contract',

  //CONTRACT HANDOVER
  CONTRACT_HANDOVER: 'contract_handover',
  SEARCH_STAFF_USER_BY_CONTRACT: 'search_staff_user_by_contract',
  GET_STATIC_STAFF_USER_CONTRACT_HANDOVER:
    'get_static_staff_user_contract_handover',
  GET_STATUS_CONTRACT_HANDOVER: 'get_contract_handover',

  //SALARY
  SALARY: 'salary',
  GET_SALARY_BY_CURRENT_USER: 'get_salary_by_current_user',

  // KPI
  KPI: 'kpi',
  STATIC_KPI: 'static_kpi',
  SUM_KPI: 'sum_kpi',

  // MAP
  PROVINCE: 'province',
  WARD: 'ward',
  DISTRICT: 'district',

  // EMPLOYEE ADVANCE FEE
  EMPLOYEE_ADVANCE_FEE: 'employee_advance_fee',
  SEARCH_EMPLOYEE_ADVANCE_FEE: 'employee_advance_fee',
  STATIC_EMPLOYEE_ADVANCE_FEE: 'static_employee_advance_fee',
  GET_EMPLOYEE_ADVANCE_BY_CURRENT_USER: 'get_employee_advance_by_current_user',

  // WORKING RULE
  WORKING_RULE: 'working_rule',

  //SALARY REQUEST
  SALARY_REQUEST: 'salary_request',
  SALARY_REQUEST_KPI: 'salary_request_kpi',
  SEARCH_SALARY_REQUEST: 'search_salary_request',
  STATIC_SALARY_REQUEST: 'static_salary_request',
  GET_DETAIL_SALARY_REQUEST_BY_PAID_SALARY_HANDLER:
    'get_detail_salary_request_by_paid_salary_handler',

  // punish
  PUNISH: 'punish',
  GET_BASE_STATIC_PUNISH: 'get_base_static_punish',
  GET_DETAIL_STATIC_PUNISH: 'get_detail_static_punish',
  SEARCH_PUNISH_BY_STAFF: 'search_punish_by_staff',

  // staff setting
  GET_STATIC_STAFF_USER_SETTING: 'get_static_staff_user_setting',

  // consultant call kpi
  CONSULTANT_CALL_KPI_REQUEST: 'consultant_call_kpi_request',
  SEARCH_TASK_CONSULTANT_CALL_KPI_HISTORY:
    'search_task_CONSULTANT_CALL_KPI_history',
  // training section
  TRAINING_SECTION: 'training_section',
  GET_COUNT_TRAINING_SECTION: 'get_count_training_section',

  // manage user
  GET_STATIC_TRAINED: 'get_static_trained',

  // user training section
  USER_TRAINING_SECTION: 'user_training_section',
  GET_STATIC_USER_TRAINING_SECTION_BY_USER:
    'get_Static_user_Training_section_by_user',
  GET_STATIC_USER_TRAINING_SECTION_BY_CURRENT_USER:
    'get_Static_user_Training_section_by_current_user',
  SEARCH_USER_TRAINING_SECTION_BY_USER: 'search_user_training_section_by_user',
  SEARCH_USER_TRAINING_SECTION_BY_CURRENT_USER:
    'search_user_training_section_by_current_user',

  // SIDEBAR
  SIDEBAR: 'sidebar',
  SIDEBAR_GET_STATIC_ACCOUNTANT_MANAGE: 'sidebar_get_static_accountant_manage',
  SIDEBAR_GET_STATIC_ACCOUNTANT: 'sidebar_get_static_accountant',
  SIDEBAR_GET_STATIC_CONSULTANT_MANAGE: 'sidebar_get_static_consultant_manage',
  SIDEBAR_GET_STATIC_CONSULTANT: 'sidebar_get_static_consultant',

  //Staff holiday
  STAFF_HOLIDAYS: 'staff_holidays',
  STAFF_HOLIDAYS_IN_WEEK: 'staff_holidays_in_week',
  STAFF_HOLIDAY: 'staff_holiday',
});
