import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonDashboardSideType } from 'src/@types/dashboard';
import {
  ManagerVerifyState,
  TransactionCompensationLogState,
} from 'src/@types/transaction-compensation-log';

const initialState: TransactionCompensationLogState = {
  default: {
    create: {
      dialog: {
        toggle: false,
      },
    },
    view: {
      dialog: {
        toggle: false,
      },
    },
  },
  manager: {
    expense: {
      verify: {
        filter: {
          searchCharacter: '',
        },
        dialog: {
          toggle: false,
        },
      },
    },
    revenue: {
      verify: {
        filter: {
          searchCharacter: '',
        },
        dialog: {
          toggle: false,
        },
      },
    },
  },
};

export const transactionCompensationLogSlice = createSlice({
  name: 'transactionCompensationLog',
  initialState,
  reducers: {
    //Common
    setSelectedTransactionCompensationLogId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedTransactionCompensationLogId = action.payload;
    },
    //Default
    setCreateDialog: (
      state,
      action: PayloadAction<
        TransactionCompensationLogState['default']['create']['dialog']
      >,
    ) => {
      state.default.create.dialog = {
        ...state.default.create.dialog,
        ...action.payload,
      };
    },
    resetCreateDialog: (state) => {
      state.default.create.dialog = initialState.default.create.dialog;
    },

    setViewDialog: (
      state,
      action: PayloadAction<
        TransactionCompensationLogState['default']['view']['dialog']
      >,
    ) => {
      state.default.view.dialog = {
        ...state.default.view.dialog,
        ...action.payload,
      };
    },
    resetViewDialog: (state) => {
      state.default.view.dialog = initialState.default.view.dialog;
    },
    //Manager
    setManagerVerifyDialog: (
      state,
      action: PayloadAction<{
        dialog: ManagerVerifyState['verify']['dialog'];
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, dialog } = action.payload;

      state.manager[dashboardSide].verify.dialog = {
        ...state.manager[dashboardSide].verify.dialog,
        ...dialog,
      };
    },
    resetManagerVerifyDialog: (
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.dialog =
        initialState.manager[dashboardSide].verify.dialog;
    },
    setManagerVerifyFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<ManagerVerifyState['verify']['filter']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) => {
      const { dashboardSide, filter } = action.payload;

      state.manager[dashboardSide].verify.filter = {
        ...state.manager[dashboardSide].verify.filter,
        ...filter,
      };
    },
    resetManagerVerifyFilter: (
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.filter =
        initialState.manager[dashboardSide].verify.filter;
    },
  },
});

export default transactionCompensationLogSlice.reducer;

export const {
  setCreateDialog,
  resetCreateDialog,
  setSelectedTransactionCompensationLogId,
  resetManagerVerifyDialog,
  setManagerVerifyDialog,
  setManagerVerifyFilter,
  resetViewDialog,
  setViewDialog,
  resetManagerVerifyFilter,
} = transactionCompensationLogSlice.actions;
