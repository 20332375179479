import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DashboardSideType,
  IDashboardFilter,
  IDashboardState,
} from 'src/@types/dashboard';
import { TransactionMethodType } from 'src/@types/transaction';
import { momentVi } from 'src/utils/date-handle';

const initialFilter = {
  month: momentVi().month() + 1,
  year: momentVi().year(),
  searchCharacter: '',
  types: [],
  userTypes: [],
};

const initialState: IDashboardState = {
  revenue: {
    filter: initialFilter,
  },
  expense: {
    filter: initialFilter,
  },
  internal: {
    filter: initialFilter,
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    //Common
    setMethod: (
      state,
      action: PayloadAction<{
        method?: TransactionMethodType;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dashboardSide, method } = action.payload;

      state[dashboardSide].method = method;
    },

    setFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<IDashboardFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dashboardSide, filter } = action.payload;

      state[dashboardSide].filter = {
        ...state[dashboardSide].filter,
        ...filter,
      };
    },

    resetFilter: (
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state[dashboardSide].filter = {
        ...state[dashboardSide].filter,
        ...initialFilter,
      };
    },
  },
});

export default slice.reducer;

export const { setMethod, setFilter, resetFilter } = slice.actions;
