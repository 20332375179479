import { BankAccount } from './bank-account';
import { CompanyBankAccountAssignment } from './company-bank-account-assignment';
import { User } from './user';

export enum CompanyBankAccountTypeType {
  REVENUE = 'Thu',
  EXPENSE = 'Chi',
  INTERNAL = 'Nội bộ',
}

export type CompanyBankAccount = {
  id: number;
  bankAccountId: number;
  bankAccount: BankAccount;
  type: CompanyBankAccountTypeType;
  companyBankAccountAssignments: CompanyBankAccountAssignment[];
  isActive: boolean;
  isError: boolean;
  assigneeId?: number;
  assignee?: User;
};

//Service
//Update status company bank account
export type UpdateStatusCompanyBankAccountRequest = {
  bankAccountId: number;
  isActive: boolean;
  type: CompanyBankAccountTypeType;
};
