// routes
import { PATH_ACCOUNTANT } from '../../../routes/paths';
// components
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { CompanyBankAccountTypeType } from 'src/@types/company-bank-account';
import { DashboardSideType } from 'src/@types/dashboard';
import { MoneyHolderAccountTypeType } from 'src/@types/money-holder-account';
import { TransactionTypeType } from 'src/@types/transaction';
import { UndefinedTransactionTypeType } from 'src/@types/undefined-transaction';
import { AccountantRoleType } from 'src/@types/user';
import Iconify from 'src/components/Iconify';
import { SidebarContext } from 'src/contexts/sidebarContext';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  servicesPlumber: getIcon('services-plumber-icon'),
  archive: getIcon('archive-icon'),
  archiveLine: getIcon('archive-line-icon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  book: getIcon('book-icon'),
  globe: getIcon('globe-icon'),
  publisher: getIcon('publisher-icon'),
  description: getIcon('description-icon'),
  pen: getIcon('pen-icon'),
  admin: getIcon('admin-icon'),
  termPolicy: getIcon('term-policy-icon'),
  customGraduationHat: getIcon('custom-graduation-hat'),
  customFileTick: getIcon('custom-file-tick'),
  checkClass: getIcon('check_class_icon'),
  bookTool: getIcon('book-tool-icon'),
  penTool1: getIcon('pen-tool-icon-1'),
  depositRequest: getIcon('deposit-request-icon'),
  depositSupport: getIcon('deposit-support-icon'),
  expenseOverview: getIcon('expense-overview-icon'),
  internalOverview: getIcon('internal-overview-icon'),
  expenseWallet: getIcon('expense-wallet-icon'),
  incomeOverview: getIcon('income-overview-icon'),
  incomeWallet: getIcon('income-wallet-icon'),
  salaryPayment: getIcon('salary-payment-icon'),
  undefinedDeposit: getIcon('undefined-deposit-icon'),
  undefinedRefund: getIcon('undefined-refund-icon'),
  withdrawRequest: getIcon('withdraw-request-icon'),
  withdrawSupport: getIcon('withdraw-support-icon'),
  checkmarkMaleUserIcon: getIcon('checkmark-male-user-icon'),
  chatDeleteIcon: getIcon('chat-delete-icon'),
  authenticationIcon: getIcon('authentication-icon'),
  removeNoteIcon: getIcon('remove-note-icon'),
  depositReportIcon: getIcon('deposit-report-icon'),
  withdrawReportIcon: getIcon('withdraw-report-icon'),
  verifyWithdrawMoney: getIcon('verify-withdraw-money'),
  verifyWithdrawMoney2: getIcon('verify-withdraw-money-2'),
  verifyDepositMoney: getIcon('verify-deposit-money'),
  endOfShiftVerificationExpense: getIcon('end-of-shift-verification-expense'),
  endOfShiftVerificationRevenue: getIcon('end-of-shift-verification-revenue'),
  tablerContract: getIcon('tabler-contract'),
};

// ----------------------------------------------------------------------
// component get count container
const CountCompanyBankAccountantContainer = ({
  type,
}: {
  type: CompanyBankAccountTypeType;
}) => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (type === CompanyBankAccountTypeType.EXPENSE) {
    return <>{dataCountStaticAccountant.countCompanyBankAccountExpense || 0}</>;
  }

  if (type === CompanyBankAccountTypeType.REVENUE) {
    return <>{dataCountStaticAccountant.countCompanyBankAccountRevenue || 0}</>;
  }

  return <>0</>;
};

const CountUndefinedReceiptContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticAccountant.countUndefinedTransactionUnidentifiedReceipt ||
        0}
    </>
  );
};

const CountUndefinedRefundContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {dataCountStaticAccountant.countUndefinedTransactionUnidentifiedRefund ||
        0}
    </>
  );
};

const CountRequestDepositContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {(dataCountStaticAccountant.countTransactionDeposit
        .studentTransactionCount || 0) +
        (dataCountStaticAccountant.countTransactionDeposit
          .teacherTransactionCount || 0)}
    </>
  );
};

const CountSupportDepositContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {(dataCountStaticAccountant.countTransactionSupportRequestDeposit
        .studentTransactionSupReqCount || 0) +
        (dataCountStaticAccountant.countTransactionSupportRequestDeposit
          .teacherTransactionSupReqCount || 0)}
    </>
  );
};

const CountRequestWithdrawContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {(dataCountStaticAccountant.countTransactionWithdraw
        .studentTransactionCount || 0) +
        (dataCountStaticAccountant.countTransactionWithdraw
          .teacherTransactionCount || 0)}
    </>
  );
};

const CountSupportWithdrawContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>
      {(dataCountStaticAccountant.countTransactionSupportRequestWithdraw
        .studentTransactionSupReqCount || 0) +
        (dataCountStaticAccountant.countTransactionSupportRequestWithdraw
          .teacherTransactionSupReqCount || 0)}
    </>
  );
};

const CountSalaryRequestContainer = () => {
  const { dataCountStaticAccountant, isPendingCountStaticAccountant } =
    useContext(SidebarContext);

  if (isPendingCountStaticAccountant || !dataCountStaticAccountant) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  return (
    <>{dataCountStaticAccountant.countSalaryRequest.countRequestSalary || 0}</>
  );
};

const CountReportTransactionContainer = ({
  transactionTypes,
}: {
  transactionTypes: TransactionTypeType.DEPOSIT | TransactionTypeType.WITHDRAW;
}) => {
  const {
    dataCountStaticAccountantManage,
    isPendingCountStaticAccountantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (transactionTypes === TransactionTypeType.DEPOSIT) {
    return <>{dataCountStaticAccountantManage.countTransactionReportDeposit}</>;
  }

  if (transactionTypes === TransactionTypeType.WITHDRAW) {
    return (
      <>{dataCountStaticAccountantManage.countTransactionReportWithdraw}</>
    );
  }

  return <>0</>;
};

const CountUndefinedTransactionVerifyRequestContainer = ({
  undefinedTransactionType,
}: {
  undefinedTransactionType: UndefinedTransactionTypeType;
}) => {
  const {
    dataCountStaticAccountantManage,
    isPendingCountStaticAccountantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (
    undefinedTransactionType ===
    UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT
  ) {
    return (
      <>
        {dataCountStaticAccountantManage.countUndefinedTransactionVerifyRequestUnidentifiedReceipt ||
          0}
      </>
    );
  }

  if (
    undefinedTransactionType ===
    UndefinedTransactionTypeType.UNIDENTIFIED_REFUND
  ) {
    return (
      <>
        {dataCountStaticAccountantManage.countUndefinedTransactionVerifyRequestUnidentifiedRefund ||
          0}
      </>
    );
  }

  return <>0</>;
};

const CountUndefinedTransactionDeleteRequestContainer = ({
  undefinedTransactionType,
}: {
  undefinedTransactionType: UndefinedTransactionTypeType;
}) => {
  const {
    dataCountStaticAccountantManage,
    isPendingCountStaticAccountantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (
    undefinedTransactionType ===
    UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT
  ) {
    return (
      <>
        {dataCountStaticAccountantManage.countUndefinedTransactionDeleteRequestUnidentifiedReceipt ||
          0}
      </>
    );
  }

  if (
    undefinedTransactionType ===
    UndefinedTransactionTypeType.UNIDENTIFIED_REFUND
  ) {
    return (
      <>
        {dataCountStaticAccountantManage.countUndefinedTransactionDeleteRequestUnidentifiedRefund ||
          0}
      </>
    );
  }

  return <>0</>;
};

const CountTransactionCompensationLogContainer = ({
  moneyHolderAccountType,
}: {
  moneyHolderAccountType: MoneyHolderAccountTypeType;
}) => {
  const {
    dataCountStaticAccountantManage,
    isPendingCountStaticAccountantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (moneyHolderAccountType === MoneyHolderAccountTypeType.EXPENSE) {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyMoneyHolderAccountVerificationGroupExpense
        }
      </>
    );
  }

  if (moneyHolderAccountType === MoneyHolderAccountTypeType.REVENUE) {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyMoneyHolderAccountVerificationGroupRevenue
        }
      </>
    );
  }

  return <> 0</>;
};

const CountCompanyTransactionByManagerContainer = ({
  type,
}: {
  type?: TransactionTypeType;
}) => {
  const {
    dataCountStaticAccountantManage,
    isPendingCountStaticAccountantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (type === TransactionTypeType.COMPANY_REVENUE_ACCOUNT_WITHDRAW) {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyTransactionCompanyRevenueAccountWithdraw
        }
      </>
    );
  }

  if (type === TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_WITHDRAW) {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyTransactionCompanyExpenseAccountWithdraw
        }
      </>
    );
  }

  if (type === TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_DEPOSIT) {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyTransactionCompanyExpenseAccountDeposit
        }
      </>
    );
  }

  return <>0</>;
};

const CountCompanyMoneyHolderAccountVerificationContainer = ({
  dashboardSide,
}: {
  dashboardSide: DashboardSideType;
}) => {
  const {
    dataCountStaticAccountantManage,
    isPendingCountStaticAccountantManage,
  } = useContext(SidebarContext);

  if (
    isPendingCountStaticAccountantManage ||
    !dataCountStaticAccountantManage
  ) {
    return (
      <Iconify
        icon={'eos-icons:bubble-loading'}
        width={'20px'}
        height={'20px'}
      />
    );
  }

  if (dashboardSide === 'revenue') {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyMoneyHolderAccountVerificationGroupRevenue
        }
      </>
    );
  }

  if (dashboardSide === 'expense') {
    return (
      <>
        {
          dataCountStaticAccountantManage.countCompanyMoneyHolderAccountVerificationGroupExpense
        }
      </>
    );
  }

  return <>{0}</>;
};

// STAFF
// ----------------------------------------------------------------------
const navConfig = [
  // ----------------------------------------------------------------------
  // Income
  {
    subheader: 'Thu tiền',
    icon: ICONS.incomeWallet,
    items: [
      // Deposit
      {
        title: 'Yêu cầu nạp tiền',
        path: PATH_ACCOUNTANT.depositRequest,
        icon: ICONS.depositRequest,
        roles: [AccountantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountRequestDepositContainer />
          </Typography>
        ),
      },
      // Deposit support
      {
        title: 'Trợ giúp nạp tiền',
        path: PATH_ACCOUNTANT.depositSupport,
        icon: ICONS.depositSupport,
        roles: [AccountantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountSupportDepositContainer />
          </Typography>
        ),
      },
      // Undefined Deposit
      {
        title: 'Nhận chưa xác định',
        path: PATH_ACCOUNTANT.undefinedReceipt,
        icon: ICONS.undefinedDeposit,
        roles: [AccountantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountUndefinedReceiptContainer />
          </Typography>
        ),
      },
      // Income overview
      {
        title: 'Thống kê tổng thu',
        path: PATH_ACCOUNTANT.incomeOverview,
        icon: ICONS.incomeOverview,
        roles: [AccountantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountCompanyBankAccountantContainer
              type={CompanyBankAccountTypeType.REVENUE}
            />
          </Typography>
        ),
      },
      //Verify Undefined Deposit
      {
        title: 'Báo cáo nạp tiền',
        path: PATH_ACCOUNTANT.reportDepositTransaction,
        icon: ICONS.depositReportIcon,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountReportTransactionContainer
              transactionTypes={TransactionTypeType.DEPOSIT}
            />
          </Typography>
        ),
      },
      {
        title: 'Xác minh NCXD',
        path: PATH_ACCOUNTANT.undefinedReceiptVerification,
        icon: ICONS.checkmarkMaleUserIcon,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountUndefinedTransactionVerifyRequestContainer
              undefinedTransactionType={
                UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT
              }
            />
          </Typography>
        ),
      },
      //Delete Undefined Deposit
      {
        title: 'Duyệt xóa NCXD',
        path: PATH_ACCOUNTANT.undefinedReceiptDeletion,
        icon: ICONS.chatDeleteIcon,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountUndefinedTransactionDeleteRequestContainer
              undefinedTransactionType={
                UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT
              }
            />
          </Typography>
        ),
      },
      //Income Company withdraw
      {
        title: 'Duyệt tiền lấy về',
        path: PATH_ACCOUNTANT.verifyIncomeCompanyWithdrawTransaction,
        icon: ICONS.verifyWithdrawMoney,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountCompanyTransactionByManagerContainer
              type={TransactionTypeType.COMPANY_REVENUE_ACCOUNT_WITHDRAW}
            />
          </Typography>
        ),
      },
      //Revenue Company withdraw
      {
        title: 'Duyệt thu cuối ca',
        path: PATH_ACCOUNTANT.verifyRevenueMoneyHolderAccountVerification,
        icon: ICONS.endOfShiftVerificationRevenue,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountCompanyMoneyHolderAccountVerificationContainer dashboardSide="revenue" />
          </Typography>
        ),
      },
      //Revenue compensation log
      {
        title: 'Duyệt bồi thường thu',
        path: PATH_ACCOUNTANT.verifyRevenueCompensation,
        icon: ICONS.tablerContract,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountTransactionCompensationLogContainer
              moneyHolderAccountType={MoneyHolderAccountTypeType.REVENUE}
            />
          </Typography>
        ),
      },
    ],
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'Chi tiền',
    icon: ICONS.expenseWallet,
    items: [
      //Staff
      //--Withdraw
      {
        title: 'Yêu cầu rút tiền',
        path: PATH_ACCOUNTANT.withdrawRequest,
        icon: ICONS.withdrawRequest,
        roles: [AccountantRoleType.STAFF],
        subLabel: (
          <Typography>
            <CountRequestWithdrawContainer />
          </Typography>
        ),
      },
      //--Withdraw support
      {
        title: 'Trợ giúp rút tiền',
        path: PATH_ACCOUNTANT.withdrawSupport,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.withdrawSupport,
        subLabel: (
          <Typography>
            <CountSupportWithdrawContainer />
          </Typography>
        ),
      },
      //--Undefined Withdraw
      {
        title: 'Hoàn chưa xác định',
        path: PATH_ACCOUNTANT.undefinedRefund,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.undefinedRefund,
        subLabel: (
          <Typography>
            <CountUndefinedRefundContainer />
          </Typography>
        ),
      },

      //--Income overview
      {
        title: 'Thống kê tổng chi',
        path: PATH_ACCOUNTANT.expenseOverview,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.expenseOverview,
        subLabel: (
          <Typography>
            <CountCompanyBankAccountantContainer
              type={CompanyBankAccountTypeType.REVENUE}
            />
          </Typography>
        ),
      },
      //For manager
      //--Withdraw Report
      {
        title: 'Báo cáo rút tiền',
        path: PATH_ACCOUNTANT.reportWithdrawTransaction,
        icon: ICONS.withdrawReportIcon,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountReportTransactionContainer
              transactionTypes={TransactionTypeType.WITHDRAW}
            />
          </Typography>
        ),
      },
      //--Verify Undefined Withdraw
      {
        title: 'Xác minh HCXD',
        path: PATH_ACCOUNTANT.undefinedRefundVerification,
        icon: ICONS.authenticationIcon,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountUndefinedTransactionVerifyRequestContainer
              undefinedTransactionType={
                UndefinedTransactionTypeType.UNIDENTIFIED_REFUND
              }
            />
          </Typography>
        ),
      },
      //--Delete Undefined Withdraw
      {
        title: 'Duyệt xóa HCXD',
        path: PATH_ACCOUNTANT.undefinedRefundDeletion,
        icon: ICONS.removeNoteIcon,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountUndefinedTransactionDeleteRequestContainer
              undefinedTransactionType={
                UndefinedTransactionTypeType.UNIDENTIFIED_REFUND
              }
            />
          </Typography>
        ),
      },
      //Company withdraw
      {
        title: 'Duyệt tiền lấy về',
        path: PATH_ACCOUNTANT.verifyExpenseCompanyWithdrawTransaction,
        icon: ICONS.verifyWithdrawMoney2,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountCompanyTransactionByManagerContainer
              type={TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_WITHDRAW}
            />
          </Typography>
        ),
      },
      //Company withdraw
      {
        title: 'Duyệt tiền thêm vào',
        path: PATH_ACCOUNTANT.verifyExpenseCompanyDepositTransaction,
        icon: ICONS.verifyDepositMoney,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountCompanyTransactionByManagerContainer
              type={TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_DEPOSIT}
            />
          </Typography>
        ),
      },
      //Expense Company withdraw
      {
        title: 'Duyệt chi cuối ca',
        path: PATH_ACCOUNTANT.verifyExpenseMoneyHolderAccountVerification,
        icon: ICONS.endOfShiftVerificationExpense,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountCompanyMoneyHolderAccountVerificationContainer dashboardSide="expense" />
          </Typography>
        ),
      },
      //Expense compensation log
      {
        title: 'Duyệt bồi thường chi',
        path: PATH_ACCOUNTANT.verifyExpenseCompensation,
        icon: ICONS.tablerContract,
        roles: [AccountantRoleType.MANAGER],
        subLabel: (
          <Typography color={'#ffac06'}>
            <CountTransactionCompensationLogContainer
              moneyHolderAccountType={MoneyHolderAccountTypeType.EXPENSE}
            />
          </Typography>
        ),
      },
    ],
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'Nội bộ',
    icon: ICONS.expenseWallet,
    items: [
      //Staff
      //--Request Salary
      {
        title: 'Thanh toán lương',
        path: PATH_ACCOUNTANT.salaryPayment,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.salaryPayment,
        subLabel: (
          <Typography>
            <CountSalaryRequestContainer />
          </Typography>
        ),
      },
      {
        title: 'Thống kê tổng nội bộ',
        path: PATH_ACCOUNTANT.internalOverview,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.internalOverview,
      },
      //Company withdraw
      {
        title: 'Duyệt tiền lấy về',
        path: PATH_ACCOUNTANT.verifyInternalCompanyWithdrawTransaction,
        icon: ICONS.verifyWithdrawMoney2,
        roles: [AccountantRoleType.MANAGER],
      },
      //Company withdraw
      {
        title: 'Duyệt tiền chi khác',
        path: PATH_ACCOUNTANT.verifyInternalCompanyOtherExpenseTransaction,
        icon: ICONS.verifyWithdrawMoney,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Duyệt tiền thêm vào',
        path: PATH_ACCOUNTANT.verifyInternalCompanyDepositTransaction,
        icon: ICONS.verifyDepositMoney,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
];

export default navConfig;
