import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import { Socket } from 'socket.io-client';
import { PaginationRes } from 'src/@types/app-response';
import { Notification } from 'src/@types/notification';
import { UserType } from 'src/@types/user';
import { audioPath, customIcon } from 'src/assets';
import { IconSVG } from 'src/components/IconSVG';
import Image from 'src/components/Image';
import NotificationCom from 'src/components/notifications/Notification';
import { SocketContext } from 'src/contexts/SocketContext';
import { NOTIFICATION_QUERY_KEYS } from 'src/hooks/common/notification/index.constants';
import useDeleteNotificationMutation from 'src/hooks/common/notification/useDeleteNotificationMutation';
import useGetCurrentUserNotificationsQuery from 'src/hooks/common/notification/useGetCurrentUserNotificationsQuery';
import useMarkNotificationMutation from 'src/hooks/common/notification/useMarkNotificationMutation';
import useReadNotificationMutation from 'src/hooks/common/notification/useReadNotificationMutation';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import { update } from 'src/redux/slices/notification.slice';
import { useDispatch, useSelector } from 'src/redux/store';
import { PATH_CONSULTANT } from 'src/routes/paths';
import useSound from 'use-sound';
import DrawerHeader from './DrawerHeader';
import { CONSULTANT_NOTIFICATION_ACTIVITIES } from './notification.constant';

type ActivityType =
  (typeof CONSULTANT_NOTIFICATION_ACTIVITIES)[keyof typeof CONSULTANT_NOTIFICATION_ACTIVITIES];

export default function NotificationPopover() {
  const { user, userType } = useAuth();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [socket, setSocket] = useState<Socket | undefined>();
  const [isNewNotification, setIsNewNotification] = useState(false);
  const { notiAcctSocket, notiContSocket } = useContext(SocketContext);
  const notificationArea = useSelector(
    (state) =>
      state.notification.configs?.find(
        (notificationArea) => notificationArea.userId === user?.id,
      ),
  );

  //hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const [play] = useSound(audioPath.threads);
  const { enqueueSnackbar } = useSnackbar();
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  //-- Api
  const {
    data,
    isFetchingNextPage,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useGetCurrentUserNotificationsQuery({}, user?.id || 0);
  const { mutate: readMutate } = useReadNotificationMutation({
    onSuccess: (data: Notification) => {
      updateNotificationsCache(data, 'UPDATE');
    },
  });
  const { mutate: markMutate } = useMarkNotificationMutation({
    onSuccess: (data: Notification) => {
      updateNotificationsCache(data, 'UPDATE');
    },
  });
  const { mutate: deleteMutate } = useDeleteNotificationMutation({
    onSuccess: (data: Notification) => {
      updateNotificationsCache(data, 'DELETE');
    },
  });

  //-- function
  //Handle notification type =
  const handleRedirectToHistorySalary = () => {
    navigate(PATH_CONSULTANT.historySalary);
  };

  const notificationStrategies: Record<
    ActivityType,
    (notification?: Notification<ActivityType>) => void
  > = {
    'common.recruitmentApplication.new': function (
      notification?: Notification<ActivityType> | undefined,
    ): void {
      const codeRecruitment =
        notification?.templateParameter.find(
          (item) => item.name === 'codeRecruitment',
        )?.value ?? '';
      if (codeRecruitment) {
        navigate(PATH_CONSULTANT.viewRecruitmentApplications(codeRecruitment));
      }
    },

    'common.requestContractHandover.new': function (
      notification?: Notification<ActivityType> | undefined,
    ): void {
      if (notification) {
        const userId = notification.topic.value;
        navigate(PATH_CONSULTANT.staffSetting(userId));
      }
    },
    'common.training.completed': function (
      notification?: Notification<ActivityType> | undefined,
    ): void {
      if (notification) {
        const userId = notification.topic.value;
        navigate(PATH_CONSULTANT.detailTrainingStaff(userId));
      }
    },
    'common.contractHandover.assigned': function (): void {
      navigate(PATH_CONSULTANT.accountManagement);
    },
    'common.salaryRequest.infoBankAccountNotCorrect':
      handleRedirectToHistorySalary,
    'common.salaryRequest.noBankAccount': handleRedirectToHistorySalary,
    'common.salaryRequest.paidSalary': handleRedirectToHistorySalary,
  };

  const addNotificationsCache = (data: Notification) => {
    queryClient.setQueryData(
      [NOTIFICATION_QUERY_KEYS.CURRENT_USER_NOTIFICATIONS, user?.id],
      (
        infiniteData:
          | InfiniteData<PaginationRes<Notification>, unknown>
          | undefined,
      ) => {
        if (!infiniteData) return;

        const updatedData = {
          ...infiniteData,
          pages: infiniteData?.pages.map((page) => ({
            ...page,
            items: [...page.items, data],
          })),
        };

        return updatedData;
      },
    );
  };

  const updateNotificationsCache = (
    data: Notification,
    type: 'UPDATE' | 'DELETE',
  ) => {
    queryClient.setQueryData(
      [NOTIFICATION_QUERY_KEYS.CURRENT_USER_NOTIFICATIONS, user?.id],
      (
        infiniteData:
          | InfiniteData<PaginationRes<Notification>, unknown>
          | undefined,
      ) => {
        const updatedData = {
          ...infiniteData,
          pages: infiniteData?.pages.map((page) => {
            let items: Notification[] = [];

            if (type === 'UPDATE') {
              items = page.items.map((item) => {
                if (item._id === data._id) {
                  return data;
                }
                return item;
              });
            }

            if (type === 'DELETE') {
              items = page.items.filter((item) => item._id !== data._id);
            }

            return {
              ...page,
              items,
            };
          }),
        };

        return updatedData;
      },
    );
  };

  const handleReadNotification = (notification: Notification<ActivityType>) => {
    if (!notification.state.isRead) readMutate(notification._id);
    notificationStrategies[notification.activityType](notification);
  };

  const handleMarkNotification = (notification: Notification) => {
    markMutate(notification._id);
  };

  const handleDeleteNotification = (notification: Notification) => {
    deleteMutate(notification._id);
  };

  useEffect(() => {
    if (data && isSuccess) {
      const { pages } = data;

      const notis = [...pages.map((page) => page.items)].flat();

      setNotifications(notis);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (user) {
      dispatch(
        update({
          userId: user.id,
          data: {
            isOpenVolume: true,
          },
        }),
      );
    }
    switch (userType) {
      case UserType.CONSULTANT:
        setSocket(notiContSocket);
        refetch();
        break;
      case UserType.ACCOUNTANT:
        setSocket(notiAcctSocket);
        refetch();
        break;
    }
  }, [user, userType, notiContSocket, notiAcctSocket]);

  // socket
  useEffect(() => {
    if (socket) {
      socket.on('recruitment_notification', (data: Notification) => {
        addNotificationsCache(data);
        setIsNewNotification(true);
      });
      socket.on('error', (error: any) => {
        console.log(error);
      });
    }
    return () => {
      if (socket) {
        socket.off('error');
        socket.off('recruitment_notification');
      }
    };
  }, [socket, userType]);

  useEffect(() => {
    if (isNewNotification) {
      if (isVolumeUp) {
        play();
      }
      setIsNewNotification(false);
    }
  }, [isNewNotification, notificationArea]);

  const sortNotifications = notifications.sort((a, b) => {
    if (a.state.isMark !== b.state.isMark) {
      return a.state.isMark ? -1 : 1;
    }

    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    return dateB - dateA;
  });

  const isVolumeUp = !!notificationArea?.isOpenVolume;

  return (
    <>
      <Stack
        style={{
          cursor: 'pointer',
        }}
        alignItems={'center'}
        onClick={() => setOpenDrawer(true)}
        position={'relative'}
      >
        {Array.isArray(notifications) &&
          notifications.length > 0 &&
          notifications.some((item) => !item.state.isRead) && (
            <Box
              position={'absolute'}
              top={-3}
              right={0}
              width={11}
              height={11}
              borderRadius={11}
              bgcolor={'red'}
            />
          )}
        <IconSVG
          path={customIcon.notification}
          css={{ width: '25px', height: '25px', color: 'white' }}
        />
      </Stack>
      <DrawerHeader
        totalItems={0}
        heightEachItem={100}
        toggleDrawer={(newOpen) => setOpenDrawer(newOpen)}
        title="Thông báo"
        open={openDrawer}
        heightDrawer={1000}
        topArrow={openDrawer ? 57 : 80}
        rightArrow={isDesktop ? 68 : 76}
        speaker={{
          isShow: true,
          open: isVolumeUp,
          setOpen: (value) => {
            enqueueSnackbar(
              `Chế độ âm thanh đã được ${
                value ? 'bật' : 'tắt'
              } khi có thông báo mới`,
            );
            dispatch(
              update({
                userId: user?.id || 0,
                data: {
                  isOpenVolume: !isVolumeUp,
                },
              }),
            );
          },
        }}
      >
        {sortNotifications && isSuccess ? (
          <>
            {sortNotifications.map((item, key) => (
              <NotificationCom
                key={key}
                handle={() => handleReadNotification(item)}
                avatarUrl={item.topic.avatarUrl}
                alt={item.topic.displayName}
                bgcolor={
                  !item.state.isRead
                    ? `${theme.palette.primary.main}20`
                    : undefined
                }
                time={item.createdAt}
                content={item?.previewText?.content}
                isMark={item.state.isMark}
                colorTitle={theme.palette.primary.main}
                handleRemove={() => handleDeleteNotification(item)}
                handleMark={() => handleMarkNotification(item)}
              />
            ))}
            <Stack>
              <Button
                ref={ref}
                variant="text"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage
                  ? 'Đang tải thêm dữ liệu...'
                  : hasNextPage
                    ? 'Tải thêm dữ liệu'
                    : ''}
              </Button>
            </Stack>
          </>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={theme.spacing(3)}
            mt={theme.spacing(16)}
          >
            <Typography color={theme.palette.grey[700]}>
              Không có dữ liệu
            </Typography>
            <Image src="/assets/not-found/not-found-2.png" />
          </Stack>
        )}
      </DrawerHeader>
    </>
  );
}
