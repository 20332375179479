import { ReactNode, createContext, useEffect } from 'react';
import { SidebarStaticAccountantManageRes, SidebarStaticAccountantRes, SidebarStaticConsultantManageRes, SidebarStaticConsultantRes } from 'src/@types/sidebar';
import { AccountantRoleType, UserType } from 'src/@types/user';
import { useGetCountStaticAccountant } from 'src/hooks/accountant/sidebar/useGetCountStatic.accountant.sidebar';
import { useGetCountStaticAccountantManage } from 'src/hooks/accountant/sidebar/useGetCountStaticManage.accountant.sidebar';
import { useGetCountStaticConsultant } from 'src/hooks/consultant/sidebar/useGetCountStatic.consultant.sidebar';
import { useGetCountStaticConsultantManage } from 'src/hooks/consultant/sidebar/useGetCountStaticManage.consultannt.sidebar';
import useAuth from 'src/hooks/useAuth';
// hooks

// ----------------------------------------------------------------------

export type SidebarContextProps = {
  dataCountStaticAccountant?: SidebarStaticAccountantRes;
  dataCountStaticAccountantManage?: SidebarStaticAccountantManageRes;
  dataCountStaticConsultant?: SidebarStaticConsultantRes;
  dataCountStaticConsultantManage?: SidebarStaticConsultantManageRes;

  isPendingCountStaticAccountant?: boolean;
  isPendingCountStaticAccountantManage?: boolean;
  isPendingCountStaticConsultant?: boolean;
  isPendingCountStaticConsultantManage?: boolean;
};

const initialState: SidebarContextProps = {};

const SidebarContext = createContext(initialState);

type SidebarProviderProps = {
  children: ReactNode;
};

function SidebarProvider({ children }: SidebarProviderProps) {
  // -----------------------------------------
  // hook
  const { userType, userRole } = useAuth();

  // -----------------------------------------
  // query
  const {
    data: dataCountStaticAccountant,
    isPending: isPendingCountStaticAccountant,
    refetch: refetchCountStaticAccountant,
  } = useGetCountStaticAccountant({
    enabled: false,
    refetchInterval: 5 * 60 * 1000,
  });

  const {
    data: dataCountStaticAccountantManage,
    isPending: isPendingCountStaticAccountantManage,
    refetch: refetchCountStaticAccountantManage,
  } = useGetCountStaticAccountantManage({
    enabled: false,
    refetchInterval: 5 * 60 * 1000,
  });

  const {
    data: dataCountStaticConsultant,
    isPending: isPendingCountStaticConsultant,
    refetch: refetchCountStaticConsultant,
  } = useGetCountStaticConsultant({
    enabled: false,
    refetchInterval: 5 * 60 * 1000,
  });

  const {
    data: dataCountStaticConsultantManage,
    isPending: isPendingCountStaticConsultantManage,
    refetch: refetchCountStaticConsultantManage,
  } = useGetCountStaticConsultantManage({
    enabled: false,
    refetchInterval: 5 * 60 * 1000,
  });

  // -----------------------------------------
  // useEffect
  useEffect(() => {
    if (
      userType === UserType.ACCOUNTANT &&
      userRole === AccountantRoleType.MANAGER
    ) {
      refetchCountStaticAccountantManage();
    }

    if (
      userType === UserType.ACCOUNTANT &&
      userRole === AccountantRoleType.STAFF
    ) {
      refetchCountStaticAccountant();
    }

    if (
      userType === UserType.CONSULTANT &&
      userRole === AccountantRoleType.MANAGER
    ) {
      refetchCountStaticConsultantManage();
    }

    if (
      userType === UserType.CONSULTANT &&
      userRole === AccountantRoleType.STAFF
    ) {
      refetchCountStaticConsultant();
    }
  }, [userType, userRole]);

  return (
    <SidebarContext.Provider
      value={{
        dataCountStaticAccountant,
        dataCountStaticAccountantManage,
        dataCountStaticConsultant,
        dataCountStaticConsultantManage,

        isPendingCountStaticAccountant,
        isPendingCountStaticAccountantManage,
        isPendingCountStaticConsultant,
        isPendingCountStaticConsultantManage,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarContext, SidebarProvider };
