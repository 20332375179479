import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CommonDashboardSideType } from 'src/@types/dashboard';
import { UndefinedTransactionTypeType } from 'src/@types/undefined-transaction';
import {
  IDefaultState,
  IManagerState,
  IUndefinedTransactionVerifyRequestState,
  IVerifyManagerFilter,
} from 'src/@types/undefined-transaction-verify-request';

const initialState: IUndefinedTransactionVerifyRequestState = {
  default: {
    revenue: {
      create: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
          isLoading: false,
        },
      },
    },
    expense: {
      create: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
          isLoading: false,
        },
      },
    },
  },
  manager: {
    expense: {
      verify: {
        dialog: {
          mode: 'VIEW',
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 10,
          statuses: [],
          undefinedTransactionType:
            UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
        },
      },
    },
    revenue: {
      verify: {
        dialog: {
          mode: 'VIEW',
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 10,
          statuses: [],
          undefinedTransactionType:
            UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
        },
      },
    },
  },
  selectedUndefinedTransactionVerifyRequestId: undefined,
};

const slice = createSlice({
  name: 'undefinedTransactionVerifyRequest',
  initialState: initialState,
  reducers: {
    setSelectedUndefinedTransactionVerifyRequestId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedUndefinedTransactionVerifyRequestId = action.payload;
    },
    //--Default-----------------------
    //Verify
    //Verify Dialog Toggle
    setCreateDialog(
      state,
      action: PayloadAction<{
        dialog: Partial<IDefaultState['create']['dialog']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { dialog, dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog = {
        ...state.default[dashboardSide].create.dialog,
        ...dialog,
      };
    },
    //Reset
    resetCreateDialog(
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog = {
        ...initialState.default[dashboardSide].create.dialog,
      };
    },
    //---Manager-----------------------
    //Verify mode

    setManagerVerifyDialog(
      state,
      action: PayloadAction<{
        dialog: Partial<IManagerState['verify']['dialog']>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { dialog, dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.dialog = {
        ...state.manager[dashboardSide].verify.dialog,
        ...dialog,
      };
    },
    resetManagerVerifyDialog(
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.selectedUndefinedTransactionVerifyRequestId = undefined;

      state.manager[dashboardSide].verify.dialog = {
        ...initialState.manager[dashboardSide].verify.dialog,
      };
    },
    setManagerVerifyFilter(
      state,
      action: PayloadAction<{
        filter: Partial<IVerifyManagerFilter>;
        dashboardSide: CommonDashboardSideType;
      }>,
    ) {
      const { filter, dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.filter = {
        ...state.manager[dashboardSide].verify.filter,
        ...filter,
      };
    },
    resetManagerVerifyFilter(
      state,
      action: PayloadAction<{ dashboardSide: CommonDashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.filter = {
        ...initialState.manager[dashboardSide].verify.filter,
      };
    },
  },
});

//Reducer
export default slice.reducer;

export const {
  //Manager
  resetManagerVerifyDialog,
  resetManagerVerifyFilter,
  setManagerVerifyFilter,
  setSelectedUndefinedTransactionVerifyRequestId,
  resetCreateDialog,
  setCreateDialog,
  setManagerVerifyDialog,
} = slice.actions;
