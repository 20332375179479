import {
  Box,
  Grid,
  Stack,
  StackProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { generateYear, getYear } from 'src/utils/generateDate.util';
import WorkYearCalendarItem from './WorkYearCalendarItem';

type Props = StackProps & {
  value?: number;
  onYearClick?: (value: number) => void;
  beforeYearCount?: number;
};

const HeaderStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 4, 1),
}));

export default function WorkYearCalendar({
  value,
  onYearClick,
  beforeYearCount = 1,
  ...other
}: Props) {
  const [year, setYear] = useState<number>(value || getYear());

  const theme = useTheme();

  const years = generateYear({
    beforeYearCount,
    afterYearCount: 2,
  });

  const handleClick = (year: number) => {
    if (years.includes(year)) {
      setYear(year);
      if (onYearClick) onYearClick(year);
    }
  };

  useEffect(() => {
    if (year) {
      const a = document.createElement('a');
      a.click();
    }
  }, [year]);

  useEffect(() => {
    if (year) {
      const element = document.getElementById('year-in-view');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [year]);

  return (
    <Stack spacing={2} {...other}>
      <HeaderStyle>
        <Typography fontSize={16} fontWeight={theme.typography.fontWeightBold}>
          Năm làm việc
        </Typography>
        <Typography fontSize={14}>Chọn năm để xem</Typography>
      </HeaderStyle>
      <Box overflow={'auto'} style={{ scrollBehavior: 'smooth' }}>
        <Grid container rowSpacing={2}>
          {years.map((item) => (
            <Grid item key={item} xs={12}>
              <WorkYearCalendarItem
                year={item}
                isActive={item === year}
                id={item === year ? 'year-in-view' : undefined}
                onClick={() => handleClick(item)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}
