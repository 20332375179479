import { ReactNode, createContext, useState } from 'react';
// hooks

// ----------------------------------------------------------------------

export type DataKeyChartItemContext = {
  indexDetail?: number;
};

export type ChartContextProps = {
  dataKeyCharts: {
    [keyChart: string]: DataKeyChartItemContext;
  };
  setIndexDetailChartContext: (data: {
    indexDetail?: number;
    nameKey: string;
  }) => void;
};

const initialState: ChartContextProps = {
  dataKeyCharts: {},
  setIndexDetailChartContext: () => null,
};

const ChartContext = createContext(initialState);

type ChartProviderProps = {
  children: ReactNode;
};

function ChartProvider({ children }: ChartProviderProps) {
  // ----------------------------------------------
  // state
  const [dataKeyChartsState, setDataKeyCharts] = useState<{
    [keyChart: string]: DataKeyChartItemContext;
  }>({});

  // ----------------------------------------------
  // handle
  const setIndexDetailChartContext = ({
    nameKey,
    indexDetail,
  }: {
    indexDetail?: number;
    nameKey: string;
  }) => {
    const newDataKeyChartsState = { ...dataKeyChartsState };
    if (!newDataKeyChartsState[nameKey]) {
      newDataKeyChartsState[nameKey] = {
        indexDetail,
      };
    } else {
      newDataKeyChartsState[nameKey] = {
        ...newDataKeyChartsState[nameKey],
        indexDetail,
      };
    }
    setDataKeyCharts(newDataKeyChartsState);
  };

  return (
    <ChartContext.Provider
      value={{
        dataKeyCharts: dataKeyChartsState,
        setIndexDetailChartContext,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
}

export { ChartContext, ChartProvider };
