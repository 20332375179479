import { WorkShift } from './work-shift';
import { User } from './user';

export enum WorkAttendanceStatusType {
  WORKING = 'Đang trong quá trình làm việc',
  WORKED = 'Đã làm việc xong',
}

export type WorkAttendance = {
  id: number;
  startedAt?: Date;
  endedAt?: Date;
  status: WorkAttendanceStatusType;
  salaryPerHour: number;
  ownerId: number;
  owner?: User;
  workShiftId?: number;
  workShift?: WorkShift;
};

//Service
export type WorkingNowRequest = {
  fopId: number;
};

export type FindUserAttendanceRequest = {
  date: Date
}


export type FindUserAttendanceRes = {
  attendanceCount: number,
  ownerId: number,
  code: string,
  fullName?: string,
  avatarUrl?: string
}[]
