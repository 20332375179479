import { DepositDashboardSideType } from 'src/@types/dashboard';
import {
  resetVerifyDepositManagerDialog,
  setSelectedTransactionId,
  setVerifyDepositManagerDialog,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

type Props = {
  dashboardSide: DepositDashboardSideType;
};

export function useVerifyDialog({ dashboardSide }: Props) {
  //state
  const toggle = useSelector(
    (state) =>
      state.transaction.manager[dashboardSide].companyDepositVerification.dialog
        .toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialogById = (id: number) => {
    dispatch(setSelectedTransactionId(id));
    dispatch(
      setVerifyDepositManagerDialog({
        dialog: {
          toggle: true,
        },
        dashboardSide,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(
      resetVerifyDepositManagerDialog({
        dashboardSide,
      }),
    );
  };

  return { toggle, closeDialog, openDialogById };
}
