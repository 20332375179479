import {
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { Suspense } from 'react';
import { useLocation } from 'react-router';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';
import WorkCalendarDialog, {
  WorkCalendarValue,
} from 'src/components/calender/WorkCalendarDialog';
import useDeepEffect from 'src/hooks/useDeepEffect';
import useSettings from 'src/hooks/useSettings';
import useTabs from 'src/hooks/useTabs';
import useToggle from 'src/hooks/useToggle';
import { setMonth, setYear } from 'src/redux/slices/task-history.slice';
import { useDispatch, useSelector } from 'src/redux/store';

//For lazy
const DepositRequestList = React.lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/task-history/deposit-request/List'
    ),
);
const WithdrawRequestList = React.lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/task-history/withdraw-request/List'
    ),
);
const DepositSupportRequestList = React.lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/task-history/deposit-support-request/List'
    ),
);
const WithdrawSupportRequestList = React.lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/task-history/withdraw-support-request/List'
    ),
);
const OutBandVerificationList = React.lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/task-history/out-band-verification/List'
    ),
);

const SalaryRequestList = React.lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/task-history/salary-request/List'
    ),
);

const CustomTabs = styled(Tabs)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '::before': {
    content: "''",
    width: '100%',
    position: 'absolute',
    bottom: 0,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  marginRight: '0 !important',
  fontWeight: 700,
  textTransform: 'none',
}));

export const TASK_HISTORY_TABS_TITLE = {
  depositRequest: 'Yêu cầu nạp tiền',
  depositSupportRequest: 'Trợ giúp nạp tiền',
  salaryRequest: 'Thanh toán lương',
  withdrawRequest: 'Yêu cầu rút tiền',
  withdrawSupportRequest: 'Trợ giúp rút tiền',
  outOfBandVerification: 'Xác minh ngoài luồng',
};

const TASK_HISTORY_TABS = [
  {
    title: TASK_HISTORY_TABS_TITLE.depositRequest,
    panel: <DepositRequestList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.depositSupportRequest,
    panel: <DepositSupportRequestList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.withdrawRequest,
    panel: <WithdrawRequestList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.withdrawSupportRequest,
    panel: <WithdrawSupportRequestList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.salaryRequest,
    panel: <SalaryRequestList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.outOfBandVerification,
    panel: <OutBandVerificationList />,
  },
];

export default function TaskHistory() {
  const month = useSelector((state) => state.taskHistory.accountant.month);
  const year = useSelector((state) => state.taskHistory.accountant.year);

  const {
    onClose: onCloseCalendar,
    onOpen: onOpenCalendar,
    toggle: calendarToggle,
  } = useToggle();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { currentTab, onChangeTab, setCurrentTab } = useTabs(
    TASK_HISTORY_TABS[0].title,
  );
  const dispatch = useDispatch();
  const location: any = useLocation();
  const { useDeepCompareEffect } = useDeepEffect();

  const handleCalendarOnClick = (data: WorkCalendarValue) => {
    dispatch(setMonth({ month: data.month, userType: 'accountant' }));
    dispatch(setYear({ year: data.year, userType: 'accountant' }));
  };

  useDeepCompareEffect(() => {
    if (location?.state?.currentTab) {
      setCurrentTab(location?.state?.currentTab);
    }
  }, [location]);

  const actions = (
    <Stack
      direction="row"
      alignItems={'center'}
      spacing={2}
      justifyContent={'flex-end'}
    >
      <Typography variant="h5" color={theme.palette.primary.main}>
        Tháng {month} Năm {year}
      </Typography>
      <Button variant="reserveContained" onClick={onOpenCalendar}>
        Chọn lịch khác
      </Button>
    </Stack>
  );

  const currentTabData = TASK_HISTORY_TABS.find(
    (item) => item.title === currentTab,
  );

  return (
    <Page title="Lịch sử công việc">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Lịch sử công việc của bạn"
          links={[]}
          actions={actions}
          actionsDisplay="inline"
        />

        <Stack spacing={4} mt={4}>
          <Stack direction={'row'}>
            <CustomTabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {TASK_HISTORY_TABS.map((tab, index) => (
                <CustomTab key={index} label={tab.title} value={tab.title} />
              ))}
            </CustomTabs>
          </Stack>

          <Suspense fallback={<Loading />}>{currentTabData?.panel}</Suspense>
        </Stack>

        {/* other */}
        <WorkCalendarDialog
          open={calendarToggle}
          onClose={onCloseCalendar}
          onClick={handleCalendarOnClick}
          value={{
            month,
            year,
          }}
        />
      </Container>
    </Page>
  );
}
